import React from "react";
import styles from "./ParticipantCard.module.scss";
import womanPicture from "../../../../shared/images/Woman1.png";
import { Image } from "antd";

const ParticipantCard = () => {
  return (
    <div className={styles.main_wrapper}>
      <div className={styles.left_part}>
        <div className={styles.image_wrapper}>
          <Image src={womanPicture} preview={false} />
        </div>
        <div className={styles.titles}>
          <div className={styles.title_upper}>
            <div className={styles.title}>Менеджер</div>
            <div className={styles.name}>Анастасия Иванова</div>
          </div>
          <div className={styles.title_lower}>
            <div className={styles.info}>
              <div className={styles.info_text}>
                Пол, возраст, дата рождения
              </div>
              <div className={styles.info_text}>Дополнительная информация</div>
            </div>
            <div className={styles.card_text}>
              Выполнены 2 задания из 3. Текущий суммарный балл 58/100. Ожидаемое
              время завершения тестового задания: 17:42.
            </div>
          </div>
        </div>
      </div>
      <div className={styles.percent}>66%</div>
      <div className={styles.progress_bar} />
    </div>
  );
};

export default ParticipantCard;
