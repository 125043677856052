import React from "react";
import EmployeesHeader from "./components/EmployeesHeader/EmployeesHeader";
import styles from "./EmployeesPage.module.scss";
import GCard from "../../components/GCard/GCard";
import EmployeeCard from "./components/EmployeeCard/EmployeeCard";
import BounceFromBottomContainer from "../../components/Animations/BounceFromBottomContainer";
import FadeInContainer from "../../components/Animations/FadeInContainer";

const EmployeesPage = () => {
  return (
    <div className={styles["page-wrapper"]}>
      <FadeInContainer>
        <EmployeesHeader />
      </FadeInContainer>
      <div>
        <GCard width={"100%"} type={"light"} shape={"balanced"}>
          <div className={styles["card-wrapper"]}>
            <BounceFromBottomContainer index={0}>
              <EmployeeCard isActive />
            </BounceFromBottomContainer>
            <BounceFromBottomContainer index={1}>
              <EmployeeCard isActive />
            </BounceFromBottomContainer>
            <BounceFromBottomContainer index={2}>
              <EmployeeCard isActive />
            </BounceFromBottomContainer>
            <BounceFromBottomContainer index={3}>
              <EmployeeCard isActive />
            </BounceFromBottomContainer>
            <BounceFromBottomContainer index={4}>
              <EmployeeCard isActive />
            </BounceFromBottomContainer>
            <BounceFromBottomContainer index={5}>
              <EmployeeCard />
            </BounceFromBottomContainer>
            <BounceFromBottomContainer index={6}>
              <EmployeeCard />
            </BounceFromBottomContainer>
            <BounceFromBottomContainer index={7}>
              <EmployeeCard />
            </BounceFromBottomContainer>
            <BounceFromBottomContainer index={8}>
              <EmployeeCard />
            </BounceFromBottomContainer>
            <BounceFromBottomContainer index={9}>
              <EmployeeCard />
            </BounceFromBottomContainer>
          </div>
        </GCard>
      </div>
    </div>
  );
};

export default EmployeesPage;
