import React from "react";
import styles from "../PersonnelReserve/PersonnelReservePage.module.scss";
import GHeader from "../../components/GHeader/GHeader";
import PersonnelReserveContent from "../PersonnelReserve/components/PersonnelReserveContent/PersonnelReserveContent";

const NewEmployeesPage = () => {
  return (
    <div className={styles.page_wrapper}>
      <GHeader title={"Новые сотрудники"} />
      <PersonnelReserveContent isNewEmployees />
    </div>
  );
};
export default NewEmployeesPage;
