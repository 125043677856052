import React from "react";
import styles from "./FirstBlob.module.scss";
import GCard from "../../../../components/GCard/GCard";
import EyeIcon from "../../../../components/Icons/EyeIcon";
import TrashIcon from "../../../../components/Icons/TrashIcon";
import EditIcon from "../../../../components/Icons/EditIcon";
import { Rate } from "antd";

const FirstBlob = () => {
  return (
    <div className={styles["first-blob"]}>
      <GCard isFullWidth isFullHeight type={"light"}>
        <div className={styles["first-row"]}>
          <span className={styles["gray-text"]}>
            Вакансия создана: 00 месяц 0000
          </span>
          <div className={styles["icons-wrapper"]}>
            <EyeIcon />
            <TrashIcon />
            <EditIcon />
          </div>
        </div>
        <div className={styles["second-row"]}>
          <span className={styles["title"]}>HR-специалист</span>
          <span className={styles["blob"]}>Опубликованная</span>
          <Rate value={4} />
          <span className={styles["small-text"]}>5 отзывов</span>
        </div>
        <div className={styles["third-row"]}>
          <span className={styles["normal-text"]}>
            Адрес: Город, улица, дом
          </span>
        </div>
        <div className={styles["fourth-row"]}>
          <div className={styles["first-column"]}>
            <span className={styles["normal-text"]}>
              В компанию требуется HR-специалист для управления процессами
              подбора, адаптации и развития персонала. Основные обязанности
              включают проведение собеседований, разработку программ обучения,
              оценку эффективности сотрудников и решение вопросов трудового
              законодательства. Ожидается, что кандидат будет активно
              сотрудничать с руководством для обеспечения успешного выполнения
              HR-стратегий.
            </span>
          </div>
          <div className={styles["second-column"]}>
            <span className={styles["title"]}>Специализация</span>
            <span className={styles["normal-text"]}>Специализация</span>
          </div>
          <div className={styles["third-column"]}>
            <div className={styles["text-wrapper"]}>
              <span className={styles["title"]}>Доход</span>
              <span className={styles["gray-text"]}>На руки</span>
            </div>
            <span className={styles["normal-text"]}>от 70 000</span>
          </div>
        </div>
        <div className={styles["fifth-row"]}>
          <div className={styles["first-wrapper"]}>
            <span className={styles["label"]}>Где искать сотрудников</span>
            <div className={styles["blobs-wrapper"]}>
              <span className={styles["blobbed-text"]}>Город</span>
              <span className={styles["blobbed-text"]}>Город</span>
              <span className={styles["blobbed-text"]}>Город</span>
            </div>
          </div>
          <div className={styles["second-wrapper"]}>
            <span className={styles["label"]}>План найма</span>
            <div className={styles["blobs-wrapper"]}>
              <span className={styles["blobbed-text"]}>Число человек</span>
              <span className={styles["blobbed-text"]}>Новая ставка</span>
            </div>
          </div>
          <div className={styles["third-wrapper"]}>
            <span className={styles["title"]}>Дата выхода</span>
            <div className={styles["blobs-wrapper"]}>
              <span className={styles["blobbed-text"]}>00 месяц</span>
            </div>
          </div>
        </div>
      </GCard>
    </div>
  );
};

export default FirstBlob;
