import React from "react";
import styles from "./GCard.module.scss";

interface GCardProps {
  children: React.ReactNode;
  type?: "light" | "normal";
  isFullWidth?: boolean;
  isFullHeight?: boolean;
  width?: string;
  shape?: "rounded" | "squared" | "balanced" | "twenty";
}

const GCard: React.FC<GCardProps> = ({
  children,
  type,
  isFullWidth,
  isFullHeight,
  width,
  shape,
}) => {
  return (
    <div
      className={`${styles["card"]} ${type === "light" ? styles["light"] : ""} 
      ${shape === "balanced" ? styles["balanced"] : ""}
      ${shape === "twenty" ? styles["twenty"] : ""}
      } ${isFullHeight ? styles["full-height"] : ""} ${
        isFullWidth ? styles["full-width"] : ""
      } ${shape === "squared" ? styles["squared"] : ""}`}
      style={{ width: `${width ?? ""}` }}
    >
      {children}
    </div>
  );
};

export default GCard;
