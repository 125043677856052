import React from "react";
import AnalyticsSurveyPageContent from "./components/AnalyticsSurveyPageContent/AnalyticsSurveyPageContent";
import styles from "./AnalyticsSurveyPage.module.scss";
import GHeader from "../../components/GHeader/GHeader";
import { ANALYTICS } from "../../app/providers/RouterConfig";

const AnalyticsSurveyPage = ({ isWitget }: any) => {
  return (
    <div className={styles.survey_page}>
      <GHeader
        title={"Опрос"}
        buttonText={"Создать опрос"}
        withArrow
        arrowLink={ANALYTICS}
      />

      <AnalyticsSurveyPageContent isWitget={isWitget} />
    </div>
  );
};

export default AnalyticsSurveyPage;
