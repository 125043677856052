import React from "react";

const PurpleGraphicIcon = () => {
  return (
    <svg
      width="227"
      height="226"
      viewBox="0 0 227 226"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M194.775 113.416C194.775 126.205 191.733 138.812 185.899 150.207C180.064 161.602 171.602 171.461 161.204 178.978C150.807 186.495 138.769 191.456 126.076 193.457C113.382 195.457 100.392 194.44 88.1694 190.488C75.9465 186.536 64.837 179.762 55.7492 170.719C46.6615 161.676 39.8536 150.621 35.8821 138.458C31.9106 126.296 30.8883 113.37 32.8988 100.739C34.9093 88.1078 39.8955 76.1294 47.4497 65.7831L73.8041 84.8364C69.2716 91.0441 66.2798 98.2312 65.0735 105.81C63.8672 113.389 64.4806 121.144 66.8635 128.442C69.2464 135.739 73.3311 142.372 78.7838 147.798C84.2364 153.224 90.9021 157.288 98.2359 159.66C105.57 162.031 113.363 162.641 120.98 161.441C128.596 160.24 135.818 157.263 142.057 152.753C148.295 148.243 153.373 142.328 156.873 135.491C160.374 128.654 162.199 121.089 162.199 113.416H194.775Z"
        fill="#BA1474"
      />
      <path
        d="M46.3932 67.1132C56.702 52.3423 71.6611 41.4087 88.909 36.0386C106.157 30.6684 124.711 31.1675 141.642 37.4571C158.573 43.7467 172.917 55.4685 182.408 70.772C191.9 86.0755 195.999 104.089 194.059 121.968L161.714 118.492C162.878 107.765 160.419 96.9565 154.724 87.7744C149.029 78.5923 140.422 71.5592 130.264 67.7854C120.105 64.0116 108.973 63.7122 98.6239 66.9343C88.2752 70.1564 79.2997 76.7165 73.1144 85.5791L46.3932 67.1132Z"
        fill="#FDE4FF"
      />
      <path
        d="M33.1544 127.639C29.6044 108.047 33.4358 87.8398 43.9163 70.8818C54.3967 53.9239 70.7907 41.4047 89.9637 35.7179L99.2568 66.7419C87.753 70.1539 77.9165 77.6655 71.6283 87.8403C65.34 98.015 63.0412 110.139 65.1712 121.894L33.1544 127.639Z"
        fill="#FFFAFF"
      />
    </svg>
  );
};

export default PurpleGraphicIcon;
