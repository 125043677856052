import React from "react";
import { Image } from "antd";
import Conference from "../../../../shared/images/Conference.svg";
import styles from "./ConfereneRoomContent.module.scss";
import ImageIcon from "../../../../components/Icons/ImageIcon";
import SiriIcon from "../../../../shared/images/SIriIcon.svg";
import Settings from "../../../../shared/images/Settings.svg";
import Call from "../../../../shared/images/Call.svg";
import BounceFromBottomContainer from "../../../../components/Animations/BounceFromBottomContainer";
import GChat from "../../../../components/GChat/GChat";

const ConferenceRoomContent = () => {
  return (
    <div className={styles["call-wrap"]}>
      <div className={styles["content-wrap"]}>
        <div className={styles["wrapper"]}>
          <div className={styles["image-wrapper"]}>
            <BounceFromBottomContainer index={0}>
              <Image src={Conference} preview={false} />
            </BounceFromBottomContainer>
            <div className={styles["siri-wrapper"]}>
              <BounceFromBottomContainer index={1}>
                <Image src={SiriIcon} preview={false} />
              </BounceFromBottomContainer>
            </div>
          </div>
          <div className={styles["icon-wrapper"]}>
            <BounceFromBottomContainer index={2}>
              <ImageIcon />
            </BounceFromBottomContainer>
          </div>
        </div>
        <div className={styles["settings-wrapper"]}>
          <BounceFromBottomContainer index={3}>
            <Image src={Settings} preview={false} />
          </BounceFromBottomContainer>
          <BounceFromBottomContainer index={4}>
            <Image src={Call} preview={false} />
          </BounceFromBottomContainer>
        </div>
      </div>
      <BounceFromBottomContainer index={5}>
        <div className={styles["chat-div-wrap"]}>
          <GChat />
        </div>
      </BounceFromBottomContainer>
    </div>
  );
};

export default ConferenceRoomContent;
