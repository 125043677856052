import React, { useState } from "react";
import { Input, Select, DatePicker, Checkbox } from "antd";
import styles from "./CreateVacancyMainForm.module.scss";
import GCard from "../../../../components/GCard/GCard";
import PlusIcon from "../../../../components/Icons/PlusIcon";
import MinusIcon from "../../../../components/Icons/MinusIcon";

const { Option } = Select;

const CreateVacancyMainForm = () => {
  const [value, setValue] = useState(1);
  const [secondValue, setSecondValue] = useState(1);

  const onChange = (e: any) => {
    setValue(e.target.value);
  };

  const onSecondChange = (e: any) => {
    setSecondValue(e.target.value);
  };

  return (
    <GCard>
      <div className={styles["form-container"]}>
        <div className={styles["first-row"]}>
          <div>
            <label className={styles["label"]} style={{ marginBottom: "4px" }}>
              Название вакансии
            </label>
            <Input placeholder="Название" />
            <span className={styles["small-text"]}>Указать код вакансии</span>
          </div>

          <div>
            <label className={styles["label"]} style={{ marginBottom: "4px" }}>
              Специализация
            </label>
            <Select placeholder="Select">
              <Option value="specialization1">Specialization 1</Option>
              <Option value="specialization2">Specialization 2</Option>
            </Select>
          </div>

          <div>
            <label className={styles["label"]} style={{ marginBottom: "4px" }}>
              Где искать сотрудников
            </label>
            <Select placeholder="Select">
              <Option value="location1">Location 1</Option>
              <Option value="location2">Location 2</Option>
            </Select>
          </div>
        </div>

        <div>
          <label className={styles["label"]}>План найма</label>
          <div>
            <div className={styles["second-row"]}>
              <Input
                placeholder="Сколько сотрудников"
                className={styles["input"]}
              />
              <PlusIcon />
              <MinusIcon />
              <Select placeholder="Select" className={styles["input"]}>
                <Option value="type1">Type 1</Option>
                <Option value="type2">Type 2</Option>
              </Select>
            </div>
            <div>
              <DatePicker
                placeholder="Select date"
                className={styles["date-picker"]}
              />
              <Checkbox>Remember me</Checkbox>
            </div>
          </div>
        </div>

        <div>
          <label className={styles["label"]}>
            Предполагаемый уровень дохода в месяц или за объем работ
          </label>

          <div className={styles["third-row"]}>
            <Input placeholder="от" className={styles["income-input"]} />
            <Input placeholder="до" className={styles["income-input"]} />
            <Select placeholder="Select" className={styles["select-inline"]}>
              <Option value="currency1">EUR</Option>
              <Option value="currency2">RUB</Option>
              <Option value="currency2">USD</Option>
            </Select>
          </div>
          <div className={styles["checkbox-wrapper"]}>
            <Checkbox
              checked={value === 1}
              onChange={() => onChange({ target: { value: 1 } })}
            >
              На руки
            </Checkbox>
            <span>Подсказка</span>
            <Checkbox
              checked={value === 2}
              onChange={() => onChange({ target: { value: 2 } })}
              style={{ marginTop: "8px" }}
            >
              До вычета налога
            </Checkbox>
            <span>Подсказка</span>
          </div>
        </div>

        <div>
          <label className={styles["label"]} style={{ marginBottom: "4px" }}>
            Где будет работать сотрудник
          </label>
          <div className={styles["employee-wrapper"]}>
            <div className={styles["checkbox-wrapper"]}>
              <Checkbox
                checked={secondValue === 1}
                onChange={() => onSecondChange({ target: { value: 1 } })}
              >
                Указывать адрес
              </Checkbox>
              <span>Подсказка</span>

              <Checkbox
                checked={secondValue === 2}
                onChange={() => onSecondChange({ target: { value: 2 } })}
                style={{ marginTop: "8px" }}
              >
                Не указывать адрес
              </Checkbox>
              <span>Подсказка</span>
            </div>
            <div style={{ marginTop: "12px" }}>
              <Input placeholder="Адрес" />
            </div>
          </div>
          <label
            className={styles["label"]}
            style={{ height: "10vh", display: "flex" }}
          >
            Опыт
          </label>
        </div>
      </div>
    </GCard>
  );
};

export default CreateVacancyMainForm;
