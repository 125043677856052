import React from "react";
import styles from "./ChatPageContent.module.scss";
import GChat from "../../../../components/GChat/GChat";
import AddFileIcon from "../../../../components/Icons/AddFileIcon";
import FourStripesIcon from "../../../../components/Icons/FourStripesIcon";
import CalendarIcon from "../../../../components/Icons/CalendarIcon";
import BorderlessPlusIcon from "../../../../components/Icons/BorderlessPlusIcon";
import ChatCard from "../ChatCard/ChatCard";
import BounceFromLeftContainer from "../../../../components/Animations/BounceFromLeftContainer";
import BounceFromBottomContainer from "../../../../components/Animations/BounceFromBottomContainer";
import BounceFromRightContainer from "../../../../components/Animations/BounceFromRightContainer";

const ChatPageContent = () => {
  const [chatIsOpen, setChatIsOpen] = React.useState(false);

  const handleClick = () => {
    setChatIsOpen(!chatIsOpen);
  };

  return (
    <div className={styles.main_wrapper}>
      <BounceFromLeftContainer index={0}>
        <div className={styles.icons}>
          <div className={styles.add_file}>
            <AddFileIcon />
          </div>
          <div className={styles.four_stripes}>
            <FourStripesIcon />
          </div>
          <div className={styles.calendar}>
            <CalendarIcon />
          </div>
          <div className={styles.plus}>
            <BorderlessPlusIcon />
          </div>
        </div>
      </BounceFromLeftContainer>
      <div className={styles.chat_list}>
        <BounceFromBottomContainer index={1}>
          <div onClick={handleClick}>
            <ChatCard />
          </div>
        </BounceFromBottomContainer>
        <BounceFromBottomContainer index={2}>
          <div onClick={handleClick}>
            <ChatCard />
          </div>
        </BounceFromBottomContainer>
        <BounceFromBottomContainer index={3}>
          <div onClick={handleClick}>
            <ChatCard />
          </div>
        </BounceFromBottomContainer>
        <BounceFromBottomContainer index={4}>
          <div onClick={handleClick}>
            <ChatCard />
          </div>
        </BounceFromBottomContainer>
        <BounceFromBottomContainer index={5}>
          <div onClick={handleClick}>
            <ChatCard />
          </div>
        </BounceFromBottomContainer>
        <BounceFromBottomContainer index={6}>
          <div onClick={handleClick}>
            <ChatCard />
          </div>
        </BounceFromBottomContainer>
        <BounceFromBottomContainer index={7}>
          <div onClick={handleClick}>
            <ChatCard />
          </div>
        </BounceFromBottomContainer>
        <BounceFromBottomContainer index={8}>
          <div onClick={handleClick}>
            <ChatCard />
          </div>
        </BounceFromBottomContainer>
        <BounceFromBottomContainer index={9}>
          <div onClick={handleClick}>
            <ChatCard />
          </div>
        </BounceFromBottomContainer>
      </div>
      <BounceFromRightContainer index={11}>
        <div
          className={`${styles.chat_field} ${
            chatIsOpen ? "" : styles[`chat_field--closed`]
          }`}
        >
          {chatIsOpen ? (
            <GChat />
          ) : (
            <div className={styles.chat_field_card}>
              Нажмите на чат, чтобы продолжить разговор.
            </div>
          )}
        </div>
      </BounceFromRightContainer>
    </div>
  );
};

export default ChatPageContent;
