import React from "react";
import CalendarBig from "../../shared/images/CalendarBig.svg";
import { Image } from "antd";
import CalendarTable from "./components/CalendarTable/CalendarTable";
import GHeader from "../../components/GHeader/GHeader";
import styles from "./CalendarPage.module.scss";
import BounceFromBottomContainer from "../../components/Animations/BounceFromBottomContainer";
import { INTERVIEWS } from "../../app/providers/RouterConfig";

const CalendarPage = () => {
  return (
    <div className={styles["page-wrapper"]}>
      <GHeader title={"Календарь"} withArrow arrowLink={INTERVIEWS} />
      <div className={styles["content-wrap"]}>
        <BounceFromBottomContainer index={0}>
          <Image src={CalendarBig} preview={false} />
        </BounceFromBottomContainer>
        <BounceFromBottomContainer index={1}>
          <div className={styles["table-wrap"]}>
            <CalendarTable />
          </div>
        </BounceFromBottomContainer>
      </div>
    </div>
  );
};

export default CalendarPage;
