import React from "react";
import VacationPageHeader from "./components/VacationPageHeader/VacationPageHeader";
import FirstBlob from "./components/FirstBlob/FirstBlob";
import SecondBlob from "./components/SecondBlob/SecondBlob";
import ThirdBlob from "./components/ThirdBlob/ThridBlob";
import styles from "./VacationPage.module.scss";
import PersonalCard from "../PersonnelReserve/components/PersonalCard/PersonalCard";
import GHeader from "../../components/GHeader/GHeader";
import { VACANCIES } from "../../app/providers/RouterConfig";
import BounceFromBottomContainer from "../../components/Animations/BounceFromBottomContainer";

const VacationPage = () => {
  return (
    <div className={styles["vacation-page"]}>
      <GHeader title={" HR-специалист"} withArrow arrowLink={VACANCIES} />
      <div>
        <div className={styles["blobs-wrapper"]}>
          <BounceFromBottomContainer index={0}>
            <FirstBlob />
          </BounceFromBottomContainer>
          <div className={styles["right-row"]}>
            <BounceFromBottomContainer index={1}>
              <SecondBlob />
            </BounceFromBottomContainer>
            <BounceFromBottomContainer index={2}>
              <ThirdBlob />
            </BounceFromBottomContainer>
          </div>
        </div>
        <BounceFromBottomContainer index={3}>
          <p className={styles["title"]}>Отклики</p>
        </BounceFromBottomContainer>
        <div className={styles["cards-wrapper"]}>
          <BounceFromBottomContainer index={4}>
            <PersonalCard isVacancy />
          </BounceFromBottomContainer>
          <BounceFromBottomContainer index={5}>
            <PersonalCard isVacancy />
          </BounceFromBottomContainer>
          <BounceFromBottomContainer index={6}>
            <PersonalCard isVacancy />
          </BounceFromBottomContainer>
        </div>
      </div>
    </div>
  );
};

export default VacationPage;
