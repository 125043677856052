import React from "react";
import { Image, Input, Select } from "antd";
import styles from "./TestTasksModal.module.scss";
import BlankImage from "../../../../shared/images/BlankImage.png";
import CirclePlusIcon from "../../../../components/Icons/CirclePlusIcon";
import TextArea from "antd/es/input/TextArea";

const TestTasksModal: React.FC = () => {
  return (
    <div>
      <div className={styles["top-wrap"]}>
        <div className={styles["cover-wrap"]}>
          <span className={styles["modal-text"]}>Добавьте обложку</span>
          <Image
            preview={false}
            src={BlankImage}
            height={"141px"}
            width={"156px"}
          />
        </div>
        <div className={styles["vacation-wrap"]}>
          <span className={styles["modal-text"]}>Название вакансии</span>
          <Input placeholder={"Название"} />
          <div className={styles["tag-wrap"]}>
            <div>
              <span className={styles["modal-text"]}>Тег</span>
            </div>
            <div className={styles["label-wrap"]}>
              <span className={styles["label"]}>Label</span>
              <span className={styles["label"]}>Label</span>
              <span className={styles["label"]}>Label</span>
              <span className={styles["label"]}>Label</span>
              <span className={styles["label"]}>Label</span>
              <CirclePlusIcon />
            </div>
          </div>
        </div>
      </div>
      <div className={styles["mid-wrap"]}>
        <div className={styles["time-wrap"]}>
          <span className={styles["modal-text"]}>Время прохождения</span>
          <Input placeholder={"00 минут"} />
        </div>
        <div className={styles["difficulty-wrap"]}>
          <span className={styles["modal-text"]}>Сложность</span>
          <Select placeholder={"Выберите сложность"} />
        </div>
      </div>
      <div className={styles["material-wrap"]}>
        <span className={styles["modal-text"]}>Описание материала</span>
        <TextArea placeholder={"Enter a description"} />
      </div>
    </div>
  );
};

export default TestTasksModal;
