import React from "react";

const AddAvatarIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1" y="1" width="32" height="32" rx="16" fill="#F6F6FF" />
      <rect
        x="1"
        y="1"
        width="32"
        height="32"
        rx="16"
        stroke="#C7BFFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="1 3"
      />
      <path
        d="M13 17H21"
        stroke="#101828"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 21V13"
        stroke="#101828"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AddAvatarIcon;
