import React from 'react';

const LayersIcon = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.2629 3.65002L23.6379 6.92502C25.7629 7.86252 25.7629 9.41252 23.6379 10.35L16.2629 13.625C15.4254 14 14.0504 14 13.2129 13.625L5.83789 10.35C3.71289 9.41252 3.71289 7.86252 5.83789 6.92502L13.2129 3.65002C14.0504 3.27502 15.4254 3.27502 16.2629 3.65002Z"
                stroke="#E5E1FC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M3.75 13.75C3.75 14.8 4.5375 16.0125 5.5 16.4375L13.9875 20.2125C14.6375 20.5 15.375 20.5 16.0125 20.2125L24.5 16.4375C25.4625 16.0125 26.25 14.8 26.25 13.75"
                stroke="#E5E1FC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M3.75 20C3.75 21.1625 4.4375 22.2125 5.5 22.6875L13.9875 26.4625C14.6375 26.75 15.375 26.75 16.0125 26.4625L24.5 22.6875C25.5625 22.2125 26.25 21.1625 26.25 20"
                stroke="#E5E1FC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    );
};

export default LayersIcon;