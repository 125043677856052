import React from "react";
import VacationCard from "./components/VacationCard/VacationCard";
import VacationPageHeader from "./components/VacationPageHeader/VacationPageHeader";

import styles from "./VacationsPage.module.scss";
import BounceFromBottomContainer from "../../components/Animations/BounceFromBottomContainer";
import FadeInContainer from "../../components/Animations/FadeInContainer";

const VacationsPage = () => {
  return (
    <div className={styles["vacations-page"]}>
      <FadeInContainer>
        <VacationPageHeader />
      </FadeInContainer>
      <div>
        <BounceFromBottomContainer index={0}>
          <VacationCard />
        </BounceFromBottomContainer>
        <BounceFromBottomContainer index={1}>
          <VacationCard />
        </BounceFromBottomContainer>
        <BounceFromBottomContainer index={2}>
          <VacationCard />
        </BounceFromBottomContainer>
        <BounceFromBottomContainer index={3}>
          <VacationCard />
        </BounceFromBottomContainer>
        <BounceFromBottomContainer index={4}>
          <VacationCard />
        </BounceFromBottomContainer>
      </div>
    </div>
  );
};

export default VacationsPage;
