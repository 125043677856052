import React from "react"
import { Avatar } from "antd"
import avatar1 from "./../../../../shared/images/AvatarGroup1.svg"
import avatar2 from "./../../../../shared/images/AvatarGroup2.svg"
import avatar3 from "./../../../../shared/images/AvatarGroup3.svg"
import avatar4 from "./../../../../shared/images/AvatarGroup4.svg"
import avatar5 from "./../../../../shared/images/AvatarGroup5.svg"
import avatar6 from "./../../../../shared/images/AvatarGroup6.svg"

const EmployeeAvatar: React.FC = () => (
  <>
    <Avatar.Group
      max={{
        count: 6,
        style: {
          color: "#f56a00",
          backgroundColor: "#fde3cf",
          width: 35,
          height: 35,
        },
      }}
    >
      <Avatar
        style={{ backgroundColor: "#fff", width: 35, height: 35 }}
        src={avatar1}
      />
      <Avatar
        style={{ backgroundColor: "#fff", width: 35, height: 35 }}
        src={avatar2}
      />
      <Avatar
        style={{ backgroundColor: "#fff", width: 35, height: 35 }}
        src={avatar3}
      />
      <Avatar
        style={{ backgroundColor: "#fff", width: 35, height: 35 }}
        src={avatar4}
      />
      <Avatar
        style={{ backgroundColor: "#fff", width: 35, height: 35 }}
        src={avatar5}
      />
      <Avatar
        style={{ backgroundColor: "#fff", width: 35, height: 35 }}
        src={avatar6}
      />
      <Avatar
        style={{ backgroundColor: "#fff", width: 35, height: 35 }}
        src={avatar6}
      />
      <Avatar
        style={{ backgroundColor: "#fff", width: 35, height: 35 }}
        src={avatar6}
      />
      <Avatar
        style={{ backgroundColor: "#fff", width: 35, height: 35 }}
        src={avatar6}
      />
      <Avatar
        style={{ backgroundColor: "#fff", width: 35, height: 35 }}
        src={avatar6}
      />
      <Avatar
        style={{ backgroundColor: "#fff", width: 35, height: 35 }}
        src={avatar6}
      />
    </Avatar.Group>
  </>
)

export default EmployeeAvatar
