import React from "react";
import styles from "./TestTaskPage.module.scss";
import TaskHeader from "./components/TaskHeader/TaskHeader";
import TaskContent from "./components/TaskContent/TaskContent";
import TaskProgress from "./components/TaskProgress/TaskProgress";
import BounceFromRightContainer from "../../components/Animations/BounceFromRightContainer";

interface TestTaskPageProps {
  isAdaptation?: boolean;
}

const TestTaskPage: React.FC<TestTaskPageProps> = ({ isAdaptation }) => {
  const [currentTab, setCurrentTab] = React.useState<"material" | "progress">(
    "material"
  );

  const handleTabChange = (tab: "material" | "progress") => {
    setCurrentTab(tab);
  };

  return (
    <div className={styles.main_wrapper}>
      <TaskHeader
        activeTab={currentTab}
        handleTabChange={handleTabChange}
        isAdaptation={isAdaptation}
      />
      {currentTab === "material" ? (
        <BounceFromRightContainer>
          <TaskContent />
        </BounceFromRightContainer>
      ) : (
        <TaskProgress />
      )}
    </div>
  );
};

export default TestTaskPage;
