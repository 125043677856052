import React from "react";
import AnalyticsPageContent from "./components/AnalyticsPageContent/AnalyticsPageContent";
import styles from "./AnalyticsPage.module.scss";
import GHeader from "../../components/GHeader/GHeader";

const AnalyticsPage = () => {
  return (
    <div className={styles.analytics_page}>
      <GHeader title={"Аналитика компании"} />
      <AnalyticsPageContent />
    </div>
  );
};

export default AnalyticsPage;
