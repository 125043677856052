import React from "react";
import styles from "./AnalyticsPageCardSub.module.scss";
import { useNavigate } from "react-router";
import { ANALYTICS_SURVEY_WITGET } from "../../../../app/providers/RouterConfig";

interface AnalyticsPageCardSubProps {
  title: string;
  tag: string;
  tagClass: any;
}

const AnalyticsPageCardSub: React.FC<AnalyticsPageCardSubProps> = ({
  tagClass,
  tag,
  title,
}) => {
  const navigate = useNavigate();
  return (
    <div
      className={styles.card_box}
      onClick={() => navigate(ANALYTICS_SURVEY_WITGET)}
    >
      <div className={styles.card_title}>{title}</div>
      <div className={styles[tagClass]}>{tag}</div>
    </div>
  );
};

export default AnalyticsPageCardSub;
