import React from "react";

const MessagesIcon = () => {
    return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M19.2393 17.5313L19.6455 20.8229C19.7497 21.6875 18.8226 22.2916 18.083 21.8437L13.7184 19.25C13.2393 19.25 12.7705 19.2188 12.3122 19.1563C13.083 18.25 13.5413 17.1042 13.5413 15.8646C13.5413 12.9062 10.9788 10.5105 7.81218 10.5105C6.60384 10.5105 5.48927 10.8542 4.56218 11.4583C4.53093 11.1979 4.52051 10.9375 4.52051 10.6667C4.52051 5.92708 8.63509 2.08334 13.7184 2.08334C18.8018 2.08334 22.9163 5.92708 22.9163 10.6667C22.9163 13.4792 21.4684 15.9688 19.2393 17.5313Z"
            stroke="#5B50A9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path
            d="M13.5413 15.8646C13.5413 17.1041 13.083 18.25 12.3122 19.1563C11.2809 20.4063 9.64551 21.2083 7.81217 21.2083L5.09342 22.8229C4.63509 23.1041 4.05175 22.7187 4.11425 22.1875L4.37467 20.1354C2.97884 19.1667 2.08301 17.6146 2.08301 15.8646C2.08301 14.0312 3.06218 12.4167 4.56218 11.4583C5.48926 10.8542 6.60384 10.5104 7.81217 10.5104C10.9788 10.5104 13.5413 12.9062 13.5413 15.8646Z"
            stroke="#5B50A9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    )
}

export default MessagesIcon;