import React from "react";
import styles from "./PersonnelReservePage.module.scss";
import PersonnelReserveHeader from "./components/PersonnelReserveHeader/PersonnelReserveHeader";
import PersonnelReserveContent from "./components/PersonnelReserveContent/PersonnelReserveContent";
import MainMenu from "../../components/MainMenu/MainMenu";
import GHeader from "../../components/GHeader/GHeader";

const PersonnelReservePage = () => {
  return (
    <div className={styles.page_wrapper}>
      <GHeader title={"Кадровый резерв"} />
      <PersonnelReserveContent />
    </div>
  );
};
export default PersonnelReservePage;
