import React from "react";
import { motion, Variants } from "framer-motion";

interface BounceFromTopContainerProps {
  index?: number;
  children: React.ReactNode;
}

const bounceFromTopVariants: Variants = {
  hidden: {
    opacity: 0,
    x: 0,
    y: -50,
  },
  visible: (index: number) => ({
    opacity: 1,
    x: 0,
    y: 0,
    transition: {
      delay: index * 0.1,
      type: "spring",
      stiffness: 100,
      duration: 0.6,
    },
  }),
};

const BounceFromTopContainer: React.FC<BounceFromTopContainerProps> = ({
  index,
  children,
}) => {
  return (
    <motion.div
      custom={index ?? 0}
      initial="hidden"
      animate="visible"
      variants={bounceFromTopVariants}
    >
      {children}
    </motion.div>
  );
};

export default BounceFromTopContainer;
