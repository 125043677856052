import React from "react";
import styles from "./TargetCompanyGraphics.module.scss";
import OrangeGraphicIcon from "../../../../components/Icons/OrangeGraphicIcon";
import BlueGraphicIcon from "../../../../components/Icons/BlueGraphicIcon";
import PurpleGraphicIcon from "../../../../components/Icons/PurpleGraphicIcon";

interface TargetCompanyGraphicsProps {
  title1: string;
  subtitle1: string;
  subtitle2: string;
  subtitle3: string;
  title2: string;
  subtitle4: string;
  subtitle5: string;
  subtitle6: string;
  title3: string;
  subtitle7: string;
  subtitle8: string;
  subtitle9: string;
}

const TargetCompanyGraphics: React.FC<TargetCompanyGraphicsProps> = ({
  title1,
  title2,
  title3,
  subtitle1,
  subtitle2,
  subtitle3,
  subtitle4,
  subtitle5,
  subtitle6,
  subtitle7,
  subtitle8,
  subtitle9,
}) => {
  return (
    <div className={styles["card-wrapper"]}>
      <div className={styles["graphic-card-wrap"]}>
        <OrangeGraphicIcon />
        <div className={styles["graphic-card"]}>
          <h3>{title1}</h3>
          <span>{subtitle1}</span>
          <span>{subtitle2}</span>
          <span>{subtitle3}</span>
        </div>
      </div>
      <div className={styles["graphic-card-wrap"]}>
        <div style={{ width: "180px" }}>
          <BlueGraphicIcon />
        </div>
        <div className={styles["graphic-card"]}>
          <h3>{title2}</h3>
          <span>{subtitle4}</span>
          <span>{subtitle5}</span>
          <span>{subtitle6}</span>
        </div>
      </div>
      <div className={styles["graphic-card-wrap"]}>
        <PurpleGraphicIcon />
        <div className={styles["graphic-card"]}>
          <h3>{title3}</h3>
          <span>{subtitle7}</span>
          <span>{subtitle8}</span>
          <span>{subtitle9}</span>
        </div>
      </div>
    </div>
  );
};

export default TargetCompanyGraphics;
