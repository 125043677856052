import styles from "./EmployeePosition.module.scss"

const EmployeePosition = () => {
  return (
    <div className={styles["emplyee-position"]}>
      <h4>Должность</h4>
      <span>00.00.00 -00.00.00 </span>
    </div>
  )
}

export default EmployeePosition