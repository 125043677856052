import React from "react";
import styles from "./ThirdBlob.module.scss";
import GCard from "../../../../components/GCard/GCard";
import HeadHunterIcon from "../../../../components/Icons/HeadHunterIcon";

const ThirdBlob = () => {
  return (
    <div className={styles["third-blob"]}>
      <GCard type={"light"} isFullWidth>
        <div className={styles["text-wrapper"]}>
          <div className={styles["centered"]}>
            <span className={styles["title"]}>14</span>
            <span className={styles["description"]}>Просмотров</span>
          </div>
          <div className={styles["centered"]}>
            <span className={styles["title"]}>3</span>
            <span className={styles["description"]}>Откликов</span>
          </div>
          <div className={styles["centered"]}>
            <span className={styles["title"]}>21%</span>
            <span className={styles["description"]}>Конверсия</span>
          </div>
        </div>
        <div className={styles["hh-wrapper"]}>
          <HeadHunterIcon />
        </div>
      </GCard>
    </div>
  );
};

export default ThirdBlob;
