import React from "react";
import styles from "./AnalyticsPageCard.module.scss";

interface AnalyticsPageCardProps {
  circleClass: any;
  title: string;
  description: string;
}

const AnalyticsPageCard: React.FC<AnalyticsPageCardProps> = ({
  circleClass,
  title,
  description,
}) => {
  return (
    <div className={styles.card_box}>
      <div>
        <div className={`${styles.circle} ${styles[circleClass]}`}></div>
      </div>

      <div className={styles.text_box}>
        <h3 className={styles.title}>{title}</h3>
        <div className={styles.page}>{description}</div>
      </div>
    </div>
  );
};

export default AnalyticsPageCard;
