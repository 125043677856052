import React from "react";

const OrangeGraphicIcon = () => {
  return (
    <svg
      width="227"
      height="226"
      viewBox="0 0 227 226"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M194.775 112.861C194.775 125.65 191.733 138.257 185.899 149.651C180.064 161.046 171.602 170.905 161.204 178.422C150.807 185.939 138.769 190.901 126.076 192.901C113.382 194.902 100.392 193.885 88.1694 189.933C75.9465 185.981 64.837 179.207 55.7492 170.164C46.6615 161.121 39.8536 150.066 35.8821 137.903C31.9106 125.74 30.8883 112.815 32.8988 100.184C34.9093 87.5524 39.8955 75.574 47.4497 65.2277L73.8041 84.281C69.2716 90.4887 66.2798 97.6758 65.0735 105.255C63.8672 112.833 64.4806 120.589 66.8635 127.886C69.2464 135.184 73.3311 141.817 78.7838 147.243C84.2364 152.668 90.9021 156.733 98.2359 159.104C105.57 161.475 113.363 162.086 120.98 160.885C128.596 159.685 135.818 156.708 142.057 152.198C148.295 147.687 153.373 141.772 156.873 134.935C160.374 128.098 162.199 120.534 162.199 112.861H194.775Z"
        fill="#E8AA61"
      />
      <path
        d="M46.3932 66.5578C56.702 51.7869 71.6611 40.8533 88.909 35.4831C106.157 30.1129 124.711 30.612 141.642 36.9017C158.573 43.1913 172.917 54.9131 182.408 70.2166C191.9 85.5201 195.999 103.534 194.059 121.413L161.714 117.937C162.878 107.209 160.419 96.4011 154.724 87.219C149.029 78.0369 140.422 71.0038 130.264 67.23C120.105 63.4562 108.973 63.1567 98.6239 66.3789C88.2752 69.601 79.2997 76.1611 73.1144 85.0236L46.3932 66.5578Z"
        fill="#E8CAA7"
      />
      <path
        d="M33.1544 127.083C29.6044 107.491 33.4358 87.2844 43.9163 70.3264C54.3967 53.3684 70.7907 40.8493 89.9637 35.1625L99.2568 66.1865C87.753 69.5985 77.9165 77.1101 71.6283 87.2848C65.34 97.4596 63.0412 109.584 65.1712 121.339L33.1544 127.083Z"
        fill="#C85616"
      />
    </svg>
  );
};

export default OrangeGraphicIcon;
