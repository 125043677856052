import React from "react";
import styles from "./EmployeeDocumentContent.module.scss";
import DocumentCard from "../DocumentCard/DocumentCard";
import document from "../../../../shared/images/document.jpg";
import { Image } from "antd";
import BounceFromBottomContainer from "../../../../components/Animations/BounceFromBottomContainer";
import BounceFromTopContainer from "../../../../components/Animations/BounceFromTopContainer";

const EmployeeDocumentContent: React.FC<{ isCerts: boolean | undefined }> = ({
  isCerts,
}) => {
  return (
    <div className={styles.main_wrapper}>
      <BounceFromBottomContainer index={0}>
        <DocumentCard isCerts={isCerts} />
      </BounceFromBottomContainer>
      <div className={styles.pages_grid}>
        <BounceFromTopContainer index={1}>
          <div className={styles.image_wrapper}>
            <Image width={"17vw"} src={document} />
          </div>
        </BounceFromTopContainer>
        <BounceFromTopContainer index={2}>
          <div className={styles.image_wrapper}>
            <Image width={"17vw"} src={document} />
          </div>
        </BounceFromTopContainer>
        <BounceFromTopContainer index={3}>
          <div className={styles.image_wrapper}>
            <Image width={"17vw"} src={document} />
          </div>
        </BounceFromTopContainer>
        <BounceFromTopContainer index={4}>
          <div className={styles.image_wrapper}>
            <Image width={"17vw"} src={document} />
          </div>
        </BounceFromTopContainer>
        <BounceFromTopContainer index={5}>
          <div className={styles.image_wrapper}>
            <Image width={"17vw"} src={document} />
          </div>
        </BounceFromTopContainer>
        <BounceFromTopContainer index={6}>
          <div className={styles.image_wrapper}>
            <Image width={"17vw"} src={document} />
          </div>
        </BounceFromTopContainer>
        <BounceFromTopContainer index={7}>
          <div className={styles.image_wrapper}>
            <Image width={"17vw"} src={document} />
          </div>
        </BounceFromTopContainer>
        <BounceFromTopContainer index={8}>
          <div className={styles.image_wrapper}>
            <Image width={"17vw"} src={document} />
          </div>
        </BounceFromTopContainer>
        <BounceFromTopContainer index={9}>
          <div className={styles.image_wrapper}>
            <Image width={"17vw"} src={document} />
          </div>
        </BounceFromTopContainer>
        <BounceFromTopContainer index={10}>
          <div className={styles.image_wrapper}>
            <Image width={"17vw"} src={document} />
          </div>
        </BounceFromTopContainer>
      </div>
    </div>
  );
};

export default EmployeeDocumentContent;
