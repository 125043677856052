import React from "react";
import AnalyticsPageCard from "../AnalyticsPageCard/AnalyticsPageCard";
import styles from "./AnalyticsPageContent.module.scss";
import AnalyticsPageCardSub from "../AnalyticsPageCardSub/AnalyticsPageCardSub";
import BounceFromBottomContainer from "../../../../components/Animations/BounceFromBottomContainer";

const AnalyticsPageContent = () => {
  return (
    <div className={styles.main_wrapper}>
      <BounceFromBottomContainer>
        <div className={styles.conainer_left}>
          <BounceFromBottomContainer index={0}>
            <AnalyticsPageCard
              circleClass={"circle_1"}
              title={"Рынок и тренды"}
              description={
                "Эта аналитика фокусируется на изучении текущих тенденций и изменений на рынке. Включает в себя анализ конкурентной среды, исследование поведения потребителей и прогнозирование будущих трендов. Основная цель - предоставить компании актуальную информацию для разработки стратегий и принятия решений."
              }
            />
          </BounceFromBottomContainer>
          <BounceFromBottomContainer index={1}>
            <AnalyticsPageCard
              circleClass={"circle_2"}
              title={"Эффективность продаж"}
              description={
                "Данная аналитика направлена на оценку и оптимизацию процесса продаж. Анализируются ключевые показатели эффективности (KPI), такие как объем продаж, конверсия, средний чек и жизненный цикл клиента. Результаты анализа помогают выявить сильные и слабые стороны, а также разработать рекомендации по улучшению показателей."
              }
            />
          </BounceFromBottomContainer>
          <BounceFromBottomContainer index={2}>
            <AnalyticsPageCard
              circleClass={"circle_3"}
              title={"Потребительские предпочтения"}
              description={
                "Эта аналитика занимается исследованием предпочтений и ожиданий потребителей. Включает в себя сбор и анализ данных о поведении клиентов, их предпочтениях, уровнях удовлетворенности и лояльности. Цель - создать детализированный портрет целевой аудитории и разработать стратегии для повышения удовлетворенности и удержания клиентов."
              }
            />
          </BounceFromBottomContainer>
          <BounceFromBottomContainer index={3}>
            <AnalyticsPageCard
              circleClass={"circle_1"}
              title={"Операционная эффективность"}
              description={
                "Эта аналитика направлена на оценку и улучшение внутренних бизнес-процессов компании. Анализируются различные аспекты операционной деятельности, такие как управление цепочками поставок, производство, логистика и управление ресурсами. Цель - выявить узкие места, оптимизировать процессы и снизить издержки для повышения общей эффективности и производительности компании."
              }
            />
          </BounceFromBottomContainer>
        </div>
      </BounceFromBottomContainer>
      <BounceFromBottomContainer index={4}>
        <div className={styles.conainer_right}>
          <BounceFromBottomContainer index={5}>
            <AnalyticsPageCardSub
              tagClass={"tag_1"}
              title={"Удовлетворенность клиентов"}
              tag={"Клиентский опыт"}
            />
          </BounceFromBottomContainer>
          <BounceFromBottomContainer index={6}>
            <AnalyticsPageCardSub
              tagClass={"tag_1"}
              title={"Качество обслуживания"}
              tag={"Сервис"}
            />
          </BounceFromBottomContainer>
          <BounceFromBottomContainer index={7}>
            <AnalyticsPageCardSub
              tagClass={"tag_1"}
              title={"Потребительские предпочтения"}
              tag={"Покупатель"}
            />
          </BounceFromBottomContainer>
          <BounceFromBottomContainer index={8}>
            <AnalyticsPageCardSub
              tagClass={"tag_2"}
              title={"Работа и карьерное развитие"}
              tag={"Карьерный рост"}
            />
          </BounceFromBottomContainer>
          <BounceFromBottomContainer index={9}>
            <AnalyticsPageCardSub
              tagClass={"tag_2"}
              title={"Впечатления от нового продукта"}
              tag={"Новый продукт"}
            />
          </BounceFromBottomContainer>
          <BounceFromBottomContainer index={10}>
            <AnalyticsPageCardSub
              tagClass={"tag_1"}
              title={"Использование цифровых технологий"}
              tag={"Цифровизация"}
            />
          </BounceFromBottomContainer>
          <BounceFromBottomContainer index={11}>
            <AnalyticsPageCardSub
              tagClass={"tag_4"}
              title={"Эффективность маркетинговых кампаний"}
              tag={"Маркетинг"}
            />
          </BounceFromBottomContainer>
          <BounceFromBottomContainer index={12}>
            <AnalyticsPageCardSub
              tagClass={"tag_1"}
              title={"Условия труда и рабочая среда"}
              tag={"Рабочие условия"}
            />
          </BounceFromBottomContainer>
          <BounceFromBottomContainer index={13}>
            <AnalyticsPageCardSub
              tagClass={"tag_1"}
              title={"Лояльность клиентов"}
              tag={"Лояльность"}
            />
          </BounceFromBottomContainer>
          <BounceFromBottomContainer index={14}>
            <AnalyticsPageCardSub
              tagClass={"tag_4"}
              title={"Социальная ответственность и устойчивое развитие"}
              tag={"Устойчивое развитие"}
            />
          </BounceFromBottomContainer>
          <BounceFromBottomContainer index={15}>
            <AnalyticsPageCardSub
              tagClass={"tag_1"}
              title={"Удовлетворенность клиентов"}
              tag={"Клиентский опыт"}
            />
          </BounceFromBottomContainer>
          <BounceFromBottomContainer index={16}>
            <AnalyticsPageCardSub
              tagClass={"tag_1"}
              title={"Удовлетворенность клиентов"}
              tag={"Клиентский опыт"}
            />
          </BounceFromBottomContainer>
          <BounceFromBottomContainer index={17}>
            <AnalyticsPageCardSub
              tagClass={"tag_1"}
              title={"Удовлетворенность клиентов"}
              tag={"Клиентский опыт"}
            />
          </BounceFromBottomContainer>
          <BounceFromBottomContainer index={18}>
            <AnalyticsPageCardSub
              tagClass={"tag_1"}
              title={"Удовлетворенность клиентов"}
              tag={"Клиентский опыт"}
            />
          </BounceFromBottomContainer>
          <BounceFromBottomContainer index={19}>
            <AnalyticsPageCardSub
              tagClass={"tag_1"}
              title={"Удовлетворенность клиентов"}
              tag={"Клиентский опыт"}
            />
          </BounceFromBottomContainer>
          <BounceFromBottomContainer index={20}>
            <AnalyticsPageCardSub
              tagClass={"tag_1"}
              title={"Удовлетворенность клиентов"}
              tag={"Клиентский опыт"}
            />
          </BounceFromBottomContainer>
          <BounceFromBottomContainer index={21}>
            <AnalyticsPageCardSub
              tagClass={"tag_1"}
              title={"Удовлетворенность клиентов"}
              tag={"Клиентский опыт"}
            />
          </BounceFromBottomContainer>
        </div>
      </BounceFromBottomContainer>
    </div>
  );
};

export default AnalyticsPageContent;
