import React from "react";
import styles from "./ProgressBarCard.module.scss";
import EditIcon from "../../../components/Icons/EditIcon";
import TrashIcon from "../../../components/Icons/TrashIcon";
import BigProgressBarIcon from "../../../components/Icons/BigProgressBarIcon";
import WomanAvatarIcon from "../../../components/Icons/WomanAvatarIcon";
import PawnbrokerIcon from "../../../components/Icons/PawnbrokerIcon";
import CompanyAvatarIcon from "../../../components/Icons/CompanyAvatarIcon";
import MultipleAvatarsIcon from "../../../components/Icons/MultipleAvatarsIcon";
import AddAvatarIcon from "../../../components/Icons/AddAvatarIcon";
import GemplLogoIcon from "../../../components/Icons/GemplLogoIcon";

interface ProgressBarCardProps {
  title: string;
  startDate: string;
  endDate: string;
  name: string;
  email: string;
  iconType: "1" | "2" | "3";
}

const ProgressBarCard: React.FC<ProgressBarCardProps> = ({
  title,
  startDate,
  endDate,
  name,
  iconType,
  email,
}) => {
  const renderIcon = () => {
    switch (iconType) {
      case "1":
        return (
          <div className={styles["info-wrap"]}>
            <div className={styles["person-info-wrap"]}>
              <WomanAvatarIcon />
              <div className={styles["person-info"]}>
                <span>{name}</span>
                <span className={styles["email"]}>{email}</span>
              </div>
            </div>
            <div className={styles["pawnbroker-wrap"]}>
              <PawnbrokerIcon />
            </div>
          </div>
        );
      case "2":
        return (
          <div className={styles["info-wrap"]}>
            <div className={styles["person-info-wrap"]}>
              <div className={styles["gempl-logo"]}>
                <GemplLogoIcon />
              </div>
              <div className={styles["person-info"]}>
                <span>{name}</span>
                <span className={styles["email"]}>{email}</span>
              </div>
            </div>
            <div className={styles["pawnbroker-wrap"]}>
              <PawnbrokerIcon />
            </div>
          </div>
        );
      case "3":
        return (
          <div className={styles["info-wrap"]}>
            <div className={styles["person-info-wrap"]}>
              <div className={styles["avatars-wrap"]}>
                <MultipleAvatarsIcon />
                <AddAvatarIcon />
              </div>
            </div>
            <div className={styles["pawnbroker-wrap"]}>
              <PawnbrokerIcon />
            </div>
          </div>
        );
    }
  };
  return (
    <div className={styles["card-wrapper"]}>
      <div className={styles["card-content-wrap"]}>
        <div className={styles["name-wrap"]}>
          <div>
            <h3>{title}</h3>
          </div>
          <div className={styles["icons-wrap"]}>
            <EditIcon />
            <TrashIcon />
          </div>
        </div>
        <h3>
          {startDate} - {endDate}
        </h3>
        <span className={styles["progress"]}>Сейчас цель выполнена на 40%</span>
        <div className={styles["progress-bar-wrap"]}>
          <div className={styles["progress-bar-full"]}></div>
          <div className={styles["progress-bar"]}></div>
          <div className={styles["progress-bar"]}></div>
        </div>
        {renderIcon()}
        <span>
          Внедрение новых технологий и продуктов, а также совершенствование
          существующих процессов и решений для поддержания конкурентоспособности
          на рынке.
        </span>
      </div>
    </div>
  );
};

export default ProgressBarCard;
