import React from "react";
import styles from "./AnalyticsSurveyPageCardSub.module.scss";
import AnalyticsSurveyPageAvatarGroup from "../AnalyticsSurveyPageAvatarGroup/AnalyticsSurveyPageAvatarGroup";

const AnalyticsSurveyPageCardSub = () => {
  return (
    <div className={styles.card_box}>
      <div className={styles.card_title}>Отделение на Бауманской</div>

      <div className={styles.card_container_middle}>
        <div>
          Клиенты отмечают необходимость улучшения времени отклика службы
          поддержки.
        </div>

        <div>
          <AnalyticsSurveyPageAvatarGroup />
        </div>
      </div>

      <div className={styles.tag_1}>опрос</div>
    </div>
  );
};

export default AnalyticsSurveyPageCardSub;
