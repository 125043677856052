import React from "react";
import styles from "./TestTasksPage.module.scss";
import TestTasksModal from "./components/TestTasksModal/TestTasksModal";
import TestTasksCard from "./components/TestTasksCard/TestTasksCard";
import GHeader from "../../components/GHeader/GHeader";
import BounceFromBottomContainer from "../../components/Animations/BounceFromBottomContainer";

interface TestTasksPageProps {
  isAdaptation?: boolean;
}
const TestTasksPage: React.FC<TestTasksPageProps> = ({ isAdaptation }) => {
  return (
    <div className={styles["page-wrapper"]}>
      <GHeader
        title={`${isAdaptation ? "Адаптация" : "Тестовые задания"}`}
        buttonText={"Создать задание"}
        ModalContent={TestTasksModal}
      />
      <BounceFromBottomContainer index={0}>
        <TestTasksCard
          rectType={`${isAdaptation ? "2" : "1"}`}
          isAdaptation={isAdaptation}
        />
      </BounceFromBottomContainer>
      <BounceFromBottomContainer index={1}>
        <TestTasksCard rectType={"1"} isAdaptation={isAdaptation} />
      </BounceFromBottomContainer>
      <BounceFromBottomContainer index={2}>
        <TestTasksCard
          rectType={`${isAdaptation ? "3" : "1"}`}
          isAdaptation={isAdaptation}
        />
      </BounceFromBottomContainer>
      <BounceFromBottomContainer index={3}>
        <TestTasksCard rectType={"1"} isAdaptation={isAdaptation} />
      </BounceFromBottomContainer>
    </div>
  );
};

export default TestTasksPage;
