import React from 'react';

const TaskIcon = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.75 24.375H26.25" stroke="#E5E1FC" strokeWidth="1.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M13.75 15.625H26.25" stroke="#E5E1FC" strokeWidth="1.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M13.75 6.875H26.25" stroke="#E5E1FC" strokeWidth="1.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M3.75 6.875L5 8.125L8.75 4.375" stroke="#E5E1FC" strokeWidth="1.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M3.75 15.625L5 16.875L8.75 13.125" stroke="#E5E1FC" strokeWidth="1.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M3.75 24.375L5 25.625L8.75 21.875" stroke="#E5E1FC" strokeWidth="1.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>
    );
};

export default TaskIcon;