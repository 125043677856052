import React, {useState, useRef} from 'react';
import styles from './CompanyStructure.module.scss'
import EmployeeCard from "../EmployeeCard/EmployeeCard";
import CompanyStructureLines from "../../../../components/Icons/CompanyStructureLines";
import CompanyStructureVLine from "../../../../components/Icons/CompanyStructureVLine";

const CompanyStructureContent = () => {

    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [startY, setStartY] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [scrollTop, setScrollTop] = useState(0);

    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        if (!scrollContainerRef.current) return;

        setIsDragging(true);
        setStartX(e.pageX - scrollContainerRef.current.offsetLeft);
        setStartY(e.pageY - scrollContainerRef.current.offsetTop);
        setScrollLeft(scrollContainerRef.current.scrollLeft);
        setScrollTop(scrollContainerRef.current.scrollTop);
    };

    const handleMouseLeaveOrUp = () => {
        setIsDragging(false);
    };

    const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
        if (!isDragging || !scrollContainerRef.current) return;

        e.preventDefault();
        const x = e.pageX - scrollContainerRef.current.offsetLeft;
        const y = e.pageY - scrollContainerRef.current.offsetTop;
        const walkX = (x - startX) ;
        const walkY = (y - startY) ;
        scrollContainerRef.current.scrollLeft = scrollLeft - walkX;
        scrollContainerRef.current.scrollTop = scrollTop - walkY;
    };

    return (
        <div className={styles.scroll_container}
             ref={scrollContainerRef}
             onMouseDown={handleMouseDown}
             onMouseLeave={handleMouseLeaveOrUp}
             onMouseUp={handleMouseLeaveOrUp}
             onMouseMove={handleMouseMove}>
        <div className={styles.main_wrapper}>
            <EmployeeCard index={0} />
            <div className={styles.lines}>
                <CompanyStructureLines />
            </div>
            <div className={styles.card_row}>
                <EmployeeCard index={1}/>
                <EmployeeCard index={2}/>
                <EmployeeCard index={3}/>
                <EmployeeCard index={4}/>
            </div>
            <div className={styles.lines}>
                <CompanyStructureVLine />
            </div>
            <div className={styles.card_row}>
                <EmployeeCard index={5}/>
                <EmployeeCard index={6}/>
            </div>
            <div className={styles.card_row}>
                <EmployeeCard index={7}/>
                <EmployeeCard index={8}/>
            </div>
            <EmployeeCard index={9}/>
        </div>
        </div>
    );
};

export default CompanyStructureContent;