import React from "react";
import styles from "./TargetCompanyCard.module.scss";
import BorderlessMoreIcon from "../../../../components/Icons/BorderlessMoreIcon";
import BarIcon from "../../../../components/Icons/BarIcon";
import PawnbrokerIcon from "../../../../components/Icons/PawnbrokerIcon";
import CompanyAvatar from "../../../../shared/images/CompanyAvatar.png";
import WomanAvatarIcon from "../../../../components/Icons/WomanAvatarIcon";
import CompanyAvatarIcon from "../../../../components/Icons/CompanyAvatarIcon";
import MultipleAvatarsIcon from "../../../../components/Icons/MultipleAvatarsIcon";
import GemplLogoIcon from "../../../../components/Icons/GemplLogoIcon";
import { useNavigate } from "react-router";
import {
  INDIVIDUAL_COMPANY_TARGET,
  INDIVIDUAL_GROUP_TARGET,
  INDIVIDUAL_PERSON_TARGET,
} from "../../../../app/providers/RouterConfig";

interface TargetCompanyCardProps {
  title: string;
  startDate: string;
  endDate: string;
  name: string;
  email: string;
  iconType: "1" | "2" | "3";
}

const TargetCompanyCard: React.FC<TargetCompanyCardProps> = ({
  title,
  startDate,
  endDate,
  name,
  email,
  iconType,
}) => {
  const navigate = useNavigate();

  const navFunc = () => {
    switch (iconType) {
      case "1":
        return navigate(INDIVIDUAL_PERSON_TARGET);
      case "2":
        return navigate(INDIVIDUAL_COMPANY_TARGET);
      case "3":
        return navigate(INDIVIDUAL_GROUP_TARGET);
    }
  };

  const renderIcon = () => {
    switch (iconType) {
      case "1":
        return (
          <div className={styles["info-wrap"]}>
            <div className={styles["person-info-wrap"]}>
              <WomanAvatarIcon />
              <div className={styles["person-info"]}>
                <span>{name}</span>
                <span className={styles["email"]}>{email}</span>
              </div>
            </div>
            <div className={styles["pawnbroker-wrap"]}>
              <PawnbrokerIcon />
            </div>
          </div>
        );
      case "2":
        return (
          <div className={styles["info-wrap"]}>
            <div className={styles["person-info-wrap"]}>
              <div className={styles["gempl-logo"]}>
                <GemplLogoIcon />
              </div>
              <div className={styles["person-info"]}>
                <span>{name}</span>
                <span className={styles["email"]}>{email}</span>
              </div>
            </div>
            <div className={styles["pawnbroker-wrap"]}>
              <PawnbrokerIcon />
            </div>
          </div>
        );
      case "3":
        return (
          <div className={styles["info-wrap"]}>
            <div className={styles["person-info-wrap"]}>
              <MultipleAvatarsIcon />
            </div>
            <div className={styles["pawnbroker-wrap"]}>
              <PawnbrokerIcon />
            </div>
          </div>
        );
    }
  };
  return (
    <div className={styles["card-wrapper"]} onClick={navFunc}>
      <div className={styles["top-wrap"]}>
        <div className={styles["title-wrap"]}>
          <h3>{title}</h3>
          <BorderlessMoreIcon />
        </div>
        <div className={styles["time-wrap"]}>
          <h3>
            {startDate} - {endDate}
          </h3>
        </div>
        <div className={styles["bar-wrap"]}>
          <BarIcon />
        </div>
        {renderIcon()}
      </div>
    </div>
  );
};

export default TargetCompanyCard;
