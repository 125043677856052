import React from 'react';
import styles from "./TaskProgress.module.scss";
import ParticipantCard from "../ParticipantCard/ParticipantCard";
import BounceFromLeftContainer from "../../../../components/Animations/BounceFromLeftContainer";

const TaskProgress = () => {
    return (
        <div className={styles.main_wrapper}>
            <BounceFromLeftContainer index={0}>
                <ParticipantCard />
            </BounceFromLeftContainer>
            <BounceFromLeftContainer index={1}>
                <ParticipantCard />
            </BounceFromLeftContainer>
            <BounceFromLeftContainer index={2}>
                <ParticipantCard />
            </BounceFromLeftContainer>
            <BounceFromLeftContainer index={3}>
                <ParticipantCard />
            </BounceFromLeftContainer>
            <BounceFromLeftContainer index={4}>
                <ParticipantCard />
            </BounceFromLeftContainer>
            <BounceFromLeftContainer index={5}>
                <ParticipantCard />
            </BounceFromLeftContainer>
            <BounceFromLeftContainer index={6}>
                <ParticipantCard />
            </BounceFromLeftContainer>

        </div>
    );
};

export default TaskProgress;