import React from 'react';

const DownloadIcon = () => {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.1247 9.27084C20.8747 9.59376 22.4059 11.5208 22.4059 15.7396V15.875C22.4059 20.5313 20.5413 22.3958 15.8851 22.3958H9.10384C4.44759 22.3958 2.58301 20.5313 2.58301 15.875V15.7396C2.58301 11.5521 4.09342 9.62501 7.78092 9.28126"
                stroke="#5B50A9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12.5 2.08334V15.5" stroke="#5B50A9" strokeWidth="1.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M15.9899 13.1771L12.5003 16.6667L9.01074 13.1771" stroke="#5B50A9" strokeWidth="1.5"
                  strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default DownloadIcon;