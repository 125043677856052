import React from "react";
import styles from "./AnalyticsSurveyPageCard.module.scss";

const AnalyticsSurveyPageCard = () => {
  return (
    <div className={styles.card_box}>
      <div>
        <div className={`${styles.circle} ${styles["circle_1"]}`}></div>
      </div>

      <div className={styles.text_box}>
        <h3 className={styles.title}>Удовлетворенность клиентов</h3>
        <p className={styles.date}>14.06.2024</p>
        <div className={styles.page}>
          Данный опрос направлен на оценку уровня удовлетворенности наших
          клиентов. Мы стремимся постоянно улучшать качество предоставляемых
          услуг и продуктов. Ваше мнение поможет нам понять, что мы делаем
          правильно и какие области требуют улучшений.
        </div>
      </div>
    </div>
  );
};

export default AnalyticsSurveyPageCard;
