import React from 'react';
import styles from './Message.module.scss';
import WomanAvatar from "../../../Icons/WomanAvatar";
import FadeInContainer from "../../../Animations/FadeInContainer";

interface IMessageProps {
    source: "incoming" | "outgoing";
    messageText: string;
}

const Message:React.FC<IMessageProps> = ({messageText, source}) => {
    return (
        <FadeInContainer>
        <div className={`${styles.wrapper} ${styles[`wrapper--${source}`]}`}>
            <div className={`${styles.message} ${styles[`message--${source}`]}`}>{messageText}</div>
            <div className={styles.avatar_wrapper}>
                <WomanAvatar />
            </div>
        </div>
        </FadeInContainer>
    );
};

export default Message;