import React from "react";
import styles from "./TaskPart.module.scss";
import ArrowDownIcon from "../../../../components/Icons/ArrowDownIcon";
import EditIcon from "../../../../components/Icons/EditIcon";
import TrashIcon from "../../../../components/Icons/TrashIcon";

const TaskPart = () => {
  const [collapsed, setCollapsed] = React.useState(true);

  const handleWrapping = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div className={styles.card_main_wrapper}>
      <div className={styles.card_title}>
        <h3>Организация встречи с руководителями отдела продаж</h3>
        <div className={styles.right_title}>
          <h3>Содержание</h3>
          <div
            className={
              collapsed ? styles.wrap_button_down : styles.wrap_button_up
            }
            onClick={handleWrapping}
          >
            <ArrowDownIcon />
          </div>
        </div>
      </div>
      <div
        className={`${styles.hidden_block} ${
          collapsed
            ? styles["hidden_block--collapsed"]
            : styles["hidden_block--opened"]
        }`}
      >
        <div className={styles.hidden_block_title}>
          <h3>Задание</h3>
          <div className={styles.icons}>
            <EditIcon />
            <TrashIcon />
          </div>
        </div>
        <div className={styles.hidden_block_text}>
          • Выберите удобное время для встречи с пятью ключевыми клиентами,
          учитывая их разные часовые пояса. Составьте расписание встреч на
          следующую неделю. <br /> • Предложите три возможных варианта места
          проведения встречи (онлайн/офлайн), обоснуйте ваш выбор.
          <br /> • Подготовьте краткую повестку дня для каждой встречи,
          включающую основные вопросы для обсуждения.
        </div>
      </div>
    </div>
  );
};

export default TaskPart;
