import { Routing } from "../pages";
import React from "react";
import Providers from "./providers/index";
import "./styles/index.scss";

function Index() {
  return (
    <Providers>
      <Routing />
    </Providers>
  );
}

export default Index;
