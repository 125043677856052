import React from "react";
import styles from "./LeftContent.module.scss";
import GoalCard from "../GoalCard/GoalCard";
import ProfileCard from "../ProfileCard/ProfileCard";
import WeeklyDiagram from "../../../../components/Icons/WeeklyDiagram";
import BounceFromBottomContainer from "../../../../components/Animations/BounceFromBottomContainer";

const LeftContent = () => {
  return (
    <div className={styles.main_wrapper}>
      <h1 className={styles.header}>Личный кабинет</h1>
      <div className={styles.goals}>
        <BounceFromBottomContainer index={0}>
          <GoalCard title={"Повышение квалификации"} />
        </BounceFromBottomContainer>
        <BounceFromBottomContainer index={1}>
          <GoalCard title={"Продвижение по службе"} />
        </BounceFromBottomContainer>
        <BounceFromBottomContainer index={2}>
          <GoalCard title={"Развитие лидерских качеств"} />
        </BounceFromBottomContainer>
        <BounceFromBottomContainer index={3}>
          <GoalCard title={"Оптимизация рабочих процессов"} />
        </BounceFromBottomContainer>
        <BounceFromBottomContainer index={4}>
          <GoalCard title={"Баланс работы и личной жизни"} />
        </BounceFromBottomContainer>
        <BounceFromBottomContainer index={5}>
          <GoalCard title={"Установление профессиональных связей"} />
        </BounceFromBottomContainer>
      </div>
      <BounceFromBottomContainer index={6}>
        <div className={styles.analytics}>
          <WeeklyDiagram />
          <div className={styles.content}>
            <h3 className={styles.title}>Аналитика недели</h3>
            <p className={styles.text}>
              Получите свежие данные по ключевым показателям за прошедшую
              неделю. Следите за тенденциями и улучшайте свои решения с нашими
              детальными отчетами и графиками.
            </p>
            <div className={styles.button}>Посмотреть по отделам</div>
          </div>
        </div>
      </BounceFromBottomContainer>
      <div className={styles.profile_cards}>
        <BounceFromBottomContainer index={7}>
          <ProfileCard />
        </BounceFromBottomContainer>
        <BounceFromBottomContainer index={8}>
          <ProfileCard />
        </BounceFromBottomContainer>
        <BounceFromBottomContainer index={9}>
          <ProfileCard />
        </BounceFromBottomContainer>
        <BounceFromBottomContainer index={10}>
          <ProfileCard />
        </BounceFromBottomContainer>
      </div>
    </div>
  );
};

export default LeftContent;
