import React from 'react';
import styles from "./EmployeeCard.module.scss";
import WomanAvatar from "../../../../components/Icons/WomanAvatar";
import BounceFromBottomContainer from "../../../../components/Animations/BounceFromBottomContainer";

interface EmployeeCardProps {
    index: number;
}

const EmployeeCard:React.FC<EmployeeCardProps> = ({index}) => {
    return (
        <BounceFromBottomContainer index={index}>
        <div className={styles.main_wrapper}>
            <p className={styles.card_title}>Должность</p>
            <div className={styles.content}>
                <div className={styles.avatar_wrapper}> <WomanAvatar /> </div>
                <div className={styles.text_wrapper}>
                    <p className={styles.name}>Olivia Rhye</p>
                    <p className={styles.email}>gempl@mail.com</p>
                </div>
            </div>
        </div>
        </BounceFromBottomContainer>
    );
};

export default EmployeeCard;