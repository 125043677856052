import React from "react";

const EditIcon = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.8123 3.74999L5.26024 12.8021C4.93732 13.1458 4.62482 13.8229 4.56232 14.2917L4.17691 17.6667C4.04149 18.8854 4.91649 19.7187 6.12482 19.5104L9.47899 18.9375C9.94774 18.8542 10.604 18.5104 10.9269 18.1562L19.479 9.10416C20.9582 7.54166 21.6248 5.76041 19.3227 3.58333C17.0311 1.42708 15.2915 2.18749 13.8123 3.74999Z"
      stroke="#5B50A9"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.3857 5.26041C12.8337 8.13541 15.167 10.3333 18.0628 10.625"
      stroke="#5B50A9"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.125 22.9167H21.875"
      stroke="#5B50A9"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default EditIcon;
