import React from "react";
import MoreBorderlessIcon from "../../../../components/Icons/MoreBorderlessIcon";
import styles from "./GoalCard.module.scss";
import BookmarkBorderIcon from "../../../../components/Icons/BookmarkBorderIcon";
import WomanAvatar from "../../../../components/Icons/WomanAvatar";

interface GoalCardProps {
  title: string;
}

const GoalCard: React.FC<GoalCardProps> = ({ title }) => {
  return (
    <div className={styles.main_wrapper}>
      <div className={styles.header}>
        <h3 className={styles.title}>{title}</h3>
        <MoreBorderlessIcon />
      </div>
      <div className={styles.progress_bar}>
        <div className={styles.bar_filled} />
        <div className={styles.bar_empty} />
        <div className={styles.bar_empty} />
      </div>
      <div className={styles.content}>
        <div className={styles.owner_card}>
          <div className={styles.avatar_wrapper}>
            <WomanAvatar />
          </div>
          <div className={styles.owner_info}>
            <p className={styles.name}>Olivia Rhye</p>
            <p className={styles.email}>olivia@untitledui.com</p>
          </div>
        </div>
        <BookmarkBorderIcon />
      </div>
    </div>
  );
};

export default GoalCard;
