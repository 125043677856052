import React from "react";

const BlueGraphicIcon = () => {
  return (
    <svg
      width="180px"
      height="180px"
      viewBox="0 0 227 226"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M194.775 113.139C194.775 125.927 191.733 138.535 185.899 149.929C180.064 161.324 171.602 171.183 161.204 178.7C150.807 186.217 138.769 191.179 126.076 193.179C113.382 195.18 100.392 194.163 88.1694 190.211C75.9465 186.259 64.837 179.484 55.7492 170.441C46.6615 161.398 39.8536 150.344 35.8821 138.181C31.9106 126.018 30.8883 113.093 32.8988 100.461C34.9093 87.8302 39.8955 75.8518 47.4497 65.5055L73.8041 84.5588C69.2716 90.7666 66.2798 97.9536 65.0735 105.532C63.8672 113.111 64.4806 120.866 66.8635 128.164C69.2464 135.462 73.3311 142.095 78.7838 147.52C84.2364 152.946 90.9021 157.011 98.2359 159.382C105.57 161.753 113.363 162.363 120.98 161.163C128.596 159.963 135.818 156.986 142.057 152.476C148.295 147.965 153.373 142.05 156.873 135.213C160.374 128.376 162.199 120.812 162.199 113.139H194.775Z"
        fill="#608CE3"
      />
      <path
        d="M46.3932 66.8356C56.702 52.0647 71.6611 41.1312 88.909 35.761C106.157 30.3908 124.711 30.8899 141.642 37.1795C158.573 43.4691 172.917 55.1909 182.408 70.4945C191.9 85.798 195.999 103.811 194.059 121.691L161.714 118.215C162.878 107.487 160.419 96.6789 154.724 87.4968C149.029 78.3147 140.422 71.2816 130.264 67.5078C120.105 63.734 108.973 63.4346 98.6239 66.6567C88.2752 69.8788 79.2997 76.4389 73.1144 85.3015L46.3932 66.8356Z"
        fill="#9BBBF7"
      />
      <path
        d="M33.1544 127.361C29.6044 107.769 33.4358 87.5622 43.9163 70.6043C54.3967 53.6463 70.7907 41.1271 89.9637 35.4403L99.2568 66.4643C87.753 69.8764 77.9165 77.3879 71.6283 87.5627C65.34 97.7375 63.0412 109.862 65.1712 121.617L33.1544 127.361Z"
        fill="#2E32A7"
      />
    </svg>
  );
};

export default BlueGraphicIcon;
