import React from "react";
import styles from "./PersonalCard.module.scss";
import { Image } from "antd";
import woman_image from "../../../../shared/images/Woman1.png";
import BlankStarIcon from "../../../../components/Icons/BlankStarIcon";
import MessagesIcon from "../../../../components/Icons/MessagesIcon";
import { useNavigate } from "react-router-dom";
import {
  NEW_EMPLOYEES_CANDIDATE,
  PERSONNEL_RESERVE_CANDIDATE,
  VACANCY_CANDIDATE,
} from "../../../../app/providers/RouterConfig";

interface PersonalCardProps {
  status?: "inSearch" | "notInSearch";
  isVacancy?: boolean;
  isNewEmployees?: boolean;
}

const PersonalCard: React.FC<PersonalCardProps> = ({
  status = "inSearch",
  isVacancy,
  isNewEmployees,
}) => {
  const navigate = useNavigate();
  const searchStatus =
    status === "inSearch" ? "Активно ищу работу" : "Не ищу работу";

  let link = PERSONNEL_RESERVE_CANDIDATE;
  if (isVacancy) link = VACANCY_CANDIDATE;
  if (isNewEmployees) link = NEW_EMPLOYEES_CANDIDATE;
  return (
    <div className={styles.main_wrapper} onClick={() => navigate(link)}>
      <div className={styles.card_pfp}>
        <Image
          preview={false}
          width={"118px"}
          height={"102px"}
          src={woman_image}
        />
      </div>
      <div className={styles.card_info_wrapper}>
        <div>
          <h3 className={styles.card_info_title}>Менеджер</h3>
          <h3 className={styles.card_info_title}>Анастасия Иванова</h3>
        </div>
        <div>
          <p className={styles.card_info_content}>
            Пол, возраст, дата рождения
          </p>
          <p className={styles.card_info_content}>Дополнительная информация</p>
        </div>
      </div>
      <div className={styles.card_content_wrapper}>
        <div
          className={`${styles.card_status} ${
            styles[`card_status--${status}`]
          } ${isNewEmployees ? styles.align : ""}`}
        >
          {isNewEmployees ? "Новый сотрудник" : searchStatus}
        </div>
        <p className={styles.card_content}>
          Привет! Меня зовут Анастасия, и я талантливый и организованный
          Менеджер, который поможет Вам управлять любыми проектами и ежедневными
          задачами. Я энергична, ответственна и люблю работать в динамичной
          среде, эта работа точно для меня!
        </p>
      </div>
      <div className={styles.card_icons}>
        <BlankStarIcon />
        <MessagesIcon />
      </div>
    </div>
  );
};

export default PersonalCard;
