import React from "react";
import styles from "./EmployeeCard.module.scss";
import { Image } from "antd";
import first_avatar from "../../../../shared/images/Avatar_first.svg";
import second_avatar from "../../../../shared/images/Avatar_second.svg";
import { useNavigate } from "react-router";
import { EMPLOYEE } from "../../../../app/providers/RouterConfig";

interface EmployeeCardProps {
  isActive?: boolean;
}

const EmployeeCard: React.FC<EmployeeCardProps> = ({ isActive }) => {
  const navigate = useNavigate();
  return isActive ? (
    <div className={styles["card"]} onClick={() => navigate(EMPLOYEE)}>
      <div className={styles["employee-wrapper"]}>
        <div className={styles["eployee-image"]}>
          <Image src={first_avatar} preview={false} />
        </div>
        <div className={styles["employee-info"]}>
          <h3>Olivia Rhye</h3>
          <span>gempl@mail.com</span>
        </div>
      </div>
      <button className={styles["active"]}>Отдел продаж</button>
    </div>
  ) : (
    <div className={styles["card"]}>
      <div className={styles["employee-wrapper"]}>
        <div className={styles["eployee-image"]}>
          <Image src={second_avatar} preview={false} />
        </div>
        <div className={styles["employee-info"]}>
          <h3>Olivia Rhye</h3>
          <span>gempl@mail.com</span>
        </div>
      </div>
      <button>HR отдел</button>
    </div>
  );
};

export default EmployeeCard;
