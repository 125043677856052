import React from "react";
import styles from "./AnalyticsSurveyPageAvatarGroup.module.scss";
import { Image } from "antd";
import a1_avatar from "../../../../shared/images/AvatarInGroup1.png";
import a2_avatar from "../../../../shared/images/AvatarInGroup2.png";
import a3_avatar from "../../../../shared/images/AvatarInGroup3.png";
import a4_avatar from "../../../../shared/images/AvatarInGroup4.png";
import a5_avatar from "../../../../shared/images/AvatarInGroup5.png";

const AnalyticsSurveyPageAvatarGroup = () => {
  return (
    <div className={styles.avatar_container}>
      <div className={styles.avatar}>
        <Image width={30} height={30} src={a1_avatar} preview={false} />
      </div>
      <div className={styles.avatar}>
        <Image width={30} height={30} src={a2_avatar} preview={false} />
      </div>
      <div className={styles.avatar}>
        <Image width={30} height={30} src={a3_avatar} preview={false} />
      </div>
      <div className={styles.avatar}>
        <Image width={30} height={30} src={a4_avatar} preview={false} />
      </div>
      <div className={styles.avatar}>
        <Image width={30} height={30} src={a5_avatar} preview={false} />
      </div>
      <div className={styles.plus_icon}>+5</div>
    </div>
  );
};

export default AnalyticsSurveyPageAvatarGroup;
