import React from 'react';

const SmallPlusIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 10H15" stroke="#E5E1FC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10 15V5" stroke="#E5E1FC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default SmallPlusIcon;