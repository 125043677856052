import React from "react";
import styles from "./CandidatePageContent.module.scss";
import { Image } from "antd";
import woman_picture from "../../../../shared/images/Woman2.png";
import DownloadIcon from "../../../../components/Icons/DownloadIcon";
import BlankStarIcon from "../../../../components/Icons/BlankStarIcon";
import MessagesIcon from "../../../../components/Icons/MessagesIcon";
import CandidatePreviousJob from "../CandidatePreviousJob/CandidatePreviousJob";
import BounceFromBottomContainer from "../../../../components/Animations/BounceFromBottomContainer";

const CandidatePageContent = () => {
  return (
    <div className={styles.main_wrapper}>
      <div className={styles.candidate_info_wrapper}>
        <BounceFromBottomContainer index={0}>
          <div className={styles.candidate_info_image_wrapper}>
            <Image
              width={"262px"}
              height={"226px"}
              preview={false}
              src={woman_picture}
            />
          </div>
        </BounceFromBottomContainer>
        <div className={styles.candidate_info_text}>
          <div className={styles.candidate_info_upper}>
            <BounceFromBottomContainer index={1}>
              <div className={styles.candidate_info_title}>
                <p className={styles.candidate_name}>Имя Фамилия</p>
                <div className={styles.candidate_info_status}>
                  Активно ищу работу
                </div>
              </div>
            </BounceFromBottomContainer>
            <BounceFromBottomContainer index={2}>
              <div className={styles.candidate_info_icons}>
                <DownloadIcon />
                <BlankStarIcon />
                <MessagesIcon />
              </div>
            </BounceFromBottomContainer>
          </div>
          <BounceFromBottomContainer index={3}>
            <p className={styles.candidate_personal_data}>
              Пол, возраст, дата рождения
            </p>
          </BounceFromBottomContainer>
          <BounceFromBottomContainer index={4}>
            <p className={styles.candidate_about_me}>
              Обо мне: Привет! Меня зовут Анастасия, и я талантливый и
              организованный Менеджер, который поможет Вам управлять любыми
              проектами и ежедневными задачами. Я энергична, ответственна и
              люблю работать в динамичной среде, эта работа точно для меня!
            </p>
          </BounceFromBottomContainer>
          <BounceFromBottomContainer index={5}>
            <p className={styles.candidate_position}>HR-менеджер</p>
          </BounceFromBottomContainer>
          <BounceFromBottomContainer index={6}>
            <div className={styles.candidate_professional_data}>
              <p className={styles.candidate_professional_data_text}>
                Специализация
              </p>
              <p className={styles.candidate_professional_data_text}>
                Занятость: полная занятость
              </p>
              <p className={styles.candidate_professional_data_text}>
                График работы: 9:00-18:00
              </p>
            </div>
          </BounceFromBottomContainer>
        </div>
      </div>
      <div className={styles.candidate_experience}>
        <BounceFromBottomContainer index={7}>
          <div className={styles.candidate_experience_title}>
            <h4 className={styles.candidate_experience_title_text}>
              Опыт работы
            </h4>
            <p className={styles.candidate_experience_title_time}>
              4 года 1 месяц
            </p>
          </div>
        </BounceFromBottomContainer>
        <BounceFromBottomContainer index={8}>
          <CandidatePreviousJob
            title={"Яндекс"}
            time={"10 октября 2023 - 10 октября 2024"}
            description={
              "HR-менеджер. Управляла отделом HR и занималась наймом новых сотрудников."
            }
          />
        </BounceFromBottomContainer>
        <BounceFromBottomContainer index={9}>
          <CandidatePreviousJob
            title={"Лукойл"}
            time={"10 октября 2022 - 10 октября 2023"}
            description={
              "HR-менеджер. Управляла отделом HR и занималась наймом новых сотрудников."
            }
          />
        </BounceFromBottomContainer>
        <BounceFromBottomContainer index={10}>
          <CandidatePreviousJob
            title={"Сбербанк"}
            time={"10 октября 2021 - 10 октября 2022"}
            description={
              "HR-менеджер. Управляла отделом HR и занималась наймом новых сотрудников."
            }
          />
        </BounceFromBottomContainer>
        <BounceFromBottomContainer index={11}>
          <CandidatePreviousJob
            title={"Вконтакте"}
            time={"10 октября 2020 - 10 октября 2021"}
            description={
              "HR-менеджер. Управляла отделом HR и занималась наймом новых сотрудников."
            }
          />
        </BounceFromBottomContainer>
      </div>
      <div className={styles.candidate_skills}>
        <BounceFromBottomContainer index={12}>
          <h4 className={styles.candidate_skills_title}>Ключевые навыки</h4>
        </BounceFromBottomContainer>
        <BounceFromBottomContainer index={13}>
          <div className={styles.candidate_skills_list}>
            <div className={styles.candidate_skill}>Навык</div>
            <div className={styles.candidate_skill}>Навык</div>
            <div className={styles.candidate_skill}>Навык</div>
            <div className={styles.candidate_skill}>Навык</div>
            <div className={styles.candidate_skill}>Навык</div>
            <div className={styles.candidate_skill}>Навык</div>
            <div className={styles.candidate_skill}>Навык</div>
          </div>
        </BounceFromBottomContainer>
      </div>
      <div className={styles.candidate_degree}>
        <BounceFromBottomContainer index={14}>
          <h4 className={styles.candidate_degree_title}>Высшее образование</h4>
        </BounceFromBottomContainer>
        <BounceFromBottomContainer index={15}>
          <div className={styles.candidate_degree_description}>
            <div className={styles.candidate_degree_number}>0000</div>
            <div className={styles.candidate_degree_name}>Название</div>
            <div className={styles.candidate_degree_direction}>Напрвление</div>
          </div>
        </BounceFromBottomContainer>
      </div>
      <div className={styles.candidate_degree2}>
        <BounceFromBottomContainer index={16}>
          <h4 className={styles.candidate_degree2_title}>Высшее образование</h4>
        </BounceFromBottomContainer>
        <BounceFromBottomContainer index={17}>
          <div className={styles.candidate_degree2_colleges}>
            <div className={styles.candidate_degree2_college}>Учреждение</div>
            <div className={styles.candidate_degree2_college}>Учреждение</div>
          </div>
        </BounceFromBottomContainer>
      </div>
      <div className={styles.candidate_degree2}>
        <BounceFromBottomContainer index={18}>
          <h4 className={styles.candidate_degree2_title}>
            Гражданство, время в пути до работы
          </h4>
        </BounceFromBottomContainer>
        <BounceFromBottomContainer index={19}>
          <div className={styles.candidate_degree2_colleges}>
            <div className={styles.candidate_degree2_college}>Язык</div>
            <div className={styles.candidate_degree2_college}>Язык</div>
          </div>
        </BounceFromBottomContainer>
      </div>
    </div>
  );
};

export default CandidatePageContent;
