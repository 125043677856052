import React from 'react';

const HatIcon = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.5629 3.16248L5.03789 8.07498C2.62539 9.64998 2.62539 13.175 5.03789 14.75L12.5629 19.6625C13.9129 20.55 16.1379 20.55 17.4879 19.6625L24.9754 14.75C27.3754 13.175 27.3754 9.66248 24.9754 8.08748L17.4879 3.17498C16.1379 2.27498 13.9129 2.27498 12.5629 3.16248Z"
                stroke="#E5E1FC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M7.03789 16.35L7.02539 22.2125C7.02539 23.8 8.25039 25.5 9.75039 26L13.7379 27.325C14.4254 27.55 15.5629 27.55 16.2629 27.325L20.2504 26C21.7504 25.5 22.9754 23.8 22.9754 22.2125V16.4125"
                stroke="#E5E1FC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M26.75 18.75V11.25" stroke="#E5E1FC" strokeWidth="1.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>
    );
};

export default HatIcon;