import React from "react";
import styles from "./RightContent.module.scss";
import WomanAvatar from "../../../../components/Icons/WomanAvatar";
import BounceFromRightContainer from "../../../../components/Animations/BounceFromRightContainer";

const RightContent = () => {
  return (
    <div className={styles.main_wrapper}>
      <BounceFromRightContainer index={0}>
        <div className={styles.avatar_wrapper}>
          <WomanAvatar />
        </div>
      </BounceFromRightContainer>
      <BounceFromRightContainer index={1}>
        <p className={styles.name}>Olivia Rhye</p>
        <p className={styles.email}>gempl@mail.com</p>
      </BounceFromRightContainer>
      <BounceFromRightContainer index={2}>
        <div className={styles.button}>Изменить профиль</div>
      </BounceFromRightContainer>
      <div className={styles.positions}>
        <BounceFromRightContainer index={3}>
          <div className={styles.position}>
            <p className={styles.name_pos}>Младший специалист</p>
            <p className={styles.time}>10 очков</p>
          </div>
        </BounceFromRightContainer>
        <BounceFromRightContainer index={4}>
          <div className={styles.position}>
            <p className={styles.name_pos}>Рекрутер</p>
            <p className={styles.time}>25 очков</p>
          </div>
        </BounceFromRightContainer>
        <BounceFromRightContainer index={5}>
          <div className={styles.position}>
            <p className={styles.name_pos}>Старший рекрутер</p>
            <p className={styles.time}>40 очков</p>
          </div>
        </BounceFromRightContainer>
        <BounceFromRightContainer index={6}>
          <div className={styles.position}>
            <p className={styles.name_pos}>Старший специалист</p>
            <p className={styles.time}>60 очков</p>
          </div>
        </BounceFromRightContainer>
        <BounceFromRightContainer index={7}>
          <div className={styles.position}>
            <p className={styles.name_pos}>HR менеджер</p>
            <p className={styles.time}>80 очков</p>
          </div>
        </BounceFromRightContainer>
        <BounceFromRightContainer index={8}>
          <div className={styles.position}>
            <p className={styles.name_pos}>HR директор</p>
            <p className={styles.time}>100 очков</p>
          </div>
        </BounceFromRightContainer>
      </div>
      <BounceFromRightContainer index={9}>
        <div className={styles.button}>Запросить должность</div>
      </BounceFromRightContainer>
    </div>
  );
};

export default RightContent;
