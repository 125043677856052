import React from "react";
import AnalyticsSurveyPageCard from "../AnalyticsSurveyPageCard/AnalyticsSurveyPageCard";
import styles from "./AnalyticsSurveyPageContent.module.scss";
import AnalyticsSurveyPageCardSub from "../AnalyticsSurveyPageCardSub/AnalyticsSurveyPageCardSub";
import AnalyticsSurveyPageCardWitget from "../AnalyticsSurveyPageCardWitget/AnalyticsSurveyPageCardWitget";
import BounceFromBottomContainer from "../../../../components/Animations/BounceFromBottomContainer";

const AnalyticsSurveyPageContent = ({ isWitget }: any) => {
  return (
    <div>
      <div className={styles.card_box}>
        <BounceFromBottomContainer index={0}>
          <AnalyticsSurveyPageCard />
        </BounceFromBottomContainer>
        {isWitget ? (
          <BounceFromBottomContainer index={1}>
            <AnalyticsSurveyPageCardWitget />
          </BounceFromBottomContainer>
        ) : null}
      </div>

      <BounceFromBottomContainer index={3}>
        <div className={styles.conainer_right}>
          <BounceFromBottomContainer index={4}>
            <AnalyticsSurveyPageCardSub />
          </BounceFromBottomContainer>
          <BounceFromBottomContainer index={5}>
            <AnalyticsSurveyPageCardSub />
          </BounceFromBottomContainer>
          <BounceFromBottomContainer index={6}>
            <AnalyticsSurveyPageCardSub />
          </BounceFromBottomContainer>
          <BounceFromBottomContainer index={7}>
            <AnalyticsSurveyPageCardSub />
          </BounceFromBottomContainer>
          <BounceFromBottomContainer index={8}>
            <AnalyticsSurveyPageCardSub />
          </BounceFromBottomContainer>
          <BounceFromBottomContainer index={9}>
            <AnalyticsSurveyPageCardSub />
          </BounceFromBottomContainer>
          <BounceFromBottomContainer index={10}>
            <AnalyticsSurveyPageCardSub />
          </BounceFromBottomContainer>
          <BounceFromBottomContainer index={11}>
            <AnalyticsSurveyPageCardSub />
          </BounceFromBottomContainer>
          <BounceFromBottomContainer index={12}>
            <AnalyticsSurveyPageCardSub />
          </BounceFromBottomContainer>
          <BounceFromBottomContainer index={13}>
            <AnalyticsSurveyPageCardSub />
          </BounceFromBottomContainer>
          <BounceFromBottomContainer index={14}>
            <AnalyticsSurveyPageCardSub />
          </BounceFromBottomContainer>
          <BounceFromBottomContainer index={15}>
            <AnalyticsSurveyPageCardSub />
          </BounceFromBottomContainer>
          <BounceFromBottomContainer index={16}>
            <AnalyticsSurveyPageCardSub />
          </BounceFromBottomContainer>
        </div>
      </BounceFromBottomContainer>
    </div>
  );
};

export default AnalyticsSurveyPageContent;
