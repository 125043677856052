import React from "react";
import TrashIcon from "../../../../components/Icons/TrashIcon";
import styles from "../../InterviewsPage.module.scss";
import GCard from "../../../../components/GCard/GCard";
import PeopleWithPlusIcon from "../../../../components/Icons/PeopleWithPlusIcon";
import BounceFromBottomContainer from "../../../../components/Animations/BounceFromBottomContainer";
import { useNavigate } from "react-router";
import { CONFERENCE_ROOM } from "../../../../app/providers/RouterConfig";

interface MeetingCardProps {
  index: number;
}

const MeetingCard: React.FC<MeetingCardProps> = ({ index }) => {
  const navigate = useNavigate();
  return (
    <BounceFromBottomContainer index={index}>
      <div
        className={styles["wrapper"]}
        onClick={() => navigate(CONFERENCE_ROOM)}
      >
        <GCard type="light" isFullWidth shape={"squared"}>
          <div className={styles["card_wrapper"]}>
            <div className={styles["column"]}>
              <span className={styles["date"]}>12 августа, 12:00</span>
              <div className={styles["main"]}>
                <span className={styles["header"]}>
                  Собеседование с кандидатом
                </span>
                <p className={styles["paragraph"]}>
                  Краткое описание встречи: собеседование руководителя отдела с
                  потенциальным HR-менеджером.
                </p>
              </div>
              <span className={styles["gray_text"]}>Иван, Пётр, Анастасия</span>
            </div>
            <div className={styles["column"]}>
              <div className={styles["trash_wrapper"]}>
                <TrashIcon />
              </div>
              <PeopleWithPlusIcon />
            </div>
          </div>
        </GCard>
      </div>
    </BounceFromBottomContainer>
  );
};

export default MeetingCard;
