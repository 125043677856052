import React from "react";
import { Image } from "antd";
import ArrowLeftIcon from "../../../../components/Icons/ArrowLeftIcon";
import GSearchBar from "../../../../components/GSearchBar/GSearchBar";
import first_avatar from "../../../../shared/images/Avatar1.png";
import styles from "./TaskHeader.module.scss";
import { useNavigate } from "react-router";
import { ADAPTATION, TEST_TASKS } from "../../../../app/providers/RouterConfig";
import FadeInContainer from "../../../../components/Animations/FadeInContainer";

interface TaskHeaderProps {
  activeTab: "material" | "progress";
  handleTabChange: (tab: "material" | "progress") => void;
  isAdaptation?: boolean;
}

const TaskHeader: React.FC<TaskHeaderProps> = ({
  activeTab,
  handleTabChange,
  isAdaptation,
}) => {
  const navigate = useNavigate();

  return (
    <FadeInContainer>
      <div className={styles["main-wrapper"]}>
        <div className={styles["title-wrapper"]}>
          <div
            style={{ width: "24px", height: "24px" }}
            onClick={() => navigate(isAdaptation ? ADAPTATION : TEST_TASKS)}
          >
            <ArrowLeftIcon />
          </div>
          <h2 className={styles["title"]}>Тестовое задание для менеджеров</h2>
          <div
            onClick={() => handleTabChange("material")}
            className={`${styles.tab_button} ${
              activeTab === "material"
                ? styles["tab_button_active"]
                : styles["tab_button_inactive"]
            }`}
          >
            Материал
          </div>
          <div
            onClick={() => handleTabChange("progress")}
            className={`${styles.tab_button} ${
              activeTab === "progress"
                ? styles["tab_button_active"]
                : styles["tab_button_inactive"]
            }`}
          >
            Прохождение
          </div>
        </div>
        <div className={styles["avatar-wrapper"]}>
          <GSearchBar />
          <Image width={48} height={48} src={first_avatar} preview={false} />
        </div>
      </div>
    </FadeInContainer>
  );
};

export default TaskHeader;
