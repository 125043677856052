import React, { useEffect, useState } from "react";
import GemplLogoIcon from "../Icons/GemplLogoIcon";
import PeopleIcon from "../Icons/PeopleIcon";
import LayersIcon from "../Icons/LayersIcon";
import ColumnsIcon from "../Icons/ColumnsIcon";
import HatIcon from "../Icons/HatIcon";
import BriefcaseIcon from "../Icons/BriefcaseIcon";
import PaperSheetIcon from "../Icons/PaperSheetIcon";
import styles from "./MainMenu.module.scss";
import TaskIcon from "../Icons/TaskIcon";
import MenuArrowRight from "../Icons/MenuArrowRight";
import GemplWithTextLogoIcon from "../Icons/GemplWithTextLogoIcon";
import { useNavigate } from "react-router";
import {
  ADAPTATION,
  ANALYTICS,
  CERTIFICATES,
  CHAT,
  COMPANY_STRUCTURE,
  DEPARTMENT,
  DOCUMENTS_ALL_EMPLOYEES,
  EDUCATION,
  EMPLOYEES,
  INTERVIEWS,
  NEW_EMPLOYEES,
  PERSONNEL_RESERVE,
  TARGET_COMPANY,
  TARGET_GROUP,
  TARGET_PERSON,
  TEST_TASKS,
  VACANCIES,
} from "../../app/providers/RouterConfig";
import ChatIcon from "../Icons/ChatIcon";

const MainMenu = () => {
  const [collapsed, setCollapsed] = useState(true);
  const [currentPage, setCurrentPage] = useState("home");
  const [currentPageFromSubMenu, setCurrentPageFromSubMenu] = useState(0);
  const navigate = useNavigate();

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  useEffect(() => {
    setCurrentPageFromSubMenu(0);
  }, [currentPage]);

  const items = [
    {
      icon: <TaskIcon />,
      title: "Цели и задачи",
      key: "-цели",
      subMenu: [
        { title: "Цели компании", key: TARGET_COMPANY },
        { title: "Общие цели", key: TARGET_GROUP },
        { title: "Мои цели", key: TARGET_PERSON },
      ],
    },
    {
      icon: <PeopleIcon />,
      title: "Рекрутинг",
      key: "-рекрутинг",
      subMenu: [
        { title: "Вакансии", key: VACANCIES },
        { title: "Собеседования", key: INTERVIEWS },
        { title: "Кадровый резерв", key: PERSONNEL_RESERVE },
        { title: "Тестовые задания", key: TEST_TASKS },
      ],
    },
    {
      icon: <LayersIcon />,
      title: "Онбординг",
      key: "-onboarding",
      subMenu: [
        { title: "Адаптация", key: ADAPTATION },
        { title: "Новые сотрудники", key: NEW_EMPLOYEES },
      ],
    },
    {
      icon: <ColumnsIcon />,
      title: "Аналитика",
      key: ANALYTICS,
    },
    {
      icon: <HatIcon />,
      title: "Обучение",
      key: EDUCATION,
    },
    { icon: <ChatIcon />, title: "Чат", key: CHAT },
    {
      icon: <BriefcaseIcon />,
      title: "Компания",
      key: "-company",
      subMenu: [
        { title: "Структура компании", key: COMPANY_STRUCTURE },
        { title: "Все сотрудники", key: EMPLOYEES },
        { title: "Отделы", key: DEPARTMENT },
      ],
    },
    {
      icon: <PaperSheetIcon />,
      title: "Документы",
      key: "-documents",
      subMenu: [
        { title: "Все документы", key: DOCUMENTS_ALL_EMPLOYEES },
        { title: "Справки", key: CERTIFICATES },
      ],
    },
  ];

  const handleSelectItem = (key: string) => {
    // if (key !== "recruitment") setCurrentPageFromSubMenu("");
    setCurrentPage(key);
    if (key.split("-").length < 2) {
      navigate(key);
    }
  };

  const handleSelectFromSubMenu = (key: string, index: number) => {
    setCurrentPageFromSubMenu(index);
    navigate(key);
  };

  return (
    <div>
      <div
        className={
          collapsed
            ? styles.main_menu_wrapper_collapsed
            : styles.main_menu_wrapper
        }
      >
        <div className={styles.logo_wrapper}>
          {collapsed ? (
            <div>
              <div
                className={styles["logo-no-text"]}
                style={{ transition: ".6s", opacity: 1 }}
              >
                <GemplLogoIcon />
              </div>
              <div
                className={styles["text-logo"]}
                style={{ transition: ".6s", opacity: 0 }}
              >
                <GemplWithTextLogoIcon />
              </div>
            </div>
          ) : (
            <div>
              <div
                className={styles["logo-no-text"]}
                style={{ transition: ".6s", opacity: 0 }}
              >
                <GemplLogoIcon />
              </div>
              <div
                style={{ transition: ".6s", opacity: 1 }}
                className={styles["text-logo"]}
              >
                <GemplWithTextLogoIcon />
              </div>
            </div>
          )}
        </div>
        <div className={styles.menu_items}>
          {items.map((item) => {
            return (
              <div key={`${item.key}`} className={styles.menu_item}>
                <div
                  onClick={() => handleSelectItem(item.key)}
                  className={`${styles.item_content} ${
                    item.key === currentPage
                      ? styles["item_content--selected"]
                      : ""
                  }`}
                >
                  <div style={{ height: "30px" }}>{item.icon}</div>
                  <div>{item.title}</div>
                </div>
                {item.subMenu && (
                  <div
                    className={`${styles.subMenu} ${
                      item.key === currentPage && !collapsed
                        ? styles.show
                        : styles.hide
                    }`}
                  >
                    <div
                      className={`${styles.actual_bar} ${
                        styles[`elements-${item.subMenu.length}`]
                      }`}
                    >
                      <div
                        className={` ${
                          styles[`position-${currentPageFromSubMenu}`]
                        } ${styles.actual_bar_thumb} `}
                      ></div>
                    </div>
                    <div className={styles.subMenu_options}>
                      {item.subMenu.map((item, index) => (
                        <div
                          key={item.key}
                          onClick={() => {
                            handleSelectFromSubMenu(item.key, index);
                          }}
                        >
                          {item.title}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
        {collapsed ? (
          <div onClick={toggleCollapsed} className={styles.arrow_button_right}>
            <MenuArrowRight />
          </div>
        ) : (
          <div onClick={toggleCollapsed} className={styles.arrow_button_left}>
            <MenuArrowRight />
          </div>
        )}
      </div>
      <div
        style={{
          width: `${collapsed ? "80px" : "285px"}`,
          transition: ".6s ease",
        }}
      ></div>
    </div>
  );
};

export default MainMenu;
