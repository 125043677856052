import React from "react";
import styles from "./DepartmentPage.module.scss";
import GCard from "../../components/GCard/GCard";
import BounceFromBottomContainer from "../../components/Animations/BounceFromBottomContainer";
import FadeInContainer from "../../components/Animations/FadeInContainer";
import DepartmentHeader from "./components/DepartmentHeader/DepartmentHeader";
import DepartmentCard from "./components/DepartmentCard/DepartmentCard";

const DepartmentPage = () => {
  return (
    <div className={styles["page-wrapper"]}>
      <FadeInContainer>
        <DepartmentHeader />
      </FadeInContainer>
      <div>
        <GCard width={"100%"} type={"light"} shape={"balanced"}>
          <div className={styles["card-wrapper"]}>
            <BounceFromBottomContainer index={0}>
              <DepartmentCard />
            </BounceFromBottomContainer>
            <BounceFromBottomContainer index={1}>
              <DepartmentCard />
            </BounceFromBottomContainer>
            <BounceFromBottomContainer index={2}>
              <DepartmentCard />
            </BounceFromBottomContainer>
            <BounceFromBottomContainer index={3}>
              <DepartmentCard />
            </BounceFromBottomContainer>
            <BounceFromBottomContainer index={4}>
              <DepartmentCard />
            </BounceFromBottomContainer>
            <BounceFromBottomContainer index={5}>
              <DepartmentCard />
            </BounceFromBottomContainer>
            <BounceFromBottomContainer index={6}>
              <DepartmentCard />
            </BounceFromBottomContainer>
            <BounceFromBottomContainer index={7}>
              <DepartmentCard />
            </BounceFromBottomContainer>
            <BounceFromBottomContainer index={8}>
              <DepartmentCard />
            </BounceFromBottomContainer>
            <BounceFromBottomContainer index={9}>
              <DepartmentCard />
            </BounceFromBottomContainer>
          </div>
        </GCard>
      </div>
    </div>
  );
};

export default DepartmentPage;
