import React from 'react';
import styles from './ChatCard.module.scss';
import first_avatar from "../../../../shared/images/Avatar1.png";
import {Image} from "antd";

const ChatCard = () => {
    return (
        <div className={styles.main_wrapper}>
            <Image width={48} height={48} src={first_avatar} preview={false} />
            <div className={styles.text}>
                <p className={styles.name}>Olivia Rhye</p>
                <p className={styles.description}>Lorem Ipsum is simply dummy ...</p>
            </div>
        </div>
    );
};

export default ChatCard;