import React from 'react';

const BriefcaseIcon = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10 27.5H20C25.025 27.5 25.925 25.4875 26.1875 23.0375L27.125 13.0375C27.4625 9.9875 26.5875 7.5 21.25 7.5H8.75003C3.41253 7.5 2.53753 9.9875 2.87503 13.0375L3.81253 23.0375C4.07503 25.4875 4.97503 27.5 10 27.5Z"
                stroke="#E5E1FC" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"
                strokeLinejoin="round"/>
            <path d="M10 7.5V6.5C10 4.2875 10 2.5 14 2.5H16C20 2.5 20 4.2875 20 6.5V7.5" stroke="#E5E1FC"
                  strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M17.5 16.25V17.5C17.5 17.5125 17.5 17.5125 17.5 17.525C17.5 18.8875 17.4875 20 15 20C12.525 20 12.5 18.9 12.5 17.5375V16.25C12.5 15 12.5 15 13.75 15H16.25C17.5 15 17.5 15 17.5 16.25Z"
                stroke="#E5E1FC" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"
                strokeLinejoin="round"/>
            <path d="M27.0625 13.75C24.175 15.85 20.875 17.1 17.5 17.525" stroke="#E5E1FC" strokeWidth="1.5"
                  strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3.27539 14.0875C6.08789 16.0125 9.26289 17.175 12.5004 17.5375" stroke="#E5E1FC"
                  strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default BriefcaseIcon;