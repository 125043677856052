import React from 'react';

const PaperSheetIcon = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M27.5 13.75V21.25C27.5 26.25 26.25 27.5 21.25 27.5H8.75C3.75 27.5 2.5 26.25 2.5 21.25V8.75C2.5 3.75 3.75 2.5 8.75 2.5H10.625C12.5 2.5 12.9125 3.05 13.625 4L15.5 6.5C15.975 7.125 16.25 7.5 17.5 7.5H21.25C26.25 7.5 27.5 8.75 27.5 13.75Z"
                stroke="#E5E1FC" strokeWidth="1.5" strokeMiterlimit="10"/>
            <path d="M10 2.5H21.25C23.75 2.5 25 3.75 25 6.25V7.975" stroke="#E5E1FC" strokeWidth="1.5"
                  strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default PaperSheetIcon;