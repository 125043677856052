import React from "react"

const FavoritesIcon = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g filter="url(#filter0_d_564_27732)">
  <rect x="3" y="2" width="34" height="34" rx="7" stroke="#5B50A9" stroke-width="2"/>
  <path d="M25.8334 26.5L20.0001 22.3333L14.1667 26.5V13.1667C14.1667 12.7246 14.3423 12.3007 14.6549 11.9882C14.9675 11.6756 15.3914 11.5 15.8334 11.5H24.1667C24.6088 11.5 25.0327 11.6756 25.3453 11.9882C25.6578 12.3007 25.8334 12.7246 25.8334 13.1667V26.5Z" stroke="#5B50A9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <defs>
  <filter id="filter0_d_564_27732" x="0" y="0" width="40" height="40" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
  <feOffset dy="1"/>
  <feGaussianBlur stdDeviation="1"/>
  <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"/>
  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_564_27732"/>
  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_564_27732" result="shape"/>
  </filter>
  </defs>
  </svg>
  
)

export default FavoritesIcon
