import React from "react";
import { BrowserRouter } from "react-router-dom";

interface RouterConfigProps {
  children: React.ReactNode;
}

const RouterConfig: React.FC<RouterConfigProps> = ({
  children,
}: RouterConfigProps) => {
  return <BrowserRouter>{children}</BrowserRouter>;
};

/* Главная */
export const PERSONAL_PROFILE = "/profile";

/* Цели и задачи */
export const TARGET_COMPANY = "/targets/company";
export const INDIVIDUAL_COMPANY_TARGET = TARGET_COMPANY + "/target";

export const TARGET_GROUP = "/targets/group";
export const INDIVIDUAL_GROUP_TARGET = TARGET_GROUP + "/target";

export const TARGET_PERSON = "/targets/person";
export const INDIVIDUAL_PERSON_TARGET = TARGET_PERSON + "/target";

/* Рекрутинг */
export const VACANCIES = "/vacancies";
export const CREATE_VACANCY = "/vacancy/create";
export const VACANCY = "/vacancy";
export const VACANCY_CANDIDATE = VACANCY + "/candidate";

export const INTERVIEWS = "/interviews";
export const CALENDAR = INTERVIEWS + "/calendar";

export const TEST_TASKS = "/test/tasks";
export const TEST_TASK = TEST_TASKS + "/task";

export const PERSONNEL_RESERVE = "/personnel-reserve";
export const PERSONNEL_RESERVE_CANDIDATE = PERSONNEL_RESERVE + "/candidate";

/* Онбординг */
export const ADAPTATION = "/adaptation";
export const ADAPTATION_TASK = ADAPTATION + "/task";

export const NEW_EMPLOYEES = "/new-employees";
export const NEW_EMPLOYEES_CANDIDATE = NEW_EMPLOYEES + "/candidate";

/* Аналитика */
export const ANALYTICS = "/analytics";
export const ANALYTICS_SURVEY = "/analytics/survey";
export const ANALYTICS_SURVEY_WITGET = "/analytics/survey/witget";

/* Компания */
export const COMPANY_STRUCTURE = "/company/structure";
export const DEPARTMENT = "/department";
export const DEPARTMENT_STRUCTURE = DEPARTMENT + "/structure";

export const CONFERENCE_ROOM = "/conference/room";

export const EMPLOYEES = "/employees";
export const EMPLOYEE = "/employees/employee";

/* Документы */
export const DOCUMENTS_ALL_EMPLOYEES = "/documents/all-employees";
export const EMPLOYEE_DOCUMENT = "/documents/all-employees/document";
export const CERTIFICATES = "/documents/certificates";
export const EMPLOYEE_CERTIFICATES = CERTIFICATES + "/document";
export const CHAT = "/chat";

export const EDUCATION = "/education";

export default RouterConfig;
