import React from "react";

const BlankStarIcon = () => {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.3016 3.65625L16.135 7.32292C16.385 7.83333 17.0516 8.32292 17.6141 8.41667L20.937 8.96875C23.062 9.32292 23.562 10.8646 22.0308 12.3854L19.4475 14.9688C19.01 15.4063 18.7704 16.25 18.9058 16.8542L19.6454 20.0521C20.2287 22.5833 18.885 23.5625 16.6454 22.2396L13.5308 20.3958C12.9683 20.0625 12.0412 20.0625 11.4683 20.3958L8.35371 22.2396C6.12454 23.5625 4.77037 22.5729 5.35371 20.0521L6.09329 16.8542C6.22871 16.25 5.98912 15.4063 5.55162 14.9688L2.96829 12.3854C1.44746 10.8646 1.93704 9.32292 4.06204 8.96875L7.38496 8.41667C7.93704 8.32292 8.60371 7.83333 8.85371 7.32292L10.687 3.65625C11.687 1.66667 13.312 1.66667 14.3016 3.65625Z"
                stroke="#5B50A9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default BlankStarIcon;