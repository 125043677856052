import React from 'react';
import styles from "./ChatPage.module.scss";
import ChatPageHeader from "./components/ChatPageHeader/ChatPageHeader";
import ChatPageContent from "./components/ChatPageContent/ChatPageContent";

const ChatPage = () => {
    return (
        <div className={styles.main_wrapper}>
            <ChatPageHeader />
            <ChatPageContent />
        </div>
    );
};

export default ChatPage;