import React from "react";
import styles from "./PersonnelReserveContent.module.scss";
import PersonalCard from "../PersonalCard/PersonalCard";
import BounceFromBottomContainer from "../../../../components/Animations/BounceFromBottomContainer";

interface PersonnelReserveContent {
  isNewEmployees?: boolean;
}
const PersonnelReserveContent: React.FC<PersonnelReserveContent> = ({
  isNewEmployees,
}) => {
  return (
    <div className={styles.main_wrapper}>
      <BounceFromBottomContainer index={0}>
        <PersonalCard isNewEmployees={isNewEmployees} />
      </BounceFromBottomContainer>
      <BounceFromBottomContainer index={1}>
        <PersonalCard isNewEmployees={isNewEmployees} />
      </BounceFromBottomContainer>
      <BounceFromBottomContainer index={2}>
        <PersonalCard isNewEmployees={isNewEmployees} />
      </BounceFromBottomContainer>
      <BounceFromBottomContainer index={3}>
        <PersonalCard isNewEmployees={isNewEmployees} />
      </BounceFromBottomContainer>
      <BounceFromBottomContainer index={4}>
        <PersonalCard isNewEmployees={isNewEmployees} />
      </BounceFromBottomContainer>
      <BounceFromBottomContainer index={5}>
        <PersonalCard isNewEmployees={isNewEmployees} />
      </BounceFromBottomContainer>
      <BounceFromBottomContainer index={6}>
        <PersonalCard isNewEmployees={isNewEmployees} />
      </BounceFromBottomContainer>
      <BounceFromBottomContainer index={7}>
        <PersonalCard isNewEmployees={isNewEmployees} />
      </BounceFromBottomContainer>
      <BounceFromBottomContainer index={8}>
        <PersonalCard isNewEmployees={isNewEmployees} />
      </BounceFromBottomContainer>
      <BounceFromBottomContainer index={9}>
        <PersonalCard isNewEmployees={isNewEmployees} />
      </BounceFromBottomContainer>
      <BounceFromBottomContainer index={10}>
        <PersonalCard isNewEmployees={isNewEmployees} />
      </BounceFromBottomContainer>
    </div>
  );
};
export default PersonnelReserveContent;
