import React from 'react';

const StripesIcon = () => {
    return (
        <svg width="21" height="14" viewBox="0 0 21 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.5 5H1.5M19.5 1H1.5M19.5 9H1.5M19.5 13H1.5" stroke="#E5E1FC" strokeWidth="2"
                  strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default StripesIcon;