//export const PRIMARY_COLOR = "#75bdab";
export const PRIMARY_COLOR = "#925ee1";
export const SECONDARY_COLOR = "#E7E7F4";
export const TERTIARY_COLOR = "#BC9FEA";
export const ACCENT_COLOR = "#6021CD";
export const BACKGROUND_COLOR = "#f6f5ff";
export const NEUTRAL_COLOR_FIRST = "#dfddf2";
export const NEUTRAL_COLOR_SECONDARY = "#E0E2E5";
export const NEUTRAL_COLOR_THIRD = "#7F7F7F";
export const ERROR_COLOR = "#DD331D";
export const SUCCESS_COLOR = "#68952D";
export const INFO_COLOR = "#1677FF";
export const BLACK_COLOR = "#000000";
export const WHITE_COLOR = "#ffffff";
