import React from "react";
import TargetCompanyCard from "./components/TargetCompanyCard/TargetCompanyCard";
import styles from "./TargetCompanyPage.module.scss";
import TargetCompanyGraphics from "./components/TargetCompanyGraphics/TargetCompanyGraphics";
import BounceFromBottomContainer from "../../components/Animations/BounceFromBottomContainer";
import BounceFromRightContainer from "../../components/Animations/BounceFromRightContainer";
import GHeader from "../../components/GHeader/GHeader";

const TargetGroupPage = () => {
  return (
    <div className={styles["page-wrapper"]}>
      <GHeader title={"Общие цели"} withMore />
      <div style={{ display: "flex", gap: "2vh" }}>
        <div className={styles["cards-wrap"]}>
          <BounceFromBottomContainer index={0}>
            <div className={styles["card-wrap"]}>
              <TargetCompanyCard
                name={"Olivia Rhye"}
                title={"Участие в корпоративных проектах"}
                email={"olivia@untitledui.com"}
                endDate={"12.08.2024"}
                startDate={"12.08.2023"}
                iconType={"3"}
              />
              <TargetCompanyCard
                name={"Olivia Rhye"}
                title={"Организация рабочего пространства"}
                email={"olivia@untitledui.com"}
                endDate={"12.08.2024"}
                startDate={"12.08.2023"}
                iconType={"3"}
              />
            </div>
          </BounceFromBottomContainer>
          <BounceFromBottomContainer index={1}>
            <div className={styles["card-wrap"]}>
              <TargetCompanyCard
                name={"Olivia Rhye"}
                title={"Повышение физической активности"}
                email={"olivia@untitledui.com"}
                endDate={"12.08.2024"}
                startDate={"12.08.2023"}
                iconType={"3"}
              />
              <TargetCompanyCard
                name={"Olivia Rhye"}
                title={"Корпоративные ценности"}
                email={"olivia@untitledui.com"}
                endDate={"12.08.2024"}
                startDate={"12.08.2023"}
                iconType={"3"}
              />
            </div>
          </BounceFromBottomContainer>
          <BounceFromBottomContainer index={2}>
            <div className={styles["card-wrap"]}>
              <TargetCompanyCard
                name={"Olivia Rhye"}
                title={"Название цели"}
                email={"olivia@untitledui.com"}
                endDate={"12.08.2024"}
                startDate={"12.08.2023"}
                iconType={"3"}
              />
              <TargetCompanyCard
                name={"Olivia Rhye"}
                title={"Название цели"}
                email={"olivia@untitledui.com"}
                endDate={"12.08.2024"}
                startDate={"12.08.2023"}
                iconType={"3"}
              />
            </div>
          </BounceFromBottomContainer>
          <BounceFromBottomContainer index={2}>
            <div className={styles["card-wrap"]}>
              <TargetCompanyCard
                name={"Olivia Rhye"}
                title={"Название цели"}
                email={"olivia@untitledui.com"}
                endDate={"12.08.2024"}
                startDate={"12.08.2023"}
                iconType={"3"}
              />
              <TargetCompanyCard
                name={"Olivia Rhye"}
                title={"Название цели"}
                email={"olivia@untitledui.com"}
                endDate={"12.08.2024"}
                startDate={"12.08.2023"}
                iconType={"3"}
              />
            </div>
          </BounceFromBottomContainer>
        </div>
        <BounceFromRightContainer>
          <div className={styles["graphics-wrap"]}>
            <TargetCompanyGraphics
              title1={"Корпоративные проекты"}
              subtitle1={"•  Межотделочные проекты 40%"}
              subtitle2={"• Новые идеи 30%"}
              subtitle3={" • Участие в проектах 30%"}
              title2={"Организация рабочего пространства"}
              subtitle4={"• Оптимизация рабочего места 30%"}
              subtitle5={"• Еженедельная уборка 30%"}
              subtitle6={"• Установка органайзеров 40%"}
              title3={"Повышение физической активности"}
              subtitle7={"• Зарядка 30%"}
              subtitle8={"• Прогулки в обеденный перерыв 30%"}
              subtitle9={"• Фитнес мероприятия 40%"}
            />
          </div>
        </BounceFromRightContainer>
      </div>
    </div>
  );
};

export default TargetGroupPage;
