import React from "react";
import CreateVacancyMainForm from "./components/CreateVacancyMainForm/CreateVacancyMainForm";

import styles from "./CreateVacancyPage.module.scss";
import CreateVacancyContactsForm from "./components/CreateVacancyContactsForm/CreateVacancyContactsForm";
import CreateVacancyTimeForm from "./components/CreateVacancyTimeForm/CreateVacancyTimeForm";
import GHeader from "../../components/GHeader/GHeader";
import { VACANCIES } from "../../app/providers/RouterConfig";
import BounceFromBottomContainer from "../../components/Animations/BounceFromBottomContainer";

const CreateVacancyPage = () => {
  return (
    <div className={styles["page-wrapper"]}>
      <GHeader title={"Создание вакансии"} withArrow arrowLink={VACANCIES} />
      <div className={styles["forms-wrapper"]}>
        <BounceFromBottomContainer index={1}>
          <CreateVacancyMainForm />
        </BounceFromBottomContainer>
        <div className={styles["right-wrapper"]}>
          <BounceFromBottomContainer index={2}>
            <CreateVacancyContactsForm />{" "}
          </BounceFromBottomContainer>
          <BounceFromBottomContainer index={3}>
            <CreateVacancyTimeForm />{" "}
          </BounceFromBottomContainer>
        </div>
      </div>
    </div>
  );
};

export default CreateVacancyPage;
