import React, { useState } from "react";
import styles from "./InterviewsPage.module.scss";
import CalendarSvg from "../../shared/images/CalendarNotext.svg";

import {
  Button,
  Checkbox,
  DatePicker,
  Image,
  Input,
  Select,
  TimePicker,
} from "antd";
import GCard from "../../components/GCard/GCard";
import MeetingCard from "./components/MeetingCard/MeetingCard";
import BounceFromBottomContainer from "../../components/Animations/BounceFromBottomContainer";
import GHeader from "../../components/GHeader/GHeader";
import { useNavigate } from "react-router";
import { CALENDAR } from "../../app/providers/RouterConfig";

const InterviewsPage = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState(1);
  const onChange = (e: any) => {
    setValue(e.target.value);
  };
  return (
    <div className={styles.PageWrapper}>
      <GHeader title={"Собеседования"} />
      <div className={styles["content_wrapper"]}>
        <div className={styles["cards-wrapper"]}>
          <MeetingCard index={0} />
          <MeetingCard index={1} />
          <MeetingCard index={2} />
          <MeetingCard index={3} />
          <MeetingCard index={4} />
          <MeetingCard index={5} />
        </div>
        <div className={styles["wrapper"]}>
          <div>
            <BounceFromBottomContainer index={0}>
              <span className={styles["calendar_header"]}>
                Понедельник, 05 мая 2024г.
              </span>
            </BounceFromBottomContainer>
            <BounceFromBottomContainer index={1}>
              <div
                className={styles["calendar-image"]}
                onClick={() => navigate(CALENDAR)}
              >
                <Image src={CalendarSvg} preview={false} />
              </div>
            </BounceFromBottomContainer>
          </div>

          <BounceFromBottomContainer index={2}>
            <GCard type="light" isFullWidth shape={"squared"}>
              <div className={styles["form_card"]}>
                <span className={styles["header"]}>Создать комнату</span>
                <div className={styles["form"]}>
                  <div className={styles["input_wrapper"]}>
                    <Input placeholder="Название" />
                    <DatePicker placeholder="00 месяц" />
                    <TimePicker placeholder="00:00" />
                  </div>
                  <div className={styles["second_line"]}>
                    <div className={styles["checkbox-wrapper"]}>
                      <Checkbox
                        checked={value === 1}
                        onChange={() => onChange({ target: { value: 1 } })}
                        className={styles["checkbox"]}
                      >
                        Закрытый доступ
                      </Checkbox>
                      <Checkbox
                        checked={value === 2}
                        onChange={() => onChange({ target: { value: 2 } })}
                        className={styles["checkbox"]}
                      >
                        Открытый доступ
                      </Checkbox>
                    </div>
                    <Input
                      addonBefore={
                        <span className={styles["placeholder"]}>"http://"</span>
                      }
                      placeholder="www.untitledui.com"
                    />
                  </div>
                </div>

                <div className={styles["third_line"]}>
                  <Select
                    placeholder="Select team member"
                    className={styles["select"]}
                    options={[
                      { value: "Member 1", label: "Member 1" },
                      { value: "Member 2", label: "Member 2" },
                      { value: "Member 3", label: "Member 3" },
                    ]}
                  />
                  <Button type="primary">Создать</Button>
                </div>
              </div>
            </GCard>
          </BounceFromBottomContainer>
          <BounceFromBottomContainer index={3}>
            <GCard type="light" isFullWidth shape={"squared"}>
              <div className={styles["settings_wrapper"]}>
                <span className={styles["header"]}>
                  Перед встречей проверите настройки
                </span>
                <Button type="primary">Настройки</Button>
              </div>
            </GCard>
          </BounceFromBottomContainer>
        </div>
      </div>
    </div>
  );
};

export default InterviewsPage;
