import React, { useState } from "react";
import styles from "./CreateVacancyTimeForm.module.scss";
import GCard from "../../../../components/GCard/GCard";
import { Checkbox, DatePicker, TimePicker } from "antd";

const CreateVacancyTimeForm = () => {
  const [value, setValue] = useState(1);
  const onChange = (e: any) => {
    setValue(e.target.value);
  };

  return (
    <GCard>
      <div className={styles["container"]}>
        <label className={styles["label"]} style={{ marginBottom: "4px" }}>
          Время публикации
        </label>
        <div className={styles["checkbox-wrapper"]}>
          <Checkbox
            checked={value === 1}
            onChange={() => onChange({ target: { value: 1 } })}
          >
            Указывать адрес
          </Checkbox>
          <span>Подсказка</span>
          <Checkbox
            checked={value === 2}
            onChange={() => onChange({ target: { value: 2 } })}
            style={{ marginTop: "8px" }}
          >
            Не указывать адрес
          </Checkbox>
          <span>Подсказка</span>
        </div>
        <DatePicker />
        <TimePicker />
      </div>
    </GCard>
  );
};

export default CreateVacancyTimeForm;
