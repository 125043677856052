import { Button } from "antd";
import React from "react";
import styles from "./AnalyticsSurveyPageCardWitget.module.scss";
import PlusWhite from "../../../../components/Icons/PlusWhite";

const AnalyticsSurveyPageCardWitget = () => {
  return (
    <div className={styles.conainer}>
      <span>Добавить виджет</span>
      <Button className={styles.button} icon={<PlusWhite />} type="text" />
    </div>
  );
};

export default AnalyticsSurveyPageCardWitget;
