import React from "react";
import ProgressBarCard from "./ProgressBarCard/ProgressBarCard";
import styles from "./MyTargetPage.module.scss";
import GraphicsCard from "./GraphicsCard/GraphicsCard";
import TimerCard from "./TimerCard/TimerCard";
import BounceFromBottomContainer from "../../components/Animations/BounceFromBottomContainer";
import GHeader from "../../components/GHeader/GHeader";
import {
  TARGET_COMPANY,
  TARGET_GROUP,
  TARGET_PERSON,
} from "../../app/providers/RouterConfig";

interface MyTargetPageProps {
  iconType: "1" | "2" | "3";
}

const MyTargetPage: React.FC<MyTargetPageProps> = ({ iconType }) => {
  const arrowLink = () => {
    switch (iconType) {
      case "1":
        return TARGET_PERSON;
      case "2":
        return TARGET_COMPANY;
      case "3":
        return TARGET_GROUP;
    }
  };
  return (
    <div className={styles["page-wrapper"]}>
      <GHeader title={"Развитие инноваций"} withArrow arrowLink={arrowLink()} />
      <div className={styles["cards-wrap"]}>
        <BounceFromBottomContainer index={0}>
          <ProgressBarCard
            title={"Развитие инноваций"}
            name={"Olivia Rhye"}
            email={"olivia@untitledui.com"}
            startDate={"12.08.2023"}
            endDate={"12.08.2024"}
            iconType={iconType}
          />
        </BounceFromBottomContainer>
        <BounceFromBottomContainer index={1}>
          <GraphicsCard />
        </BounceFromBottomContainer>
        <BounceFromBottomContainer index={5}>
          <TimerCard />
        </BounceFromBottomContainer>
      </div>
    </div>
  );
};

export default MyTargetPage;
