import React from "react";
import styles from "./DocumentsAllEmployeesPage.module.scss";
import DocumentsAllEmployeesContent from "./components/DocumentsAllEmployeesContent/DocumentsAllEmployeesContent";
import GHeader from "../../components/GHeader/GHeader";

const DocumentsAllEmployeesPage = () => {
  return (
    <div className={styles.main_wrapper}>
      <GHeader
        title={"Все документы"}
        buttonText={"Добавить раздел"}
        withMore
      />
      <DocumentsAllEmployeesContent />
    </div>
  );
};

export default DocumentsAllEmployeesPage;
