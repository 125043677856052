import FavoritesIcon from "../../../../components/Icons/FavoritesIcon";
import EllipsisIcon from "../../../../components/Icons/EllipsisIcon";
import EmployeeAimStroke from "../EmployeeAimStroke/EmployeeAimStroke";
import EmployeeAvatar from "../EmployeeAvatar/EmployeeAvatar";
import styles from "./EmployeeAim.module.scss";
import BounceFromBottomContainer from "../../../../components/Animations/BounceFromBottomContainer";

const EmployeeAim = () => {
  return (
    <div className={styles["emplyee-aim"]}>
      <div className={styles["aim-title"]}>
        <h3>Цель сотрудника</h3>
        <EllipsisIcon />
      </div>

      <div className={styles["aim-info"]}>
        <div className={styles["aim-time"]}>
          <span>12.08.2024</span>
        </div>
        <div className={styles["aim-stroke-list"]}>
          <EmployeeAimStroke isActive />
          <EmployeeAimStroke />
          <EmployeeAimStroke />
        </div>
        <div className={styles["aim-peoples-wrapper"]}>
          <EmployeeAvatar />
          <FavoritesIcon />
        </div>
      </div>
    </div>
  );
};

export default EmployeeAim;
