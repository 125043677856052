import React from 'react';

const CompanyStructureVLine = () => {
    return (
        <svg width="386" height="99" viewBox="0 0 386 99" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="0.5" y1="98.1275" x2="0.5" stroke="#6B60B8" strokeOpacity="0.23"/>
            <line x1="385.5" y1="98.1275" x2="385.5" stroke="#6B60B8" strokeOpacity="0.23"/>
        </svg>
    );
};

export default CompanyStructureVLine;