import React from "react";
import styles from "./TaskCard.module.scss";
import TrashIcon from "../../../../components/Icons/TrashIcon";
import EditIcon from "../../../../components/Icons/EditIcon";
import { Image } from "antd";
import codePicture from "../../../../shared/images/CodePicture.png";

const TaskCard = () => {
  return (
    <div className={styles.card_main_wrapper}>
      <div className={styles.card_picture_wrapper}>
        <Image preview={false} src={codePicture} />
      </div>
      <div className={styles.card_text_wrapper}>
        <div className={styles.card_text_title}>
          <div className={styles.card_text_title_text}>
            <h3 className={styles.card_text_title_title}>
              Тестовое задание для менеджеров
            </h3>
            <p className={styles.card_text_title_time}>
              Время прохождения: 40 минут
            </p>
          </div>
          <div className={styles.card_text_title_icons}>
            <div className={styles.tag}>Tag</div>
            <div className={styles.tag}>Tag</div>
            <TrashIcon />
            <EditIcon />
          </div>
        </div>
        <p className={styles.card_text}>
          Для того чтобы оценить ваши навыки и способности, мы предлагаем вам
          выполнить небольшое тестовое задание. Оно включает в себя несколько
          задач, характерных для повседневной работы менеджера в нашей команде.
          Задание поможет нам понять, как вы справляетесь с организацией встреч,
          координацией проектов и взаимодействием с партнерами.
        </p>
      </div>
    </div>
  );
};

export default TaskCard;
