import React from "react";

const CirclePlusIcon = () => {
  return (
    <div>
      <svg
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="1" y="1" width="24" height="24" rx="12" fill="#F6F6FF" />
        <rect
          x="1"
          y="1"
          width="24"
          height="24"
          rx="12"
          stroke="#5B50A9"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="1 3"
        />
        <path
          d="M9 13H17"
          stroke="#5B50A9"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13 17V9"
          stroke="#5B50A9"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default CirclePlusIcon;
