import React from "react";
import styles from "./DocumentCard.module.scss";
import {
  EMPLOYEE_CERTIFICATES,
  EMPLOYEE_DOCUMENT,
} from "../../../../app/providers/RouterConfig";
import { useNavigate } from "react-router-dom";

interface DocumentCardProps {
  isCerts?: boolean;
}

const DocumentCard: React.FC<DocumentCardProps> = ({ isCerts }) => {
  const navigate = useNavigate();
  return (
    <div
      className={styles.main_wrapper}
      onClick={() =>
        navigate(isCerts ? EMPLOYEE_CERTIFICATES : EMPLOYEE_DOCUMENT)
      }
    >
      <h2 className={styles.name}>Папка</h2>
      <p className={styles.description}>
        {isCerts
          ? "Здесь содержатся справки, которые доступны к выдаче"
          : "Здесь содержатся документы, относящиеся к регламенту компании."}
      </p>
      <div className={styles.tag}>{isCerts ? "Справки" : "Регламент"}</div>
    </div>
  );
};

export default DocumentCard;
