import React from "react";
import styles from "../TestTasks/TestTasksPage.module.scss";
import TestTasksModal from "../TestTasks/components/TestTasksModal/TestTasksModal";
import GHeader from "../../components/GHeader/GHeader";
import BounceFromBottomContainer from "../../components/Animations/BounceFromBottomContainer";
import EducationCard from "./EducationCard";

const EducationPage: React.FC = () => {
  return (
    <div className={styles["page-wrapper"]}>
      <GHeader
        title={"Обучение"}
        buttonText={"Создать задание"}
        ModalContent={TestTasksModal}
      />
      <BounceFromBottomContainer index={0}>
        <EducationCard rectType={"2"} />
      </BounceFromBottomContainer>
      <BounceFromBottomContainer index={1}>
        <EducationCard rectType={"1"} />
      </BounceFromBottomContainer>
      <BounceFromBottomContainer index={2}>
        <EducationCard rectType={"3"} />
      </BounceFromBottomContainer>
      <BounceFromBottomContainer index={3}>
        <EducationCard rectType={"1"} />
      </BounceFromBottomContainer>
    </div>
  );
};

export default EducationPage;
