import React from "react";
import styles from "./CompanyStructurePage.module.scss";
import CompanyStructureHeader from "./components/CompanyStructureHeader/CompanyStructureHeader";
import CompanyStructureContent from "./components/CompanyStructureContent/CompanyStructureContent";
import BounceFromBottomContainer from "../../components/Animations/BounceFromBottomContainer";
import GHeader from "../../components/GHeader/GHeader";

const CompanyStructurePage = () => {
  return (
    <div className={styles.page_wrapper}>
      <GHeader title={"Структура компании"} />
      <CompanyStructureContent />
    </div>
  );
};

export default CompanyStructurePage;
