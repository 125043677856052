import React from "react";
import styles from "./CandidatePreviousJob.module.scss";

interface CandidatePreviousJob {
  title: string;
  time: string;
  description: string;
}
const CandidatePreviousJob: React.FC<CandidatePreviousJob> = ({
  title,
  time,
  description,
}) => {
  return (
    <div className={styles.main_wrapper}>
      <div className={styles.job_period}>{time}</div>
      <p className={styles.job_company}>{title}</p>
      <p className={styles.job_position}>{description}</p>
    </div>
  );
};

export default CandidatePreviousJob;
