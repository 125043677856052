import React from 'react';
import styles from './ProfileCard.module.scss';
import WomanAvatar from "../../../../components/Icons/WomanAvatar";

const ProfileCard = () => {
    return (
        <div className={styles.main_wrapper}>
            <div className={styles.avatar_wrapper}>
                <WomanAvatar/>
            </div>
            <div className={styles.owner_info}>
                <p className={styles.name}>Olivia Rhye</p>
                <p className={styles.info}>Lorem Ipsum is simply dummy...</p>
            </div>
        </div>
    );
};

export default ProfileCard;