import React from "react";

const BarIcon = () => {
  return (
    <svg
      width="158"
      height="4"
      viewBox="0 0 158 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="50" height="4" rx="2" fill="#5B50A9" />
      <rect x="52" width="50" height="4" rx="2" fill="#C7BFFF" />
      <rect x="104" width="50" height="4" rx="2" fill="#C7BFFF" />
    </svg>
  );
};

export default BarIcon;
