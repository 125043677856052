import React from "react";
import styles from "../TestTasks/TestTasksPage.module.scss";
import TestTasksModal from "../TestTasks/components/TestTasksModal/TestTasksModal";
import TestTasksCard from "../TestTasks/components/TestTasksCard/TestTasksCard";
import GHeader from "../../components/GHeader/GHeader";
import BounceFromBottomContainer from "../../components/Animations/BounceFromBottomContainer";

const AdaptationPage: React.FC = () => {
  return (
    <div className={styles["page-wrapper"]}>
      <GHeader
        title={"Адаптация"}
        buttonText={"Создать задание"}
        ModalContent={TestTasksModal}
      />
      <BounceFromBottomContainer index={0}>
        <TestTasksCard rectType={"2"} isAdaptation />
      </BounceFromBottomContainer>
      <BounceFromBottomContainer index={1}>
        <TestTasksCard rectType={"1"} isAdaptation />
      </BounceFromBottomContainer>
      <BounceFromBottomContainer index={2}>
        <TestTasksCard rectType={"3"} isAdaptation />
      </BounceFromBottomContainer>
      <BounceFromBottomContainer index={3}>
        <TestTasksCard rectType={"1"} isAdaptation />
      </BounceFromBottomContainer>
    </div>
  );
};

export default AdaptationPage;
