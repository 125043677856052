import React from "react";
import commonStyles from "../../../CreateVacancy/components/VacancyPageHeader/VacancyPageHeader.module.scss";
import GSearchBar from "../../../../components/GSearchBar/GSearchBar";
import first_avatar from "../../../../shared/images/Avatar_first.svg";
import { Image } from "antd";
import styles from "./VacationPageHeader.module.scss";
import BorderlessPlusIcon from "../../../../components/Icons/BorderlessPlusIcon";
import { useState } from "react";
import { Select } from "antd/lib";
import Search from "antd/es/input/Search";
import MoreIcon from "../../../../components/Icons/MoreIcon";
import { useNavigate } from "react-router";
import { CREATE_VACANCY } from "../../../../app/providers/RouterConfig";

const VacationPageHeader = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  function showDiv() {
    setIsOpen((current) => !current);
  }

  return (
    <div className={styles["drop-div-wrapper"]}>
      <div>
        <div className={commonStyles["main-wrapper"]}>
          <div className={commonStyles["title-wrapper"]}>
            <h2 className={commonStyles["title"]}>Вакансии</h2>
            <div className={styles["button-wrapper"]}>
              <button
                className={styles["button"]}
                onClick={() => navigate(CREATE_VACANCY)}
              >
                <BorderlessPlusIcon />
                Создать вакансию
              </button>
              <div className={styles["icon-wrapper"]} onClick={showDiv}>
                <MoreIcon />
              </div>
            </div>
          </div>
          <div className={commonStyles["avatar-wrapper"]}>
            <GSearchBar />
            <div
              style={{ width: "48px", height: "48px", cursor: "pointer" }}
              onClick={() => navigate("/")}
            >
              <Image
                width={48}
                height={48}
                src={first_avatar}
                preview={false}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${styles["hidden-wrapper"]} ${
          isOpen ? styles["open"] : ""
        }`}
      >
        <div className={styles["drop-div"]}>
          <div className={styles["select-div"]}>
            <Select placeholder={"Select"} className={styles["select"]} />
            <Select placeholder={"Select"} className={styles["select"]} />
            <div className={styles["search"]}>
              <Search placeholder={"Searh"} />
            </div>
            <div className={styles["div-button-wrapper"]}>
              <button className={styles["button"]}>
                <span className={styles["button-text"]}> Отменить </span>
              </button>
              <button className={styles["reverse-button"]}>
                <span className={styles["reverse-button-text"]}>Применить</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VacationPageHeader;
