import React from "react";

const TrashIcon = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.875 6.22921C18.4062 5.88546 14.9167 5.70837 11.4375 5.70837C9.375 5.70837 7.3125 5.81254 5.25 6.02087L3.125 6.22921"
      stroke="#5B50A9"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.85449 5.17712L9.08366 3.81254C9.25033 2.82296 9.37533 2.08337 11.1357 2.08337H13.8649C15.6253 2.08337 15.7607 2.86462 15.917 3.82296L16.1462 5.17712"
      stroke="#5B50A9"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.6351 9.52087L18.958 20.0105C18.8434 21.6459 18.7497 22.9167 15.8434 22.9167H9.15592C6.24967 22.9167 6.15592 21.6459 6.04134 20.0105L5.36426 9.52087"
      stroke="#5B50A9"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.7607 17.1875H14.2295"
      stroke="#5B50A9"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.89551 13.0209H15.1038"
      stroke="#5B50A9"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default TrashIcon;
