import React from "react";
import styles from "./GSearchBar.module.scss";
import SearchIcon from "../Icons/SearchIcon";

const GSearchBar = () => {
  return (
    <div className={styles["container"]}>
      <div className={styles["icon-wrapper"]}>
        <SearchIcon />
      </div>
      <input type="text" className={styles["input"]} />{" "}
    </div>
  );
};

export default GSearchBar;
