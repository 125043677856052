import React from "react";
import styles from "./DocumentCard.module.scss";
import DownloadIcon from "../../../../components/Icons/DownloadIcon";
import EditIcon from "../../../../components/Icons/EditIcon";
import TrashIcon from "../../../../components/Icons/TrashIcon";

const DocumentCard: React.FC<{ isCerts: boolean | undefined }> = ({
  isCerts,
}) => {
  return (
    <div className={styles.main_wrapper}>
      <h2 className={styles.name}>
        {isCerts ? "Справки" : "Регламент компании"}
      </h2>
      <p className={styles.description}>
        {isCerts
          ? "Все справки, которые связаны с отпусками"
          : "Новый вариант регламента от 12.08.2023"}
      </p>
      <div className={styles.icons}>
        <div className={styles.tag}>{isCerts ? "Справки" : "Регламент"}</div>
        <DownloadIcon />
        <EditIcon />
        <TrashIcon />
      </div>
    </div>
  );
};

export default DocumentCard;
