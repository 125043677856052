import React from "react";
import GHeader from "../../../components/GHeader/GHeader";
import ConferenceRoomContent from "./ConferenceRoomContent/ConfereneRoomContent";
import styles from "./ConferenceRoomPage.module.scss";
import { CALENDAR } from "../../../app/providers/RouterConfig";

const ConferenceRoomPage = () => {
  return (
    <div className={styles["page-wrapper"]}>
      <GHeader title={"Комната"} withArrow arrowLink={CALENDAR} />
      <ConferenceRoomContent />
    </div>
  );
};

export default ConferenceRoomPage;
