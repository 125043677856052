import styles from "./EmployeeAimStroke.module.scss"

interface EmployeeAimStrokeProps {
  isActive?: boolean
}

const EmployeeAimStroke: React.FC<EmployeeAimStrokeProps> = ({ isActive }) => {
  return isActive ? (
    <div className={styles["emplyee-aim-active"]}></div>
  ) : (
    <div className={styles["emplyee-aim"]}></div>
  )
}

export default EmployeeAimStroke
