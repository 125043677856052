import React from "react";
import styles from "./VacationCard.module.scss";
import TrashIcon from "../../../../components/Icons/TrashIcon";
import EditIcon from "../../../../components/Icons/EditIcon";
import EyeIcon from "../../../../components/Icons/EyeIcon";
import GCard from "../../../../components/GCard/GCard";
import { useNavigate } from "react-router";
import { VACANCY } from "../../../../app/providers/RouterConfig";

const VacationCard = () => {
  const navigate = useNavigate();
  return (
    <div className={styles["card-wrapper"]} onClick={() => navigate(VACANCY)}>
      <GCard isFullWidth type={"light"}>
        <div className={styles["container"]}>
          <span className={styles["label"]}> HR-специалист </span>
          <span className={styles["blob"]}> Опубликованная </span>
          <span className={styles["salary"]}> от 70 000 до 100 000 </span>
          <span className={styles["description"]}>
            В компанию требуется HR-специалист для управления процессами
            подбора, адаптации и развития персонала. Основные обязанности
            включают проведение собеседований, разработку программ обучения,
            оценку эффективности сотрудников и решение вопросов трудового
            законодательства. Ожидается, что кандидат будет активно сотрудничать
            с руководством для обеспечения успешного выполнения HR-стратегий.
          </span>
          <div className={styles["tags-wrapper"]}>
            <span className={styles["tag"]}>HR</span>
            <span className={styles["tag"]}>Рекрутинг</span>
          </div>
          <div className={styles["icons-wrapper"]}>
            <EyeIcon />
            <TrashIcon />
            <EditIcon />
          </div>
        </div>
      </GCard>
    </div>
  );
};

export default VacationCard;
