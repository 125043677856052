import React from "react";
import styles from "../DocumentsAllEmployeesPage/DocumentsAllEmployeesPage.module.scss";
import DocumentsAllEmployeesContent from "../DocumentsAllEmployeesPage/components/DocumentsAllEmployeesContent/DocumentsAllEmployeesContent";
import GHeader from "../../components/GHeader/GHeader";

const CertificatesPage = () => {
  return (
    <div className={styles.main_wrapper}>
      <GHeader title={"Справки"} buttonText={"Запросить справку"} withMore />
      <DocumentsAllEmployeesContent isCerts />
    </div>
  );
};

export default CertificatesPage;
