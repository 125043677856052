import React from "react";
import { ConfigProvider } from "antd";
import {
  PRIMARY_COLOR,
  ACCENT_COLOR,
  SECONDARY_COLOR,
  TERTIARY_COLOR,
  SUCCESS_COLOR,
  INFO_COLOR,
  ERROR_COLOR,
  NEUTRAL_COLOR_FIRST,
  NEUTRAL_COLOR_SECONDARY,
  BLACK_COLOR,
  WHITE_COLOR,
} from "../../shared/constants/colors";
import "dayjs/locale/ru";
import ruRU from "antd/lib/locale/ru_RU";

interface AntConfigProps {
  children: React.ReactNode;
}

const AntConfig: React.FC<AntConfigProps> = ({ children }: AntConfigProps) => {
  return (
    <ConfigProvider
      locale={ruRU}
      theme={{
        token: {
          colorPrimary: PRIMARY_COLOR,
          colorPrimaryActive: ACCENT_COLOR,
          colorPrimaryHover: ACCENT_COLOR,
          colorPrimaryBorderHover: ACCENT_COLOR,
          colorPrimaryBg: SECONDARY_COLOR,
          colorError: ERROR_COLOR,
          colorSuccess: SUCCESS_COLOR,
          colorInfo: INFO_COLOR,
        },
        components: {
          Typography: {
            fontSizeHeading2: 24,
            fontWeightStrong: 700,
          },
          Radio: {
            wireframe: true,
          },
          Input: {
            colorBorder: NEUTRAL_COLOR_FIRST,
            lineWidth: 1,
            borderRadius: 4,
            borderRadiusLG: 6,
            borderRadiusSM: 2,
            colorPrimaryHover: PRIMARY_COLOR,
            fontSize: 16,
            fontSizeLG: 18,
            lineHeight: 1.5714285714285714,
            controlPaddingHorizontal: 12,
            controlPaddingHorizontalSM: 8,
            paddingSM: 8,
            paddingXS: 6,
            controlHeight: 44,
            paddingBlock: 8.8,
            // colorTextPlaceholder: NEUTRAL_COLOR_THIRD,
          },
          Tag: {
            colorPrimary: SECONDARY_COLOR,
            colorBorder: TERTIARY_COLOR,
            borderRadiusSM: 8,
          },
          Modal: {
            borderRadiusLG: 0,
            borderRadiusSM: 0,
            boxShadow: " 0px 6px 12px rgba(0, 0, 0, 0.1)",
            padding: 24,
          },
          Notification: {
            borderRadiusSM: 0,
            borderRadiusLG: 0,
            boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.1)",
          },
          Tabs: {
            lineWidth: 0,
            lineWidthBold: 4,
            paddingLG: 18,
            colorPrimary: PRIMARY_COLOR,
            colorPrimaryActive: PRIMARY_COLOR,
            colorPrimaryHover: PRIMARY_COLOR,
            colorText: BLACK_COLOR,
            inkBarColor: PRIMARY_COLOR,
            itemActiveColor: WHITE_COLOR,
            itemSelectedColor: WHITE_COLOR,
          },
          Carousel: {
            colorBgContainer: PRIMARY_COLOR,
          },
          Button: {
            // colorBgContainer: SECONDARY_COLOR,
            colorBorder: SECONDARY_COLOR,
            borderRadius: 4,
            colorText: PRIMARY_COLOR,
            colorPrimaryBorder: "#f2e6ff",
            colorBgContainerDisabled: "rgba(0, 0, 0, 0.04)",
          },
          Select: {
            controlHeight: 44,
          },
          Pagination: {
            colorBgContainer: PRIMARY_COLOR,
            colorPrimary: "#fff",
            colorText: "rgba(0, 0, 0, 0.88)",
            colorBgTextActive: "#fff",
            colorTextPlaceholder: "rgba(0, 0, 0, 0.25)",
            colorPrimaryHover: "#fff",
            colorPrimaryBorder: PRIMARY_COLOR,
            borderRadius: 8,
            colorBgTextHover: SECONDARY_COLOR,
          },
          Table: {
            colorBorderSecondary: SECONDARY_COLOR,
            borderRadius: 0,
            borderRadiusLG: 0,
            colorTextDescription: "rgba(0, 0, 0, 0.45)",
            controlItemBgActiveHover: SECONDARY_COLOR,
            controlItemBgActive: SECONDARY_COLOR,
            colorBgContainer: "#fff",
            boxShadowSecondary:
              "\n      0 6px 16px 0 rgba(0, 0, 0, 0.08),\n      0 3px 6px -4px rgba(0, 0, 0, 0.12),\n      0 9px 28px 8px rgba(0, 0, 0, 0.05)\n    ",
            colorText: "#000000",
            lineWidth: 1.5,
            colorLinkActive: "#0958d9",
            fontFamily:
              "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,\n'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',\n'Noto Color Emoji'",
          },
          Menu: {
            colorItemBgHover: SECONDARY_COLOR,
            colorItemText: "#414141",
            colorGroupTitle: "#7F7F7F",
            colorItemBgSelected: "rgba(0, 0, 0, 0.00)",
            // "colorActiveBarBorderSize": -8,
            // "colorActiveBarWidth": 4,
          },
          DatePicker: {
            colorPrimaryBorder: SECONDARY_COLOR,
            colorPrimaryHover: ACCENT_COLOR,
            colorSplit: "#fff",
            controlOutline: "rgba(15, 15, 145, 0.1)",
            controlItemBgActive: SECONDARY_COLOR,
            controlItemBgHover: NEUTRAL_COLOR_SECONDARY,
            colorPrimary: PRIMARY_COLOR,
            colorTextLightSolid: "#fff",
            colorTextPlaceholder: NEUTRAL_COLOR_FIRST,
            colorIcon: PRIMARY_COLOR,
            colorIconHover: ACCENT_COLOR,
            lineWidth: 1,
            colorTextHeading: ACCENT_COLOR,
            colorText: "rgba(0, 0, 0, 0.88)",
            controlHeight: 44,
          },
          Popover: {
            colorBgElevated: "#925EE1",
          },
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
};

export default AntConfig;
