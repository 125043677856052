import React from "react";
import styles from "../CompanyStructure/CompanyStructurePage.module.scss";
import CompanyStructureContent from "../CompanyStructure/components/CompanyStructureContent/CompanyStructureContent";
import GHeader from "../../components/GHeader/GHeader";
import { DEPARTMENT } from "../../app/providers/RouterConfig";

const CompanyStructurePage = () => {
  return (
    <div className={styles.page_wrapper}>
      <GHeader title={"Структура компании"} withArrow arrowLink={DEPARTMENT} />
      <CompanyStructureContent />
    </div>
  );
};

export default CompanyStructurePage;
