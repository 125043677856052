import React from 'react';

const PeopleIcon = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M22.4996 8.95C22.4246 8.9375 22.3371 8.9375 22.2621 8.95C20.5371 8.8875 19.1621 7.475 19.1621 5.725C19.1621 3.9375 20.5996 2.5 22.3871 2.5C24.1746 2.5 25.6121 3.95 25.6121 5.725C25.5996 7.475 24.2246 8.8875 22.4996 8.95Z"
                stroke="#E5E1FC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M21.2123 18.05C22.9248 18.3375 24.8123 18.0375 26.1373 17.15C27.8998 15.975 27.8998 14.05 26.1373 12.875C24.7998 11.9875 22.8873 11.6875 21.1748 11.9875"
                stroke="#E5E1FC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M7.46211 8.95C7.53711 8.9375 7.62461 8.9375 7.69961 8.95C9.42461 8.8875 10.7996 7.475 10.7996 5.725C10.7996 3.9375 9.36211 2.5 7.57461 2.5C5.78711 2.5 4.34961 3.95 4.34961 5.725C4.36211 7.475 5.73711 8.8875 7.46211 8.95Z"
                stroke="#E5E1FC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M8.7498 18.05C7.0373 18.3375 5.1498 18.0375 3.8248 17.15C2.0623 15.975 2.0623 14.05 3.8248 12.875C5.1623 11.9875 7.0748 11.6875 8.7873 11.9875"
                stroke="#E5E1FC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M15.0006 18.2875C14.9256 18.275 14.8381 18.275 14.7631 18.2875C13.0381 18.225 11.6631 16.8125 11.6631 15.0625C11.6631 13.275 13.1006 11.8375 14.8881 11.8375C16.6756 11.8375 18.1131 13.2875 18.1131 15.0625C18.1006 16.8125 16.7256 18.2375 15.0006 18.2875Z"
                stroke="#E5E1FC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M11.3629 22.2251C9.60039 23.4001 9.60039 25.3251 11.3629 26.5001C13.3629 27.8376 16.6379 27.8376 18.6379 26.5001C20.4004 25.3251 20.4004 23.4001 18.6379 22.2251C16.6504 20.9001 13.3629 20.9001 11.3629 22.2251Z"
                stroke="#E5E1FC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    );
};

export default PeopleIcon;