import React from "react";
import styles from "../../pages/TestTasks/components/TestTasksCard/TestTasksCard.module.scss";

import { Image } from "antd";
import codeImg from "../../shared/images/codeImage.png";
import { useNavigate } from "react-router";
import firstRect from "../../shared/images/First_rectangle.svg";
import secondRect from "../../shared/images/Second_rectangle.svg";
import { EDUCATION } from "../../app/providers/RouterConfig";
import GCard from "../../components/GCard/GCard";
import EditIcon from "../../components/Icons/EditIcon";
import TrashIcon from "../../components/Icons/TrashIcon";

interface TestTasksCardProps {
  rectType: "1" | "2" | "3";
  isAdaptation?: boolean;
}

const TestTasksCard: React.FC<TestTasksCardProps> = ({
  rectType,
  isAdaptation,
}) => {
  const navigate = useNavigate();

  const currentRect = () => {
    switch (rectType) {
      case "1":
        return codeImg;
      case "2":
        return firstRect;
      case "3":
        return secondRect;
    }
  };

  return (
    <div className={styles["card-wrapper"]} onClick={() => navigate(EDUCATION)}>
      <GCard isFullWidth type={"light"} shape={"twenty"}>
        <div className={styles["container"]}>
          <div className={styles["image-wrapper"]}>
            <Image
              preview={false}
              src={currentRect()}
              className={styles["image"]}
            />
          </div>
          <span className={styles["label"]}>
            Эффективная коммуникация с клиентами
          </span>
          <div className={styles["blob-wrap"]}>
            <span className={styles["blob"]}> Коммуникация </span>
          </div>
          <span className={styles["time"]}> Время прохождения: 90 минут </span>
          <span className={styles["description"]}>
            Данное задание направлено на развитие навыков общения с клиентами.
            Оно поможет вам лучше понимать потребности клиентов, эффективно
            отвечать на их запросы и разрешать возникающие проблемы. Выполнение
            задания позволит улучшить качество обслуживания и повысить
            удовлетворенность клиентов. Мы ожидаем, что вы проявите свои лучшие
            коммуникативные качества и продемонстрируете высокий уровень
            профессионализма.
          </span>
          <div className={styles["tags-wrapper"]}>
            <span className={styles["tag1"]}>Менеджмент</span>
            <span className={styles["tag2"]}>Продажи</span>
          </div>
          <div className={styles["icons-wrapper"]}>
            <EditIcon />
            <TrashIcon />
          </div>
        </div>
      </GCard>
    </div>
  );
};

export default TestTasksCard;
