import React from 'react';
import AntConfig from './AntConfig';
import RouterConfig from './RouterConfig';

interface ProvidersProps {
  children?: React.ReactNode;
}

const Providers: React.FC<ProvidersProps> = ({ children }: ProvidersProps) => {
  return (
      <AntConfig>
        <RouterConfig>{children}</RouterConfig>
      </AntConfig>
  );
};

export default Providers;
