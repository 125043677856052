import styles from "./EmployeePage.module.scss";
import { Image } from "antd";
import profile from "./../../shared/images/Profile.svg";
import EmployeePosition from "./components/EmployeePosition/EmployeePosition";
import EmployeeAim from "./components/EmployeeAim/EmployeeAim";
import EllipsisIcon from "../../components/Icons/EllipsisIcon";
import Analitics from "./../../shared/images/Analitics.svg";
import GHeader from "../../components/GHeader/GHeader";
import { EMPLOYEES } from "../../app/providers/RouterConfig";
import BounceFromBottomContainer from "../../components/Animations/BounceFromBottomContainer";
import BounceFromRightContainer from "../../components/Animations/BounceFromRightContainer";

const EmployeePage = () => {
  return (
    <div className={styles["employee-wrapper"]}>
      <div className={styles["employee-info"]}>
        <GHeader title={"Сотрудник"} withArrow arrowLink={EMPLOYEES} />
        <div className={styles["employee-aim-list"]}>
          <BounceFromBottomContainer index={0}>
            <EmployeeAim />
          </BounceFromBottomContainer>
          <BounceFromBottomContainer index={1}>
            <EmployeeAim />
          </BounceFromBottomContainer>
          <BounceFromBottomContainer index={2}>
            <EmployeeAim />
          </BounceFromBottomContainer>
          <BounceFromBottomContainer index={3}>
            <EmployeeAim />
          </BounceFromBottomContainer>
        </div>
        <BounceFromBottomContainer index={4}>
          <div className={styles["employee-status"]}>
            <div className={styles["employee-status-wrapper"]}>
              <h3>Статус прохождения опроса</h3>
              <button>Прошел</button>
            </div>
            <EllipsisIcon />
          </div>
        </BounceFromBottomContainer>
        <BounceFromBottomContainer index={5}>
          <div className={styles["employee-analitics"]}>
            <div className={styles["employee-analitics-image"]}>
              <BounceFromBottomContainer index={6}>
                <Image
                  width={236}
                  height={236}
                  src={Analitics}
                  preview={false}
                />
              </BounceFromBottomContainer>
            </div>
            <div className={styles["employee-analitics-text"]}>
              <BounceFromBottomContainer index={7}>
                <h3>Аналитика сотрудника</h3>
              </BounceFromBottomContainer>
              <BounceFromBottomContainer index={8}>
                <p>
                  Эта аналитика оценивает производительность сотрудника,
                  качество выполнения задач, взаимодействие с коллегами и
                  клиентами, а также степень участия в командной работе и
                  инициативность.
                </p>
              </BounceFromBottomContainer>
            </div>
          </div>
        </BounceFromBottomContainer>
        <BounceFromBottomContainer index={9}>
          <div className={styles["employee-vacation"]}>
            <h3>Отпуска и больничные</h3>
            <button>Смотреть</button>
          </div>
        </BounceFromBottomContainer>
      </div>
      <div className={styles["employee"]}>
        <BounceFromRightContainer index={0}>
          {" "}
          <div className={styles["employye-image"]}>
            <Image width={150} height={150} src={profile} preview={false} />
          </div>{" "}
        </BounceFromRightContainer>
        <div className={styles["employee-name"]}>
          <BounceFromRightContainer index={1}>
            <h3>Olivia Rhye</h3>
          </BounceFromRightContainer>
          <BounceFromRightContainer index={2}>
            <span>gempl@mail.com</span>
          </BounceFromRightContainer>
        </div>
        <div className={styles["employee-position-list"]}>
          <BounceFromRightContainer index={3}>
            <EmployeePosition />
          </BounceFromRightContainer>
          <BounceFromRightContainer index={4}>
            <EmployeePosition />
          </BounceFromRightContainer>
          <BounceFromRightContainer index={5}>
            <EmployeePosition />
          </BounceFromRightContainer>
          <BounceFromRightContainer index={6}>
            <EmployeePosition />
          </BounceFromRightContainer>
          <BounceFromRightContainer index={7}>
            <EmployeePosition />
          </BounceFromRightContainer>
          <BounceFromRightContainer index={8}>
            <EmployeePosition />
          </BounceFromRightContainer>
        </div>
        <BounceFromRightContainer index={9}>
          <button>Добавить должность</button>
        </BounceFromRightContainer>
      </div>
    </div>
  );
};

export default EmployeePage;
