import React, { useState } from "react";
import styles from "./GHeader.module.scss";
import ArrowLeftIcon from "../Icons/ArrowLeftIcon";
import BorderlessPlusIcon from "../Icons/BorderlessPlusIcon";
import MoreIcon from "../Icons/MoreIcon";
import GSearchBar from "../GSearchBar/GSearchBar";
import { Image, Modal } from "antd";
import first_avatar from "../../shared/images/Avatar_first.svg";
import { Select } from "antd/lib";
import Search from "antd/es/input/Search";
import FadeInContainer from "../Animations/FadeInContainer";
import { useNavigate } from "react-router";

interface GHeaderProps {
  ModalContent?: React.FC<any>;
  modalCustomWidth?: string;
  title: string;
  buttonText?: string;
  withMore?: boolean;
  withArrow?: boolean;
  arrowLink?: string;
}

const GHeader: React.FC<GHeaderProps> = ({
  ModalContent,
  modalCustomWidth,
  title,
  buttonText,
  withMore,
  withArrow,
  arrowLink,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDivOpen, setDivIsOpen] = useState(false);
  const navigate = useNavigate();

  function showDiv() {
    setDivIsOpen((current) => !current);
  }

  const MakeATask = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <FadeInContainer>
      <div className={styles["main-wrapper"]}>
        <div className={styles["title-wrapper"]}>
          {withArrow && arrowLink && (
            <div
              style={{ width: "24px", height: "24px", cursor: "pointer" }}
              onClick={() => navigate(arrowLink)}
            >
              <ArrowLeftIcon />
            </div>
          )}
          <h2 className={styles["title"]}>{title}</h2>
          <div className={styles["icons-wrapper"]}>
            <div className={styles["button-wrapper"]}>
              {buttonText && (
                <button className={styles["button"]} onClick={MakeATask}>
                  <BorderlessPlusIcon />
                  {buttonText}
                </button>
              )}
              {withMore && (
                <div className={styles["icon-wrapper"]} onClick={showDiv}>
                  <MoreIcon />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles["avatar-wrapper"]}>
          <GSearchBar />
          <div
            style={{ width: "48px", height: "48px", cursor: "pointer" }}
            onClick={() => navigate("/")}
          >
            <Image width={48} height={48} src={first_avatar} preview={false} />
          </div>
        </div>
      </div>
      <div
        className={`${styles["hidden-wrapper"]} ${
          isDivOpen ? styles["open"] : ""
        }`}
      >
        <div className={styles["drop-div"]}>
          <div className={styles["select-div"]}>
            <Select placeholder={"Select"} className={styles["select"]} />
            <Select placeholder={"Select"} className={styles["select"]} />
            <div className={styles["search"]}>
              <Search placeholder={"Searh"} />
            </div>
            <div className={styles["div-button-wrapper"]}>
              <button className={styles["button"]}>
                <span className={styles["button-text"]}> Отменить </span>
              </button>
              <button className={styles["reverse-button"]}>
                <span className={styles["reverse-button-text"]}>Применить</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      {ModalContent && (
        <Modal
          open={isModalOpen}
          width={`${modalCustomWidth ?? "716px"}`}
          onCancel={handleCancel}
          centered={true}
        >
          <ModalContent />
        </Modal>
      )}
    </FadeInContainer>
  );
};

export default GHeader;
