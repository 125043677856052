import React from "react";
import TargetCompanyCard from "./components/TargetCompanyCard/TargetCompanyCard";
import styles from "./TargetCompanyPage.module.scss";
import TargetCompanyGraphics from "./components/TargetCompanyGraphics/TargetCompanyGraphics";
import BounceFromBottomContainer from "../../components/Animations/BounceFromBottomContainer";
import BounceFromRightContainer from "../../components/Animations/BounceFromRightContainer";
import GHeader from "../../components/GHeader/GHeader";

const TargetCompanyPage = () => {
  return (
    <div className={styles["page-wrapper"]}>
      <GHeader title={"Цели компании"} withMore />
      <div style={{ display: "flex", gap: "2vh" }}>
        <div className={styles["cards-wrap"]}>
          <BounceFromBottomContainer index={0}>
            <div className={styles["card-wrap"]}>
              <TargetCompanyCard
                name={"Olivia Rhye"}
                title={"Повышение доходов компании"}
                email={"olivia@untitledui.com"}
                endDate={"12.08.2024"}
                startDate={"12.08.2023"}
                iconType={"2"}
              />
              <TargetCompanyCard
                name={"Olivia Rhye"}
                title={"Улучшение качества продукции"}
                email={"olivia@untitledui.com"}
                endDate={"12.08.2024"}
                startDate={"12.08.2023"}
                iconType={"2"}
              />
            </div>
          </BounceFromBottomContainer>
          <BounceFromBottomContainer index={1}>
            <div className={styles["card-wrap"]}>
              <TargetCompanyCard
                name={"Olivia Rhye"}
                title={"Развитие инноваций"}
                email={"olivia@untitledui.com"}
                endDate={"12.08.2024"}
                startDate={"12.08.2023"}
                iconType={"2"}
              />
              <TargetCompanyCard
                name={"Olivia Rhye"}
                title={"Расширение рынка"}
                email={"olivia@untitledui.com"}
                endDate={"12.08.2024"}
                startDate={"12.08.2023"}
                iconType={"2"}
              />
            </div>
          </BounceFromBottomContainer>
          <BounceFromBottomContainer index={2}>
            <div className={styles["card-wrap"]}>
              <TargetCompanyCard
                name={"Olivia Rhye"}
                title={"Оптимизация бизнес процессов"}
                email={"olivia@untitledui.com"}
                endDate={"12.08.2024"}
                startDate={"12.08.2023"}
                iconType={"2"}
              />
              <TargetCompanyCard
                name={"Olivia Rhye"}
                title={"Развитие корпоративной культуры"}
                email={"olivia@untitledui.com"}
                endDate={"12.08.2024"}
                startDate={"12.08.2023"}
                iconType={"2"}
              />
            </div>
          </BounceFromBottomContainer>
          <BounceFromBottomContainer index={2}>
            <div className={styles["card-wrap"]}>
              <TargetCompanyCard
                name={"Olivia Rhye"}
                title={"Устойчивое развитие"}
                email={"olivia@untitledui.com"}
                endDate={"12.08.2024"}
                startDate={"12.08.2023"}
                iconType={"2"}
              />
              <TargetCompanyCard
                name={"Olivia Rhye"}
                title={"Укрепление бренда"}
                email={"olivia@untitledui.com"}
                endDate={"12.08.2024"}
                startDate={"12.08.2023"}
                iconType={"2"}
              />
            </div>
          </BounceFromBottomContainer>
        </div>
        <BounceFromRightContainer>
          <div className={styles["graphics-wrap"]}>
            <TargetCompanyGraphics
              title1={"Повышение доходов"}
              subtitle1={"• Анализ текущих доходов 40%"}
              subtitle2={"• Оптимизация ценовой стратегии 30%"}
              subtitle3={" • Расширение ассортимента 30%"}
              title2={"Улучшение качества продукции"}
              subtitle4={"• Внедрение систем управления 30%"}
              subtitle5={"• Уровень удовлетворённости клиентов 30%"}
              subtitle6={"• Обучение и развитие персонала 40%"}
              title3={"Оптимизация бизнес-процессов"}
              subtitle7={"• Анализ текущих процессов 30%"}
              subtitle8={"• Внедрение автоматизации 30%"}
              subtitle9={"• Улучшение процессов 40%"}
            />
          </div>
        </BounceFromRightContainer>
      </div>
    </div>
  );
};

export default TargetCompanyPage;
