import React from "react";
import MainMenu from "../MainMenu/MainMenu";
import styles from "./NavigationWrapper.module.scss";

interface NavigationWrapperProps {
  children: React.ReactNode;
}

const NavigationWrapper: React.FC<NavigationWrapperProps> = ({ children }) => {
  return (
    <div className={styles["navigation-wrapper"]}>
      <MainMenu />
      {children}
    </div>
  );
};

export default NavigationWrapper;
