import React from 'react';

const CalendarIcon = () => {
    return (
        <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.66669 1.66667V4.16667" stroke="#FDE4FF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12.3333 1.66667V4.16667" stroke="#FDE4FF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M1.91669 7.575H16.0834" stroke="#FDE4FF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M16.5 7.08334V14.1667C16.5 16.6667 15.25 18.3333 12.3333 18.3333H5.66667C2.75 18.3333 1.5 16.6667 1.5 14.1667V7.08334C1.5 4.58334 2.75 2.91667 5.66667 2.91667H12.3333C15.25 2.91667 16.5 4.58334 16.5 7.08334Z" stroke="#FDE4FF" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.0789 11.4167H12.0864" stroke="#FDE4FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12.0789 13.9167H12.0864" stroke="#FDE4FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.99626 11.4167H9.00374" stroke="#FDE4FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.99626 13.9167H9.00374" stroke="#FDE4FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5.91191 11.4167H5.91939" stroke="#FDE4FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5.91191 13.9167H5.91939" stroke="#FDE4FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    );
};

export default CalendarIcon;