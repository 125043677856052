import React from "react";

const EyeIcon = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.2288 12.4999C16.2288 14.5624 14.5622 16.2291 12.4997 16.2291C10.4372 16.2291 8.77051 14.5624 8.77051 12.4999C8.77051 10.4374 10.4372 8.77075 12.4997 8.77075C14.5622 8.77075 16.2288 10.4374 16.2288 12.4999Z"
        stroke="#5B50A9"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5003 21.1146C16.1774 21.1146 19.6045 18.9479 21.9899 15.1979C22.9274 13.7292 22.9274 11.2604 21.9899 9.79167C19.6045 6.04167 16.1774 3.875 12.5003 3.875C8.82324 3.875 5.39616 6.04167 3.01074 9.79167C2.07324 11.2604 2.07324 13.7292 3.01074 15.1979C5.39616 18.9479 8.82324 21.1146 12.5003 21.1146Z"
        stroke="#5B50A9"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EyeIcon;
