import React from "react";
import styles from "./CreateVacancyContactsForm.module.scss";
import GCard from "../../../../components/GCard/GCard";
import { Input } from "antd";

const CreateVacancyContactsForm = () => {
  return (
    <GCard>
      <div className={styles["container"]}>
        <div>
          <label className={styles["label"]} style={{ marginBottom: "4px" }}>
            Компания
          </label>
          <Input placeholder="Название" />
        </div>
        <div>
          <label className={styles["label"]} style={{ marginBottom: "4px" }}>
            Контакты
          </label>
          <Input placeholder="Название" />
          <Input placeholder="Название" />
        </div>
      </div>
    </GCard>
  );
};

export default CreateVacancyContactsForm;
