import React from "react";
import { Route, Routes } from "react-router";
import {
  TARGET_COMPANY,
  TARGET_GROUP,
  TARGET_PERSON,
  PERSONNEL_RESERVE,
  CREATE_VACANCY,
  VACANCY,
  VACANCIES,
  VACANCY_CANDIDATE,
  TEST_TASKS,
  INTERVIEWS,
  TEST_TASK,
  ANALYTICS,
  ANALYTICS_SURVEY,
  ANALYTICS_SURVEY_WITGET,
  EMPLOYEES,
  DEPARTMENT,
  COMPANY_STRUCTURE,
  CALENDAR,
  EMPLOYEE,
  DOCUMENTS_ALL_EMPLOYEES,
  EMPLOYEE_DOCUMENT,
  PERSONAL_PROFILE,
  INDIVIDUAL_COMPANY_TARGET,
  INDIVIDUAL_PERSON_TARGET,
  INDIVIDUAL_GROUP_TARGET,
  ADAPTATION,
  ADAPTATION_TASK,
  PERSONNEL_RESERVE_CANDIDATE,
  NEW_EMPLOYEES,
  DEPARTMENT_STRUCTURE,
  NEW_EMPLOYEES_CANDIDATE,
  CERTIFICATES,
  EMPLOYEE_CERTIFICATES,
  CONFERENCE_ROOM,
  CHAT,
  EDUCATION,
} from "../app/providers/RouterConfig";

import InterviewsPage from "./InterviewsPage/InterviewsPage";
import VacationPage from "./VacationPage/VacationPage";
import TestTasksPage from "./TestTasks/TestTasksPage";
import CreateVacancyPage from "./CreateVacancy/CreateVacancyPage";
import PersonnelReservePage from "./PersonnelReserve/PersonnelReservePage";
import CandidatePage from "./Candidate/CandidatePage";
import VacationsPage from "./VacationsPage/VacationsPage";
import NavigationWrapper from "../components/NavigationWrapper/NavigationWrapper";
import TestTaskPage from "./TestTask/TestTaskPage";
import AnalyticsPage from "./Analytics/AnalyticsPage";
import AnalyticsSurveyPage from "./AnalyticsSurvey/AnalyticsSurveyPage";
import EmployeesPage from "./Employees/EmployeesPage";
import TargetCompanyPage from "./TargetCompany/TargetCompanyPage";
import MyTargetPage from "./MyTarget/MyTargetPage";
import CompanyStructurePage from "./CompanyStructure/CompanyStructurePage";
import DepartmentPage from "./Department/DepartmentPage";
import ChatPage from "./Chat/ChatPage";
import DocumentsAllEmployeesPage from "./DocumentsAllEmployeesPage/DocumentsAllEmployeesPage";
import EmployeeDocumentPage from "./EmployeeDocument/EmployeeDocumentPage";
import CalendarPage from "./CalendarPage/CalendarPage";
import EmployeePage from "./Employee/EmployeePage";
import TargetGroupPage from "./TargetCompany/TargetGroupPage";
import TargetPersonPage from "./TargetCompany/TargetPersonPage";
import ConferenceRoomPage from "./ConferenceRoom/components/ConferenceRoomPage";
import AdaptationPage from "./AdaptationPage/AdaptationPage";
import NewEmployeesPage from "./NewEmployees/NewEmployeesPage";
import PersonalProfilePage from "./PersonalProfilePage/PersonalProfilePage";
import DepartmentSchemePage from "./DepartmentScheme/DepartmentSchemePage";
import CertificatesPage from "./CertificatesPage/CertificatesPage";
import EducationPage from "./EducationPage/EducationPage";

export const Routing = () => {
  return (
    <Routes>
      <Route
        path={"/"}
        element={
          <NavigationWrapper>
            <PersonalProfilePage />
          </NavigationWrapper>
        }
      />
      <Route
        path={TARGET_COMPANY}
        element={
          <NavigationWrapper>
            <TargetCompanyPage />
          </NavigationWrapper>
        }
      />
      <Route
        path={TARGET_GROUP}
        element={
          <NavigationWrapper>
            <TargetGroupPage />
          </NavigationWrapper>
        }
      />
      <Route
        path={TARGET_PERSON}
        element={
          <NavigationWrapper>
            <TargetPersonPage />
          </NavigationWrapper>
        }
      />
      <Route
        path={INDIVIDUAL_COMPANY_TARGET}
        element={
          <NavigationWrapper>
            <MyTargetPage iconType={"2"} />
          </NavigationWrapper>
        }
      />
      <Route
        path={INDIVIDUAL_PERSON_TARGET}
        element={
          <NavigationWrapper>
            <MyTargetPage iconType={"1"} />
          </NavigationWrapper>
        }
      />
      <Route
        path={INDIVIDUAL_GROUP_TARGET}
        element={
          <NavigationWrapper>
            <MyTargetPage iconType={"3"} />
          </NavigationWrapper>
        }
      />
      <Route
        path={TEST_TASKS}
        element={
          <NavigationWrapper>
            <TestTasksPage />
          </NavigationWrapper>
        }
      />
      <Route
        path={ADAPTATION}
        element={
          <NavigationWrapper>
            <AdaptationPage />
          </NavigationWrapper>
        }
      />
      <Route
        path={EDUCATION}
        element={
          <NavigationWrapper>
            <EducationPage />
          </NavigationWrapper>
        }
      />
      <Route
        path={ANALYTICS}
        element={
          <NavigationWrapper>
            <AnalyticsPage />
          </NavigationWrapper>
        }
      />
      <Route
        path={ANALYTICS_SURVEY}
        element={
          <NavigationWrapper>
            <AnalyticsSurveyPage />
          </NavigationWrapper>
        }
      />
      <Route
        path={ANALYTICS_SURVEY_WITGET}
        element={
          <NavigationWrapper>
            <AnalyticsSurveyPage isWitget={true} />
          </NavigationWrapper>
        }
      />
      <Route
        path={CREATE_VACANCY}
        element={
          <NavigationWrapper>
            <CreateVacancyPage />
          </NavigationWrapper>
        }
      />
      <Route
        path={PERSONNEL_RESERVE}
        element={
          <NavigationWrapper>
            <PersonnelReservePage />
          </NavigationWrapper>
        }
      />
      <Route
        path={VACANCY_CANDIDATE}
        element={
          <NavigationWrapper>
            <CandidatePage fromVacancy />
          </NavigationWrapper>
        }
      />
      <Route
        path={PERSONNEL_RESERVE_CANDIDATE}
        element={
          <NavigationWrapper>
            <CandidatePage />
          </NavigationWrapper>
        }
      />
      <Route
        path={NEW_EMPLOYEES}
        element={
          <NavigationWrapper>
            <NewEmployeesPage />
          </NavigationWrapper>
        }
      />
      <Route
        path={NEW_EMPLOYEES_CANDIDATE}
        element={
          <NavigationWrapper>
            <CandidatePage fromNewEmployees />
          </NavigationWrapper>
        }
      />
      <Route
        path={VACANCIES}
        element={
          <NavigationWrapper>
            <VacationsPage />
          </NavigationWrapper>
        }
      />
      <Route
        path={VACANCY}
        element={
          <NavigationWrapper>
            <VacationPage />
          </NavigationWrapper>
        }
      />
      <Route
        path={TEST_TASK}
        element={
          <NavigationWrapper>
            <TestTaskPage />
          </NavigationWrapper>
        }
      />
      <Route
        path={ADAPTATION_TASK}
        element={
          <NavigationWrapper>
            <TestTaskPage isAdaptation />
          </NavigationWrapper>
        }
      />
      <Route
        path={INTERVIEWS}
        element={
          <NavigationWrapper>
            <InterviewsPage />
          </NavigationWrapper>
        }
      />
      <Route
        path={EMPLOYEES}
        element={
          <NavigationWrapper>
            <EmployeesPage />
          </NavigationWrapper>
        }
      />
      <Route
        path={CALENDAR}
        element={
          <NavigationWrapper>
            <CalendarPage />
          </NavigationWrapper>
        }
      />
      <Route
        path={DEPARTMENT}
        element={
          <NavigationWrapper>
            <DepartmentPage />
          </NavigationWrapper>
        }
      />
      <Route
        path={COMPANY_STRUCTURE}
        element={
          <NavigationWrapper>
            <CompanyStructurePage />
          </NavigationWrapper>
        }
      />
      <Route
        path={EMPLOYEE}
        element={
          <NavigationWrapper>
            <EmployeePage />
          </NavigationWrapper>
        }
      />
      <Route
        path={DOCUMENTS_ALL_EMPLOYEES}
        element={
          <NavigationWrapper>
            <DocumentsAllEmployeesPage />
          </NavigationWrapper>
        }
      />
      <Route
        path={EMPLOYEE_DOCUMENT}
        element={
          <NavigationWrapper>
            <EmployeeDocumentPage />
          </NavigationWrapper>
        }
      />
      <Route
        path={EMPLOYEE_CERTIFICATES}
        element={
          <NavigationWrapper>
            <EmployeeDocumentPage isCerts />
          </NavigationWrapper>
        }
      />
      <Route
        path={COMPANY_STRUCTURE}
        element={
          <NavigationWrapper>
            <CompanyStructurePage />
          </NavigationWrapper>
        }
      />
      <Route
        path={DEPARTMENT_STRUCTURE}
        element={
          <NavigationWrapper>
            <DepartmentSchemePage />
          </NavigationWrapper>
        }
      />
      <Route
        path={CERTIFICATES}
        element={
          <NavigationWrapper>
            <CertificatesPage />
          </NavigationWrapper>
        }
      />
      <Route
        path={CONFERENCE_ROOM}
        element={
          <NavigationWrapper>
            <ConferenceRoomPage />
          </NavigationWrapper>
        }
      />
      <Route
        path={CHAT}
        element={
          <NavigationWrapper>
            <ChatPage />
          </NavigationWrapper>
        }
      />
    </Routes>
  );
};
