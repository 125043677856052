import React from 'react';
import styles from './ChatPageHeader.module.scss';
import GSearchBar from "../../../../components/GSearchBar/GSearchBar";
import {Image} from "antd";
import first_avatar from "../../../../shared/images/Avatar1.png";

const ChatPageHeader = () => {
    return (
        <div className={styles.main_wrapper}>
            <div className={styles.title_wrapper}>
                <h2 className={styles.title}>Чат</h2>
            </div>
            <div className={styles.avatar_wrapper}>
                <GSearchBar />
                <Image width={48} height={48} src={first_avatar} preview={false} />
            </div>
        </div>
    );
};

export default ChatPageHeader;