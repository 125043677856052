import React from "react";
import styles from "./CandidatePage.module.scss";
import CandidatePageContent from "./components/CandidatePageContent/CandidatePageContent";
import GHeader from "../../components/GHeader/GHeader";
import {
  NEW_EMPLOYEES,
  PERSONNEL_RESERVE,
  VACANCY,
} from "../../app/providers/RouterConfig";

interface CandidatePageProps {
  fromVacancy?: boolean;
  fromNewEmployees?: boolean;
}
const CandidatePage: React.FC<CandidatePageProps> = ({
  fromVacancy,
  fromNewEmployees,
}) => {
  let link = fromVacancy ? VACANCY : PERSONNEL_RESERVE;
  if (!fromVacancy) link = fromNewEmployees ? NEW_EMPLOYEES : PERSONNEL_RESERVE;
  return (
    <div className={styles.page_wrapper}>
      <GHeader title={"Анастасия Иванова"} withArrow arrowLink={link} />
      <CandidatePageContent />
    </div>
  );
};

export default CandidatePage;
