import React from 'react';

const CompanyStructureLines = () => {
    return (
        <svg width="1120" height="100" viewBox="0 0 1120 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="1119.5" y1="99.1335" x2="1119.5" y2="33" stroke="#6B60B8" strokeOpacity="0.23"/>
            <line x1="1" y1="99.0637" x2="1" y2="0.936249" stroke="#6B60B8" strokeOpacity="0.23"/>
            <line x1="392.742" y1="99.1326" x2="392.742" y2="32.9991" stroke="#6B60B8" strokeOpacity="0.23"/>
            <line x1="0.999634" y1="32.631" x2="1120" y2="32.631" stroke="#6B60B8" strokeOpacity="0.23"/>
            <line x1="773.742" y1="99.1335" x2="773.742" y2="33" stroke="#6B60B8" strokeOpacity="0.23"/>
        </svg>
    );
};

export default CompanyStructureLines;