import React from "react";
import styles from "./DepartmentCard.module.scss";
import { Image } from "antd";
import AvatarGroup from "../../../../shared/images/AvatarGroup.png";
import { useNavigate } from "react-router";
import { DEPARTMENT_STRUCTURE } from "../../../../app/providers/RouterConfig";

const DepartmentCard: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div
      className={styles["card"]}
      onClick={() => navigate(DEPARTMENT_STRUCTURE)}
    >
      <div className={styles["department-wrapper"]}>
        <div className={styles["department-info"]}>
          <h2>Название подразделения</h2>
        </div>

        <div className={styles["department-info"]}>
          <span>Отдел занимается оптимизацией бизнес-процессов</span>
          <div>
            <Image src={AvatarGroup} preview={false} />
          </div>
        </div>
        <button>Тэг отдела</button>
      </div>
    </div>
  );
};

export default DepartmentCard;
