import React from "react";
import styles from "./CalendarTable.module.scss";
import ThreeIconsLightBlue from "../../../../components/Icons/ThreeIconsLightBlue";
import ThreeIconsOrange from "../../../../components/Icons/ThreeIconsOrange";
import ThreeIconsPink from "../../../../components/Icons/ThreeIconsPink";
import ThreeIconsBlue from "../../../../components/Icons/ThreeIconsBlue";
import BounceFromBottomContainer from "../../../../components/Animations/BounceFromBottomContainer";
import { useNavigate } from "react-router";
import { CONFERENCE_ROOM } from "../../../../app/providers/RouterConfig";

const CalendarTable = () => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate(CONFERENCE_ROOM)}
      className={styles["card-wrap"]}
    >
      <BounceFromBottomContainer index={2}>
        <h3>05 мая 2024 г.</h3>
      </BounceFromBottomContainer>
      <BounceFromBottomContainer index={3}>
        <div className={styles["light-blue-div"]}>
          <div className={styles["span-wrap"]}>
            <span>00:00</span>
          </div>
          <span>Name</span>
          <ThreeIconsLightBlue />
        </div>
      </BounceFromBottomContainer>
      <BounceFromBottomContainer index={4}>
        <div className={styles["orange-div"]}>
          <div className={styles["span-wrap"]}>
            <span>00:00</span>
          </div>
          <span>Name</span>
          <ThreeIconsOrange />
        </div>
      </BounceFromBottomContainer>
      <BounceFromBottomContainer index={5}>
        <div className={styles["pink-div"]}>
          <div className={styles["span-wrap"]}>
            <span>00:00</span>
          </div>
          <span>Name</span>
          <ThreeIconsPink />
        </div>
      </BounceFromBottomContainer>
      <BounceFromBottomContainer index={6}>
        <div className={styles["orange-div"]}>
          <div className={styles["span-wrap"]}>
            <span>00:00</span>
          </div>
          <span>Name</span>
          <ThreeIconsOrange />
        </div>
      </BounceFromBottomContainer>
      <BounceFromBottomContainer index={7}>
        <div className={styles["blue-div"]}>
          <div className={styles["span-wrap"]}>
            <span>00:00</span>
          </div>
          <span>Name</span>
          <ThreeIconsBlue />
        </div>
      </BounceFromBottomContainer>
    </div>
  );
};

export default CalendarTable;
