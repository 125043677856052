import React from "react";
import styles from "./EmployeeDocument.module.scss";
import EmployeeDocumentContent from "./components/EmployeeDocumentContent/EmployeeDocumentContent";
import GHeader from "../../components/GHeader/GHeader";
import {
  CERTIFICATES,
  DOCUMENTS_ALL_EMPLOYEES,
} from "../../app/providers/RouterConfig";

interface EmployeeDocumentPageProps {
  isCerts?: boolean;
}

const EmployeeDocumentPage: React.FC<EmployeeDocumentPageProps> = ({
  isCerts,
}) => {
  return (
    <div className={styles.main_wrapper}>
      <GHeader
        title={isCerts ? "Все справки" : "Все документы"}
        withArrow
        arrowLink={isCerts ? CERTIFICATES : DOCUMENTS_ALL_EMPLOYEES}
      />
      <EmployeeDocumentContent isCerts={isCerts} />
    </div>
  );
};

export default EmployeeDocumentPage;
