import React from "react";
import styles from "./TimerCard.module.scss";
import BounceFromBottomContainer from "../../../components/Animations/BounceFromBottomContainer";

const TimerCard = () => {
  return (
    <div className={styles["card-wrapper"]}>
      <BounceFromBottomContainer index={6}>
        {" "}
        <div className={styles["timer-card-wrapper"]}>
          <div className={styles["timer-content"]}>
            <div className={styles["timer-wrap"]}>
              <span>18.09.2023</span>
            </div>
            <span className={styles["timer-text"]}>
              Подписание соглашения о стратегическом партнерстве.
              <br /> Заключено партнерство с ведущим технологическим институтом
              для совместной работы над инновационными проектами и обмена
              передовыми технологиями.
            </span>
          </div>
        </div>
      </BounceFromBottomContainer>
      <BounceFromBottomContainer index={7}>
        <div className={styles["timer-card-wrapper"]}>
          <div className={styles["timer-content"]}>
            <div className={styles["timer-wrap"]}>
              <span>08.01.2024</span>
            </div>
            <span className={styles["timer-text"]}>
              Запуск нового исследовательского центра.
              <br /> Мы открыли новый исследовательский центр, оснащенный
              передовым оборудованием для проведения экспериментов и разработок
              в области искусственного интеллекта и машинного обучения.
            </span>
          </div>
        </div>
      </BounceFromBottomContainer>
      <BounceFromBottomContainer index={8}>
        {" "}
        <div className={styles["timer-card-wrapper"]}>
          <div className={styles["timer-content"]}>
            <div className={styles["timer-wrap"]}>
              <span>22.04.2024</span>
            </div>
            <span className={styles["timer-text"]}>
              Внедрение новой платформы для анализа данных.
              <br /> Запущена новая платформа для анализа больших данных,
              которая позволит нашим клиентам получать ценные инсайты и
              принимать более обоснованные бизнес-решения.
            </span>
          </div>
        </div>
      </BounceFromBottomContainer>
    </div>
  );
};

export default TimerCard;
