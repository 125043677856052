import React from "react";

const GemplLogoIcon = () => {
    return (<svg width="45" height="34" viewBox="0 0 45 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.32988 11.1384L0.313006 10.4874C0.208929 10.421 0.125195 10.3289 0.0705317 10.2206C0.0158687 10.1124 -0.00772227 9.9919 0.00221825 9.87183C0.0121588 9.75175 0.0552666 9.63644 0.127048 9.53793C0.19883 9.43941 0.296658 9.3613 0.410335 9.31173L21.4901 0.119314C21.6959 0.0295529 21.9213 -0.0105759 22.1467 0.00237677C22.3722 0.0153294 22.591 0.0809767 22.7844 0.193673L31.7212 5.39738L29.1761 6.50714L22.0043 2.33043L3.8183 10.2602L8.6005 13.0451L14.0306 10.6432C14.0306 10.6432 12.5634 9.80137 11.6729 9.27104L14.2165 8.16829L18.9987 10.9518L18.3305 11.2436C18.3305 11.2436 13.2461 13.3944 10.7563 14.6108L8.82131 15.3937L6.95317 14.4116L6.90087 21.9583L3.96502 19.8973L1.36329 21.9022M5.54262 19.2127V13.473L2.72444 11.8161L2.73461 19.2113L4.13789 17.8756C4.13789 17.8756 5.54262 19.2632 5.54262 19.2127Z"
                fill="#E5E1FC"/>
            <path
                d="M33.5798 7.08325L44.6584 13.5167C44.7688 13.5808 44.8589 13.6727 44.919 13.7826C44.979 13.8925 45.0066 14.0161 44.9989 14.1401C44.9912 14.264 44.9484 14.3836 44.8751 14.4858C44.8018 14.588 44.701 14.6689 44.5834 14.7198L38.0789 17.5539L35.6646 16.1411L40.814 13.9007L33.5475 9.68071C33.5475 9.68071 27.7478 12.3618 25.8969 13.1213C28.0303 14.2706 30.7388 15.9342 30.7388 15.9342L28.1833 17.0678L23.3252 14.2436L15.4422 17.5553L25.1804 23.1569L23.3267 23.9632C23.1186 24.0534 22.891 24.0937 22.6633 24.0809C22.4356 24.0681 22.2144 24.0024 22.0187 23.8895L10.3281 17.1358"
                fill="#E5E1FC"/>
            <path
                d="M8.85257 21.978C8.84384 20.3376 10.7909 19.2843 12.1908 20.1351C13.8251 21.1287 15.5553 22.1747 15.9744 22.4194C16.5085 22.7298 17.0978 23.1624 17.1022 23.7889C17.1226 26.4025 17.1255 29.478 17.1444 30.9538C22.1314 31.9605 27.0181 31.8483 31.7213 29.4474C31.7213 29.4474 31.7039 30.6799 31.6457 31.5132C31.6195 31.8818 31.2528 32.1295 30.8962 32.2649C24.5733 34.982 16.9858 34.475 11.0834 30.9742C10.4344 30.6041 9.54234 29.9544 9.27458 29.5406C9.05031 29.1634 8.9154 28.7398 8.88022 28.3023M15.0081 30.0913C14.963 28.8894 15.0081 26.4899 15.0081 25.3536C15.0081 25.0641 14.926 24.7805 14.7714 24.5358C14.6169 24.2911 14.3962 24.0952 14.135 23.971L10.8899 21.978L10.8127 26.64C10.8127 26.64 10.8302 27.89 11.1984 28.1566C11.8765 28.6417 13.4336 29.5086 14.9994 30.0913H15.0081Z"
                fill="#E5E1FC"/>
            <path
                d="M32.7379 19.8641C31.6698 20.4749 28.5928 21.901 28.5928 21.901L26.5576 20.3148L33.4011 17L35.4101 18.4513L35.2788 29.9656C35.2789 30.0887 35.249 30.2097 35.1921 30.3166C35.1351 30.4235 35.0531 30.5125 34.9541 30.5749C34.3406 30.9662 33.256 31.5518 32.7227 31.875L32.7379 19.8641Z"
                fill="#E5E1FC"/>
        </svg>
    )
}

export default GemplLogoIcon;