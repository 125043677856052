import React from 'react';

const FourStripesIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.5 8.33333H2.5M17.5 5H2.5M17.5 11.6667H2.5M17.5 15H2.5" stroke="#443896" strokeWidth="2"
                  strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default FourStripesIcon;