import React, { useState } from "react";
import styles from "./DepartmentHeader.module.scss";
import commonStyles from "../../../CreateVacancy/components/VacancyPageHeader/VacancyPageHeader.module.scss";
import MoreIcon from "../../../../components/Icons/MoreIcon";
import GSearchBar from "../../../../components/GSearchBar/GSearchBar";
import { Image } from "antd";
import first_avatar from "../../../../shared/images/Avatar1.png";
import { Select } from "antd/lib";
import Search from "antd/es/input/Search";

const DepartmentHeader = () => {
  const [isOpen, setIsOpen] = useState(false);

  function showDiv() {
    setIsOpen((current) => !current);
  }
  return (
    <div className={styles["drop-div-wrapper"]}>
      <div>
        <div className={commonStyles["main-wrapper"]}>
          <div className={commonStyles["title-wrapper"]}>
            <h2 className={commonStyles["title"]}>Отделы</h2>
            <div className={styles["button-wrapper"]}>
              <div className={styles["icon-wrapper"]} onClick={showDiv}>
                <MoreIcon />
              </div>
            </div>
          </div>
          <div className={commonStyles["avatar-wrapper"]}>
            <GSearchBar />
            <Image width={48} height={48} src={first_avatar} preview={false} />
          </div>
        </div>
      </div>
      <div
        className={`${styles["hidden-wrapper"]} ${
          isOpen ? styles["open"] : ""
        }`}
      >
        <div className={styles["drop-div"]}>
          <div className={styles["select-div"]}>
            <Select placeholder={"Select"} className={styles["select"]} />
            <Select placeholder={"Select"} className={styles["select"]} />
            <div className={styles["search"]}>
              <Search placeholder={"Searh"} />
            </div>
            <div className={styles["div-button-wrapper"]}>
              <button className={styles["button"]}>
                <span className={styles["button-text"]}> Отменить </span>
              </button>
              <button className={styles["reverse-button"]}>
                <span className={styles["reverse-button-text"]}>Применить</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DepartmentHeader;
