import React, {useEffect, useRef} from 'react';
import styles from './GChat.module.scss';
import Message from "./components/Message/Message";
import {Input} from "antd";
import SmileFaceIcon from "../Icons/SmileFaceIcon";
import AddFileIcon from "../Icons/AddFileIcon";
import SendMessageIcon from "../Icons/SendMessageIcon";
import {randomInt} from "node:crypto";

const GChat = () => {
    const [messages, setMessages] = React.useState<string[]>([]);
    const [currentInput, setCurrentInput] = React.useState("");

    const messagesEndRef = useRef<HTMLDivElement>(null);

    const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentInput(event.target.value);
    }

    const replies = [
        "Добрый день!",
        "Как дела?",
        "Как прогресс по прохождению курса?",
        "Мы вам перезвоним.",
        "До завтра!"
    ]

    const sendMessage = () => {
        if (currentInput.trim() !== "") {
            setMessages((prevState) => [...prevState, currentInput]);
            setCurrentInput("");
         setTimeout(() => {
             let temp = Math.floor(Math.random() * 5);
            setMessages((prevState) => [...prevState, replies[temp]]);
        }, 600)}
    }

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    return (
        <div className={styles.main_wrapper}>
            <div className={styles.messages}>
                {messages.map((message, index) => {
                    return(
                       index%2 === 0 ?
                           <Message source={"outgoing"} messageText={message} /> :
                           <Message source={"incoming"} messageText={message} />
                    )
                })}
                <div ref={messagesEndRef} />
            </div>
            <Input
                className={styles.input}
                prefix={<SmileFaceIcon />}
                suffix={
                    <div className={styles.input_icons}>
                        <AddFileIcon />
                        <div className={styles.send} onClick={sendMessage}>
                            <SendMessageIcon />
                        </div>
                    </div>}
                size="large"
                onInput={handleInput}
                onPressEnter={sendMessage}
                value={currentInput}
                placeholder={"Введите сообщение..."}
            />
        </div>
    );
};

export default GChat;