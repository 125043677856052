import React from "react";
import styles from "./TestTasksCard.module.scss";
import GCard from "../../../../components/GCard/GCard";
import TrashIcon from "../../../../components/Icons/TrashIcon";
import EditIcon from "../../../../components/Icons/EditIcon";
import { Image } from "antd";
import codeImg from "../../../../shared/images/codeImage.png";
import { useNavigate } from "react-router";
import {
  ADAPTATION,
  ADAPTATION_TASK,
  TEST_TASK,
} from "../../../../app/providers/RouterConfig";
import firstRect from "../../../../shared/images/First_rectangle.svg";
import secondRect from "../../../../shared/images/Second_rectangle.svg";

interface TestTasksCardProps {
  rectType: "1" | "2" | "3";
  isAdaptation?: boolean;
}

const TestTasksCard: React.FC<TestTasksCardProps> = ({
  rectType,
  isAdaptation,
}) => {
  const navigate = useNavigate();

  const currentRect = () => {
    switch (rectType) {
      case "1":
        return codeImg;
      case "2":
        return firstRect;
      case "3":
        return secondRect;
    }
  };

  return (
    <div
      className={styles["card-wrapper"]}
      onClick={() => navigate(isAdaptation ? ADAPTATION_TASK : TEST_TASK)}
    >
      <GCard isFullWidth type={"light"} shape={"twenty"}>
        <div className={styles["container"]}>
          <div className={styles["image-wrapper"]}>
            <Image
              preview={false}
              src={currentRect()}
              className={styles["image"]}
            />
          </div>
          <span className={styles["label"]}>
            Тестовое задание по менеджменту
          </span>
          <div className={styles["blob-wrap"]}>
            <span className={styles["blob"]}> Для первого этапа </span>
          </div>
          <span className={styles["time"]}> Время прохождения: 40 минут </span>
          <span className={styles["description"]}>
            Для того чтобы оценить ваши навыки и способности, мы предлагаем вам
            выполнить небольшое тестовое задание. Оно включает в себя несколько
            задач, характерных для повседневной работы менеджера в нашей
            команде. Задание поможет нам понять, как вы справляетесь с
            организацией встреч, координацией проектов и взаимодействием с
            партнерами.
          </span>
          <div className={styles["tags-wrapper"]}>
            <span className={styles["tag1"]}>Менеджмент</span>
            <span className={styles["tag2"]}>Продажи</span>
          </div>
          <div className={styles["icons-wrapper"]}>
            <EditIcon />
            <TrashIcon />
          </div>
        </div>
      </GCard>
    </div>
  );
};

export default TestTasksCard;
