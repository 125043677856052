import React from "react";
import styles from "./TaskContent.module.scss";
import TaskCard from "../TaskCard/TaskCard";
import TaskPart from "../TaskPart/TaskPart";
import EditIcon from "../../../../components/Icons/EditIcon";
import TrashIcon from "../../../../components/Icons/TrashIcon";
import StripesIcon from "../../../../components/Icons/StripesIcon";
import SmallPlusIcon from "../../../../components/Icons/SmallPlusIcon";
import BounceFromRightContainer from "../../../../components/Animations/BounceFromRightContainer";
import BounceFromLeftContainer from "../../../../components/Animations/BounceFromLeftContainer";

const TaskContent = () => {
  return (
    <div className={styles.main_wrapper}>
      <BounceFromRightContainer index={0}>
        <TaskCard />
      </BounceFromRightContainer>
      <div className={styles.content}>
        <BounceFromRightContainer index={0}>
          <div className={styles.buttons}>
            <div className={styles.part_button}>
              <h3>Первая глава</h3>
              <div className={styles.icons}>
                <EditIcon />
                <TrashIcon />
                <StripesIcon />
              </div>
            </div>
            <div className={styles.add_button}>
              <span>Добавить главу</span>
              <SmallPlusIcon />
            </div>
          </div>
        </BounceFromRightContainer>
        <div className={styles.task_parts}>
          <BounceFromRightContainer index={2}>
            <TaskPart />
          </BounceFromRightContainer>
          <BounceFromRightContainer index={3}>
            <TaskPart />
          </BounceFromRightContainer>
          <BounceFromRightContainer index={4}>
            <TaskPart />
          </BounceFromRightContainer>
        </div>
      </div>
      <BounceFromRightContainer index={5}>
        <div className={styles.add_block_button}>
          <span>Добавить блок</span>
          <SmallPlusIcon />
        </div>
      </BounceFromRightContainer>
    </div>
  );
};

export default TaskContent;
