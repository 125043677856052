import React from 'react';
import styles from "./PersonalProfilePage.module.scss";
import LeftContent from "./components/LeftContent/LeftContent";
import RightContent from "./components/RightContent/RightContent";

const PersonalProfilePage = () => {
    return (
        <div className={styles.main_wrapper}>
            <LeftContent />
            <RightContent />
        </div>
    );
};

export default PersonalProfilePage;