import React from "react";
import ReactDOM from "react-dom/client";
import Index from "./app";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const renderApp = () => {
  root.render(
    <>
      <meta name="viewport" content="width=device-width,initial-scale=1" />
      <Index />
    </>
  );
};

renderApp();
