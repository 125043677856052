import React from "react";
import styles from "./SecondBlob.module.scss";
import GCard from "../../../../components/GCard/GCard";
import ArrowRightWhiteIcon from "../../../../components/Icons/ArrowRightWhiteIcon";

const SecondBlob = () => {
  return (
    <div className={styles["second-blob"]}>
      <GCard type={"light"} isFullWidth>
        <div className={styles["main-wrapper"]}>
          <div className={styles["text-wrapper"]}>
            <span className={styles["title"]}>
              17 подходящих кандидатов на эту вакансию
            </span>
            <span className={styles["description"]}>
              Специалисты были отобраны на основании ваших критериев. Список
              кандидатов не содержит неактивных заявок.
            </span>
          </div>
          <div className={styles["button-wrapper"]}>
            <div className={styles["icon"]}>
              <ArrowRightWhiteIcon />
            </div>
          </div>
        </div>
      </GCard>
    </div>
  );
};

export default SecondBlob;
