import React from "react";
import styles from "./DocumentsAllEmployeesContent.module.scss";
import DocumentCard from "../DocumentCard/DocumentCard";
import BounceFromBottomContainer from "../../../../components/Animations/BounceFromBottomContainer";

interface DocumentsAllEmployeesContentProps {
  isCerts?: boolean;
}

const DocumentsAllEmployeesContent: React.FC<
  DocumentsAllEmployeesContentProps
> = ({ isCerts }) => {
  return !isCerts ? (
    <div className={styles.main_wrapper}>
      <BounceFromBottomContainer index={0}>
        <DocumentCard />
      </BounceFromBottomContainer>
      <BounceFromBottomContainer index={1}>
        <DocumentCard />
      </BounceFromBottomContainer>
      <BounceFromBottomContainer index={2}>
        <DocumentCard />
      </BounceFromBottomContainer>
      <BounceFromBottomContainer index={3}>
        <DocumentCard />
      </BounceFromBottomContainer>
      <BounceFromBottomContainer index={4}>
        <DocumentCard />
      </BounceFromBottomContainer>
      <BounceFromBottomContainer index={5}>
        <DocumentCard />
      </BounceFromBottomContainer>
      <BounceFromBottomContainer index={6}>
        <DocumentCard />
      </BounceFromBottomContainer>
      <BounceFromBottomContainer index={7}>
        <DocumentCard />
      </BounceFromBottomContainer>
      <BounceFromBottomContainer index={8}>
        <DocumentCard />
      </BounceFromBottomContainer>
      <BounceFromBottomContainer index={9}>
        <DocumentCard />
      </BounceFromBottomContainer>
    </div>
  ) : (
    <div className={styles.main_wrapper}>
      <BounceFromBottomContainer index={0}>
        <DocumentCard isCerts />
      </BounceFromBottomContainer>
      <BounceFromBottomContainer index={1}>
        <DocumentCard isCerts />
      </BounceFromBottomContainer>
      <BounceFromBottomContainer index={2}>
        <DocumentCard isCerts />
      </BounceFromBottomContainer>
      <BounceFromBottomContainer index={3}>
        <DocumentCard isCerts />
      </BounceFromBottomContainer>
    </div>
  );
};

export default DocumentsAllEmployeesContent;
