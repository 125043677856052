import React from "react";
import styles from "./GraphicsCard.module.scss";
import OrangeGraphicIcon from "../../../components/Icons/OrangeGraphicIcon";
import BlueGraphicIcon from "../../../components/Icons/BlueGraphicIcon";
import PurpleGraphicIcon from "../../../components/Icons/PurpleGraphicIcon";
import BounceFromBottomContainer from "../../../components/Animations/BounceFromBottomContainer";

const GraphicsCard = () => {
  return (
    <div className={styles["card-wrapper"]}>
      <div className={styles["graphic-cards-wrapper"]}>
        <BounceFromBottomContainer index={2}>
          <div className={styles["graphic-card"]}>
            <OrangeGraphicIcon />
            <div className={styles["text-wrap"]}>
              <h3>Внедрение текущих технологий</h3>
              <span>Анализ текущих технологий 30%</span>
              <span>Исследование рынка инноваций 40%</span>
              <span>Пилотные проекты 30%</span>
            </div>
          </div>
        </BounceFromBottomContainer>
        <BounceFromBottomContainer index={3}>
          <div className={styles["graphic-card"]}>
            <BlueGraphicIcon />
            <div className={styles["text-wrap"]}>
              <h3>Развитие культуры инноваций</h3>
              <span>Развитие сотрудников 40%</span>
              <span>Организация мероприятий 30%</span>
              <span>Создание среды для инноваций 30%</span>
            </div>
          </div>
        </BounceFromBottomContainer>
        <BounceFromBottomContainer index={4}>
          <div className={styles["graphic-card"]}>
            <PurpleGraphicIcon />
            <div className={styles["text-wrap"]}>
              <h3>Партнёрство и сотрудничество</h3>
              <span>Установление стратегические партнёров 30%</span>
              <span>Инвестирование в стартапы 40%</span>
              <span>Участие в исследованиях 30%</span>
            </div>
          </div>
        </BounceFromBottomContainer>
      </div>
    </div>
  );
};

export default GraphicsCard;
